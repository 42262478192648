import React, { useEffect, useState } from 'react';
import s from './UserForm.module.css';
import style from "../../../special/CommonStyles.module.css";
import { useDispatch, useSelector } from 'react-redux';
import config from "../../../config/config.json";
import { setFormUserEditMode } from '../../../rootSlice';
import { faEdit, faEnvelope, faPhone, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { refresh, edit } from './UserFormService.jsx'
import { RestCustomService } from 'omni-services-utils';
import { FormCustom, UserCommonForm, UserProcessingService } from 'omni-services-common';
import { InputTextCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService.jsx';

export const UserForm = (props) => {

    const done = useSelector(state => state.done)

    const state = useSelector(state => state)

    RestCustomService.setUrl(ConfigCustomService.getUrl());


    return (
        <div>
            <UserCommonForm url={ConfigCustomService.getUrl()} theme={state.settings.theme} />
        </div>
    );
}

export default UserForm;