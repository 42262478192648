import React, { useState, useEffect } from 'react';
import s from './Firm.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { chooseFirm } from '../../../rootSlice';
import { NavLink, useNavigate } from 'react-router-dom'
import FirmListItem from './firm-list-item/FirmListItem';
import { getFromFirmPath } from '../chooseProcessing/ChooseProcessing';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';
import { RestCustomService } from 'omni-services-utils';
import { FormCustom } from 'omni-services-common';

export const Firm = () => { 
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const region = useSelector(state => state.order.region);
    const city = useSelector(state => state.order.city);
    const serviceType = useSelector(state => state.order.serviceType);
    const service = useSelector(state => state.order.service);

    const [firms, setFirms] = useState();

    const state = useSelector(state => state)

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    var firmItems = [];

    var options = {
        showSelectFirm: true,
        needToLoggedIn: false,
        enableBackButton: true,
        showFooterButtons: false,
        showMenuButtons: false,
    };

    const submitClick = (selectedValue) => {
        dispatch(chooseFirm(selectedValue));
        if (serviceType.name === "Стоянка") {
            
            navigate("./../parking-date-interval");
            return;
        }

        navigate(getFromFirmPath(selectedValue));
    }

    async function getFirmsFromServer() {

        var body = {
            regionCode: region.code,
            cityCode: city.code,
            serviceTypeCode: serviceType.code,
            serviceCode: service.code,
            transportCategoryCode: 1,
            transportSubcategoryCode: 1
        };

        RestCustomService.Post(
            `/firm/get/for-customer`,
            body,
            false,
            (data) => {
                fillFirms(data)
            },
            (err) => {/*setError(err)*/
            console.log(err);
        
        }
        );
    }

    useEffect(() => {
        getFirmsFromServer();
    }, [])

    const fillFirms = (data) => {
        setFirms(data);
        for(var i = 0; i < data.length; i++) {
            firmItems.push( <FirmListItem firm={data[i]}/> );
        }
    }

    if ((firms !== undefined)) {
        return (
            <div className='centralWrapper'>
                <div className={s.formWrapper} id={state.settings.theme === "dark" ? s.dark : s.light}>
                    <FormCustom
                        title={"Выберите фирму"}
                        onSubmit={() => {}}
                        editMode={false}
                        setEditMode={() => {}}
                        refresh={() => {getFirmsFromServer();}}
                        options={options}
                        body={
                            <div>
                                <NavLink 
                                    to={"/select-firm-map"}>
                                    Показать на карте
                                </NavLink>
                            </div>
                        }
                        theme={state.settings.theme}
                    />
                </div>
                    <div className={s.itemWrapper}>
                    {
                        firms?.map((item) => (
                            <div className={s.firmItemWrapper} onClick={() => {submitClick(item);}}><FirmListItem firm={item} /></div> ))

                    }
                    </div>
            </div>
        )
    } else {
        <div>Не найдено фирм по Вашему запросу</div>
    }
}

export default (Firm);