import React, { useEffect, useState } from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import ChooseServiceSettings from '../../../../../choose/choose-service-settings/ChooseServiceSettings';
import DirectionsObject from '../../../../firm/edit-firm/tabs/tab-settings/DirectionsObject';

export const TabSettings = forwardRef((props, ref) => {

  const [staffMaxNumber, setStaffMaxNumber] = useState();
  const [accountBalance, setAccountBalance] = useState();

  const [isEnable, setIsEnable] = useState();
  const [isClosed, setIsClosed] = useState();

  const chooseServiceRef = useRef([]);

  var [src, setSrc] = useState([props.staff?.photo] ?? []);

  var [staff, setStaff] = useState(props.staff);
  var [editMode, setEditMode] = useState(props.editMode);

  var [directions, setDirections] = useState();

  var [selectedFirmNumber, setSelectedFirmNumber] = useState(props.selectedFirmNumber);


  useEffect(() => {
    setStaff(props.staff);
    setEditMode(props.editMode);
    setDirections(props.directions);
  }, [props.settings, props.staff, props.editMode, props.directions])

  useImperativeHandle(ref, () => ({
        
    getSettingsResult() {

        /*var directions = SettingsObject;
        directions.appointmentStrategy = appointmentStrategy;
        directions.paymentStrategy = paymentStrategy;
        directions.isEnable = isEnable
        directions.isClosed = isClosed;
        directions.enableEmailNotification = enableEmailNotification;
        directions.enableSmsNotification = enableSmsNotification;

        return directions;*/
    },
    
    getDirectionsResult() {

        var res = chooseServiceRef.current.getResult();
        var directions = DirectionsObject;
        
        directions.serviceTypesCode = res.serviceTypesCode;
        directions.servicesCode = res.servicesCode;

        return directions;
    },

  }));

  return (
    <div>
      {<ChooseServiceSettings
                    directions={directions} 
                    editMode={editMode}
                    ref={chooseServiceRef}
                    />}
    </div>
  );
});

export default TabSettings;