import React, { useEffect, useState } from 'react';
import ChooseSimple from '../choose-simple/ChooseSimple';
import { useDispatch, useSelector } from 'react-redux';
import { chooseCity, chooseRegion, chooseService, chooseServiceType } from '../../../rootSlice';
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import config from "../../../config/config.json";
import ChooseDouble from '../choose-double/ChooseDouble';import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { RestCustomService } from 'omni-services-utils';
import { SelectCustomSearch } from 'omni-services-base';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';

export const SelectSlot = forwardRef((props, ref) => {

    const { firmNumber } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';

    var firms = useSelector(state => state.firms)
    var selectedNumber = useSelector(state => state?.firms?.selectedNumber ?? firms?.list[0]?.number)

    var selectedFirmNumber = firmNumber;

    const state = useSelector(state => state)

    var [slots, setSlots] = useState([]);
    var [options, setOptions] = useState()
    var [selected, setSelected] = useState(props.selected ?? null);
    var [theme, setTheme] = useState(props.theme);

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const submitClick = (type, service) => {
        dispatch(chooseServiceType(type));
        dispatch(chooseService(service));
        navigate("./../select-model");
    }

    const getFormattedDate = (dateTime) => {

        var optionsDate = { year: 'numeric', month: 'numeric', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit' };

        if (dateTime == null) {
            return '';
        }

        return new Date(dateTime ?? null).toLocaleDateString("ru-RU", optionsDate);
    }

    const getSlotsFromServer = (selected) => {

        if (selected) {
            RestCustomService.Get(
                `/slot/owner/get/free/${selectedFirmNumber}/${selected}`,
                null,
                true,
                (data) => { fillSlots(data);  },
                (err) => { console.error(err); })
        } else {
            RestCustomService.Get(
                `/slot/owner/get/free/${selectedFirmNumber}`,
                null,
                true,
                (data) => { fillSlots(data); },
                (err) => { console.error(err); })
        }
    }

    const fillSlots = (data) => {
    
        var opts = [];
        for (var i = 0; i < data.length; i++) {
            if (data[i].id && getFormattedDate(data[i].begin)) {
                var opt = { key: data[i].id ?? '', label: getFormattedDate(data[i].begin), value: data[i].id?.toString() ?? '' };
                opts.push(opt);
            }
        }

        setOptions(opts);
    }

    useEffect(() => {
        getSlotsFromServer(props.selected);
        setSelected(props.selected);
        setTheme(props.theme);

    }, [props.selected, props.theme, props.options]);

    useImperativeHandle(ref, () => ({

        validation() {
    
            var err = null;
        
            return err;
        },
        
        getResult() {
            return selected;
        },

    }));

    return (
        <SelectCustomSearch
            options={options}
            title={"Свободные слоты"}
            name="choosedSlot"
            selected={selected}
            leftText={<FontAwesomeIcon icon={faClock}/>}
            theme={theme}
            setSelectedValue={setSelected}
        />
    )


});

export default SelectSlot;