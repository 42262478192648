import { useEffect } from 'react';
import { useState } from 'react';
import s from './MainCarousel.module.css'
import { CarouselCustom2 } from './carousel-custom-2/CarouselCustom2';
import { GiCartwheel } from "react-icons/gi";
import { FaWrench } from "react-icons/fa";
import { RiCarWashingFill } from "react-icons/ri";
import { GiTowTruck } from "react-icons/gi";
import { FaParking } from "react-icons/fa";

const MainCarousel = (props) => {

  var [theme, setTheme] = useState();
  var [items, setItems] = useState();

  const getColorsByTheme = (colorDark, colorLight) => {

    if (theme == "dark") {

      return colorDark;
    } else {

      return colorLight;
    }
  }

  var carouselItems = [{
    icon: <FaWrench />,
    title: "Автосервисы",
    description: "Позволяет сделать запись в автосервис",
    to: "/select-city",
    background: getColorsByTheme("rgb(6, 14, 22)", "#9dd1ca"),
    color: getColorsByTheme("#9dd1ca", "rgb(6, 14, 22)"),
  }, {
    icon: <RiCarWashingFill />,
    title: "Мойка",
    description: "Позволяет сделать запись в автосервис",
    to: "/select-city",
    background:  getColorsByTheme("rgb(6, 14, 22)", "#d1d09d"),
    color: getColorsByTheme("#d1d09d", "rgb(6, 14, 22)"),
  }, {
    icon: <GiCartwheel />,
    title: "Шиномонтаж",
    description: "Позволяет сделать запись в автосервис",
    to: "/select-city",
    background: getColorsByTheme("rgb(6, 14, 22)", "#d19d9d"),
    color: getColorsByTheme("#d19d9d", "rgb(6, 14, 22)"),
  }, {
    icon: <GiTowTruck />,
    title: "Эвакуатор",
    description: "Позволяет сделать запись в автосервис",
    to: "/select-city",
    background: getColorsByTheme("rgb(6, 14, 22)", "#9dd19f"),
    color: getColorsByTheme("#9dd19f", "rgb(6, 14, 22)"),
  }, {
    icon: <FaParking />,
    title: "Стоянка",
    description: "Позволяет сделать запись в автосервис",
    to: "/select-city",
    background: getColorsByTheme("rgb(6, 14, 22)", "#9d9ed1"),
    color: getColorsByTheme("#9d9ed1", "rgb(6, 14, 22)"),
  },

];

  useEffect(() => {
    setTheme(props.theme);
    setItems(props.items);
  }, [
    props.theme,
    props.items
    ]);

  return (
    <div id={theme == "light" ? s.light : s.dark} className={s.wrapper}>
        <CarouselCustom2
          items={carouselItems} 
          theme={theme} />
    </div>
  );
}

export default MainCarousel;