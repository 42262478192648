export var firmDefault = {
    number: 0,
    name: "",
    address: {
        regionCode: 0,
        cityCode: 0,
        locality: null,
        street: null,
        building: null
    },
    logo: null,
    uploadedFiles: [
        {
            fileName: '',
            content: ''
        }
     ],
    directions: {
        serviceTypesCode: [],
        servicesCode: [],
    },
    settings: {
        appointmentStrategy: 0,
        paymentStrategy: 0,
        isEnable: true,
        staffMaxNumber: null,
        accountBalance: null,
        isClosed: null,
        enableSmsNotification: true,
        enableSmsNotification: true,

    },
    schedule: {
		type: 1,
        roundTheClock: false,
		weekSchedule: [
			{
				dayOfWeek: 0,
				beginningTime: "0001-01-01T08:00:00+03:00",
				endingTime: "0001-01-01T17:00:00+03:00",
				brakeTimeBegin: null,
				brakeEnabled: null,
				brakeTimeEnd: null,
				brakeDuration: 0,
				isWorking: true
			}
		],
		complexSchedule: null
	},

    requisites: {
        legalName: null,
        legalAddress: null,
        legalMailAddress: null,
        inn: null,
        kpp: null,
        ogrn: null,
        okpo: null,
        checkingAccount: null,
        correspondentAccount: null,
        bic: null,
    },
    
    rate: 0,
    phone: "",
    email: ""
};

export default firmDefault;