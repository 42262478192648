import React, { useEffect, useState } from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import s from './TabSchedulle.module.css';
import { useDispatch, useSelector } from 'react-redux';
import SchedulleDay from '../../../../firm/edit-firm/schedulle-day/SchedulleDay';
import TabScheduleObject from '../../../../firm/edit-firm/tabs/tab-schedulle/TabScheduleObject';

export const TabSchedulle = forwardRef((props, ref) => {

    const mondayRef = useRef(null);
    const tuesdayRef = useRef(null);
    const wednesdayRef = useRef(null);
    const thursdayRef = useRef(null);
    const fridayRef = useRef(null);
    const saturdayRef = useRef(null);
    const sundayRef = useRef(null);

    const dispatch = useDispatch();
    const done = useSelector(state => state.done)

    const state = useSelector(state => state)
    var [editMode, setEditMode] = useState(props.editMode);

    var [schedule, setSchedulle] = useState('');

    var daySchedulle = {
        dayOfWeek: null,
        beginningTime: null,
        endingTime: null,
        brakeTimeBegin: null,
        brakeEnabled: false,
        brakeTimeEnd: null,
        brakeDuration: 0,
        isWorking: false
    };

    var getSchedulleIfExists = (dayNumber) => {

        if (schedule?.weekSchedule == null) {
            return daySchedulle;
        }

        var sh =  schedule.weekSchedule.find(w => w.dayOfWeek == dayNumber);
        if (sh) {
            return sh;
        }

        return daySchedulle;
    }


    useEffect(() => {
        setSchedulle(props.schedule);
        setEditMode(props.editMode);
    }, [props.schedule, props.editMode])

    useImperativeHandle(ref, () => ({

        validation() {
            
            var err = null;

            err = mondayRef.current.validation();
            if (err) {
                return err;
            }

            err = tuesdayRef.current.validation();
            if (err) {
                return err;
            }

            err = wednesdayRef.current.validation();
            if (err) {
                return err;
            }

            err = thursdayRef.current.validation();
            if (err) {
                return err;
            }

            err = fridayRef.current.validation();
            if (err) {
                return err;
            }

            err = saturdayRef.current.validation();
            if (err) {
                return err;
            }

            err = sundayRef.current.validation();
            if (err) {
                return err;
            }

            return null;
        },
        
        getResult() {
            var schedule = TabScheduleObject;
            
            schedule.roundTheClock = false; //roundTheClock;
            schedule.weekSchedule = [];

            var monday = mondayRef.current.getResult();
            if ((monday) && (monday.isWorking)) {
                schedule.weekSchedule.push( Object.assign({}, monday));
            }

            var tuesday = tuesdayRef.current.getResult();
            if ((tuesday) && (tuesday.isWorking)) {
                schedule.weekSchedule.push(Object.assign({}, tuesday));
            }

            var wednesday = wednesdayRef.current.getResult();
            if ((wednesday) && (wednesday.isWorking)) {
                schedule.weekSchedule.push(Object.assign({}, wednesday));
            }

            var thursday = thursdayRef.current.getResult();
            if ((thursday) && (thursday.isWorking)) {
                schedule.weekSchedule.push(Object.assign({}, thursday));
            }

            var friday = fridayRef.current.getResult();
            if ((friday) && (friday.isWorking)) {
                schedule.weekSchedule.push(friday);
            }

            var saturday = saturdayRef.current.getResult();
            if ((saturday) && (saturday.isWorking)) {
                schedule.weekSchedule.push(Object.assign({}, saturday));
            }

            var sunday = sundayRef.current.getResult();
            if ((sunday) && (sunday.isWorking)) {
                schedule.weekSchedule.push(Object.assign({}, sunday));
            }
            
            return schedule;
        },

    }));

    return (
        <div>
                TODO: отметка "Круглосуточно и 24/7"
                <br/>
                <SchedulleDay dayNumber={0} weekSchedule={getSchedulleIfExists(0)} dayOfWeek editMode={editMode} ref={mondayRef} />
                <hr className={s.solid}></hr>
                <SchedulleDay dayNumber={1} weekSchedule={getSchedulleIfExists(1)} dayOfWeek editMode={editMode} ref={tuesdayRef} />
                <hr className={s.solid}></hr>
                <SchedulleDay dayNumber={2} weekSchedule={getSchedulleIfExists(2)} dayOfWeek editMode={editMode} ref={wednesdayRef} />
                <hr className={s.solid}></hr>
                <SchedulleDay dayNumber={3} weekSchedule={getSchedulleIfExists(3)} dayOfWeek editMode={editMode} ref={thursdayRef} />
                <hr className={s.solid}></hr>
                <SchedulleDay dayNumber={4} weekSchedule={getSchedulleIfExists(4)} dayOfWeek editMode={editMode} ref={fridayRef} />
                <hr className={s.solid}></hr>
                <SchedulleDay dayNumber={5} weekSchedule={getSchedulleIfExists(5)} dayOfWeek editMode={editMode} ref={saturdayRef} />
                <hr className={s.solid}></hr>
                <SchedulleDay dayNumber={6} weekSchedule={getSchedulleIfExists(6)} dayOfWeek editMode={editMode} ref={sundayRef} />
                <hr className={s.solid}></hr>
                
            <div><span></span></div>
        </div>
    );
});

export default TabSchedulle;