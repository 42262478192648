import React, { useEffect, useState } from 'react';
import s from './ManageSlot.module.css';
import style from "../../../../special/CommonStyles.module.css";
import { useDispatch, useSelector } from 'react-redux';
import CreateSlot from '../create-slot/CreateSlot';
import { useNavigate, useParams } from 'react-router-dom';
import SlotListItem from '../../../choose/slot/slot-list-item/SlotListItem';
import EditSlotItem from '../edit-slot/EditSlotItem';
import { RestCustomService } from 'omni-services-utils';
import { DateCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';
import FilterSlot from '../filter-slot/FilterSlot';
import { SlotService } from '../SlotService';

export const ManageSlot = (props) => {
    const navigate = useNavigate();

    const { firmNumber } = useParams();

    var [slots, setSlots] = useState();
    const [error, setError] = useState();
    var [filterDate, setFilterDate] = useState(DateCustomService.getDateTimeString(new Date()));

    var firms = useSelector(state => state.firms)

    var selectedFirmNumber = firmNumber;// selectedNumber ?? firms?.list[0]?.number;

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    async function getSlotsFromServer() {

        SlotService.get(
            { firmNumber: selectedFirmNumber, date: filterDate }, 
            (data) => { fillSlots(data); },
            (err) => { console.error(err); }
        );
    }

    useEffect(() => {
        if (selectedFirmNumber) {
            getSlotsFromServer();
        }
    }, [selectedFirmNumber, filterDate])

    const fillSlots = (data) => {
        if (!data) {
            return;
        }
        var slotItems = [];

        for (var i = 0; i < data.length; i++) {
            slotItems.push(data[i]);
        }
        setSlots(slotItems);
    }


    return (
        <div className={s.wrapper}>

            <h3 className={s.headerWrapper}>Список слотов Вашей фирмы: </h3>
            <CreateSlot refresh={getSlotsFromServer} setFilterDate={setFilterDate} />
            <FilterSlot refresh={getSlotsFromServer} setFilterDate={setFilterDate} />
            <div className={s.slotListWrapper}>
                {
                    slots
                        ? slots.map((item) => (
                            <EditSlotItem slot={item} refresh={getSlotsFromServer} />
                        ))
                        : null
                }
            </div>
        </div>
    )
}

export default ManageSlot;