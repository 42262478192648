import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import s from './SlotTimeListItem.module.css';

export const SlotTimeListItem = (props) => {
    const state = useSelector(state => state);
    var [slot, setSlot] = useState(props.slot);

    const getFormattedTime = (dateTime) => {

        var optionsTime = { hour12: false, hour:'2-digit', minute:'2-digit' };

        if (dateTime == null) {
            return null;
        }

        return new Date(slot?.begin ?? null).toLocaleTimeString("ru-RU", optionsTime);
    } 

    return (
        <div className={s.wrapper}  id={state.settings.theme == "dark" ? s.dark: s.light}>
            <div className={s.descriptionWrapper}>
                <div>{ getFormattedTime(slot?.begin) ?? "Ошибка времени"}</div>
            </div>
        </div>
    )
}

export default (SlotTimeListItem);