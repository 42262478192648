import React, { useState } from 'react';
import { useEffect } from 'react';
import s from './ParkingPlace.module.css'


export const ParkingPlace = (props) => {

    var [text, setText] = useState();
    var [theme, setTheme] = useState();

    var [top, setTop] = useState(props.top);
    var [left, setLeft] = useState(props.left);
    var [state, setState] = useState(props.state);
    var [id, setId] = useState(props.id);
    var [number, setNumber] = useState(props.number);


    const styles = {
        position: 'absolute',
        top: top ?? 0,
        left: left ?? 0
    };

    const getColorByState = (state) => {
        switch (state) {
            case 0:
                return s.free;
            case 1:
                return s.reserved;
            case 2:
                return s.busy;
            default:
                return s.busy;
        }
    }

    useEffect(() => {
        setText(props.text);
        setTheme(props.theme);
        setTop(props.top);
        setLeft(props.left);
        setState(props.state);
        setNumber(props.number);
        setId(props.id);
    }, [
        props.text,
        props.theme,
        props.top,
        props.left,
        props.state,
        props.number,
        props.id
    ]);


    return (
        <div
            className={`${s.wrapper} ${getColorByState(state)}`}
            style={styles}
            onClick={() => props.onChoose({id: id, number: number})}
            >
            {number}
        </div>
    )
}

export default ParkingPlace;