import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import style from '../../special/CommonStyles.module.css';
import { useEffect, useRef, useState } from 'react';
import { setRegisteredUser } from '../../rootSlice';
import { ConfigCustomService } from '../../services/base/config-custom-service/ConfigCustomService';
import { RegisterCommonForm } from 'omni-services-common';

function RegisterForm() {

    const formRef = useRef(null)
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const state = useSelector(state => state);


    const onSuccess = (newUser) => {
        dispatch(setRegisteredUser({name: newUser.name, email: newUser.email, phone: newUser.phone }));
        //navigate("/register/confirm/email", { replace: true });
    }

    const onError = () => {

    }

    return (
        <div>
            <RegisterCommonForm 
                theme={state.settings.theme}
                url={ConfigCustomService.getUrl()}
                onSuccess={onSuccess}
                onError={onError}
                />            
        </div>
    );
}

export default RegisterForm;