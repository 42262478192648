import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './ManageTicket.module.css';
import style from '../../../../special/CommonStyles.module.css';
import AddTicket from '../add-ticket/AddTicket';
import CardTicket from '../card-ticket/CardTicket';
import { useParams } from 'react-router-dom';
import CardTicketSmall from '../card-ticket-small/CardTicketSmall';
import DraggableTickets from '../draggable/DraggableTickets';
import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';

export const ManageTicket = (props) => {

  const { firmNumber } = useParams();
  var [theme, setTheme] = useState();
  var [text, setText] = useState();
  var [editMode, setEditMode] = useState(false);
  var [error, setError] = useState();

  const state = useSelector(state => state)
  var firms = useSelector(state => state.firms)
  var selectedNumber = useSelector(state => state.firms.selectedNumber ? state.firms.selectedNumber : null)
  var selectedFirmNumber = firmNumber;

  var [tickets, setTickets] = useState([]);

  RestCustomService.setUrl(ConfigCustomService.getUrl());

  const handleSubmit = (event) => {

  }

  const setEditModeFunc = () => {
    setEditMode(!editMode);
  }

  const getAllTicketByAdmin = () => {

    RestCustomService.Get(
      `/ticket/owner/get/${selectedFirmNumber}`,
      null, 
      true, 
      (data) => { fillTickets(data); }, 
      (err) => { console.error(err); })
  }

  const fillTickets = (data) => {
    setTickets([]);
    var st = [];
    for (var i = 0; i < data?.length; i++) {
      st.push(data[i]);
    }
    setTickets(st);
  }

  useEffect(() => {
    setTheme(props.theme);
    setText(props.text);
    getAllTicketByAdmin();
  }, 
  [props.theme, props.text, selectedFirmNumber])

  return (
    <div >
        <AddTicket />
        <div className={s.slotListWrapper}>
        {/*
            tickets.map((item) => (
              <CardTicketSmall ticket={item} refresh={() => {}} />
            ))
            */}
          <DraggableTickets tickets={tickets} theme={state.settings.theme} />
        </div>
    </div>
  )
}

export default ManageTicket;