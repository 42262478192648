import React from 'react';
import s from './CarouselCustomButton2.module.css'
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const CarouselCustomButton2 = (props) => {

    var [theme, setTheme] = useState();
    var [text, setText] = useState(props.text);
    var [to, setTo] = useState(props.to);
    var [color, setColor] = useState(props.color);
    var [background, setBackground] = useState(props.background);

    useEffect(() => {
        setText(props.text);
        setTheme(props.theme);
        setTo(props.to);
        setColor(props.color);
        setBackground(props.background);
    }, [
        props.text,
        props.theme,
        props.to,
        props.color,
        props.background,
    ])

  return (
    <Link to={to}  className={s.linkWrapper}>
        <div  id={theme == "light" ? s.light : s.dark} className={s.wrapper} 
        style={
                {
                    color: color, 
                    border: `3px solid ${color}`,
                }
            }
            >
                {text}   
        </div>
    </Link>
  );
}

export default CarouselCustomButton2;