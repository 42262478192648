import React, { useEffect, useState } from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import s from './TabAuto.module.css';
import style from '../../../../../../special/CommonStyles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faCalendar, faClock, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { refresh } from '../../../../user/UserFormService';
import slotAutoScheduleObject from './SlotAutoScheduleObject';
import { RestCustomService } from 'omni-services-utils';
import { InputTextCustom } from 'omni-services-base';
import { CheckboxCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../../../../services/base/config-custom-service/ConfigCustomService';

export const TabAuto = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const done = useSelector(state => state.done)

    const state = useSelector(state => state)

    var [theme, setTheme] = useState(props.theme);
    var [editMode, setEditMode] = useState(false);
    var [begin, setBegin] = useState();
    var [duration, setDuration] = useState();
    var [firmNumber, setFirmNumber] = useState(props.selectedFirmNumber);
    var [required, setRequired] = useState(props.required);
    var [isActive, setIsActive] = useState();
    var [futureDayPeriod, setFutureDayPeriod] = useState();

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const refreshData = (data) => {
        setDuration(data.duration);
        setIsActive(data.isActive);
        setFutureDayPeriod(data.futureDayPeriod);

    }

    const getDataFromServer = () => {
        RestCustomService.Get(
            `/slotAutoSchedule/owner/get/${firmNumber}`,
            null,
            true,
            (data) => { refreshData(data); 
                },
            () => {});
    }

    const durationValidation = (text) => {
    
        if (!text) {
            return "Поле обязательно";
        }

        if (text.length == 0) {
            return "Поле обязательно";
        }

        if (/^\d+$/.test(text) == false) {
            return "длительность слота должна быть положительной";
        }

        return null;
    }

    const futureDayPeriodValidation = (text) => {
    
        if (!text) {
            return "Поле обязательно";
        }

        if (text.length == 0) {
            return "Поле обязательно";
        }

        if (/^\d+$/.test(text) == false) {
            return "период в днях должна быть положительным";
        }

        return null;
    }

    useImperativeHandle(ref, () => ({

        validation() {

            var err = null;
    
            err = durationValidation(duration);
            if (err != null) {
                return "Поле длительность слота (в минутах): " + err;
            }

            err = futureDayPeriodValidation(futureDayPeriod);
            if (err != null) {
                return "Поле период в днях: " + err;
            }
    
            return null;
        },

        getResult() {
            var auto = slotAutoScheduleObject;
            
            auto.duration = duration;
            auto.futureDayPeriod = futureDayPeriod;
            auto.isActive = isActive;

            return auto;
        },

      }));

    useEffect(() => {
        setTheme(props.theme);
        setEditMode(props.editMode);
        getDataFromServer();
    }, [props.theme, props.editMode, props.selectedFirmNumber])
    return (
        <div>
            <CheckboxCustom
                label={"Активировать автоматическое добавление"}
                checked={isActive}
                onChange={() => {setIsActive(!isActive)}}
                name="isSlotAutoScheduleActivated"
                id="isSlotAutoScheduleActivated"
                readOnly={null} />
            <InputTextCustom
                theme={state.settings.theme}
                controlId="email"
                label="Длительность слота (в минутах)"
                required={required}
                type="number"
                name="durationAutoSchedule"
                placeholder="Длительность слота..."
                muted=""
                message={duration}
                readOnly={!editMode}
                setMessage={setDuration}
                leftText={<FontAwesomeIcon icon={faClock} />}
                validation={durationValidation}
            />
            <InputTextCustom
                theme={state.settings.theme}
                controlId="email"
                label="На сколько дней вперёд"
                required={required}
                type="number"
                name="futureDayPeriod"
                placeholder="Период..."
                muted=""
                message={futureDayPeriod}
                readOnly={!editMode}
                setMessage={setFutureDayPeriod}
                leftText={<FontAwesomeIcon icon={faClock} />}
                validation={futureDayPeriodValidation}
            />
        </div>
    );
});

export default TabAuto;