import React from 'react';
import { NavLink } from 'react-router-dom';
import s from './Settings.module.css';
import { useSelector } from 'react-redux';

export const Settings = (props) => {

    var firms = useSelector(state => state.firms)
    var firmNumber = useSelector(state => state?.firms?.selectedNumber ?? firms?.list[0]?.number)

    return (
        <div className='centralWrapper'>
            <h2>Настройки</h2>
            <p className={s.txtWrapper}>
                <p>
                    <NavLink to="/user" end>
                        Настройка пользователя
                    </NavLink>
                </p>
                <p>
                    <NavLink to={`/firm/${firmNumber}/edit-firm`} end>
                        Настройка фирмы
                    </NavLink>
                </p>
                <p>
                    <NavLink to={`/firm/${firmNumber}/manage-staff`} end>
                        Настройка персонала
                    </NavLink>
                </p>
                <p>
                    <NavLink to={`/firm/${firmNumber}/manage-parking-area`} end>
                        Настройка стоянки
                    </NavLink>
                </p>
            </p>
        </div>
    )
}

export default Settings;