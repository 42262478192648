import React, {useEffect} from 'react';
import s from './ServiceType.module.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import { Link } from 'react-router-dom';
import ChooseSimple from '../choose-simple/ChooseSimple';
import { useDispatch, useSelector } from 'react-redux';
import { chooseServiceType } from '../../../rootSlice';
import {useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'

export const ServiceType = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const base = useSelector(state => state.base)
    const {register, handleSubmit} = useForm({defaultValues: {base}})

    const state = useSelector(state => state)

    var options = [];
    var serviceTypes = [];

    var serviceTypeCode = null;

    const submitClick = (selectedValue) => {
        dispatch(chooseServiceType(selectedValue));
        navigate("./../service");
    }
    

    const getServiceTypesFromServer = () => {
        const apiUrl = '/api/serviceType/get/all';
        fetch(apiUrl)
            .then((response) => response.json())
            .then((data) => fillServiceTypes(data));
    }

    useEffect(() => {
        getServiceTypesFromServer();
    });

    const fillServiceTypes = (data) => {
        for (var i = 0; i < data.length; i++) {
            var ChooseSimple = { label: data[i].name, value: data[i].code };
            options.push(ChooseSimple);
        }
    }


    return (
        <ChooseSimple 
            options={options} 
            directoryName="Тип сервиса" 
            directoryDescription="Начните набирать название" 
            previous="./city"
            next="./service" 
            chooseFunc={submitClick}/>
    )


}

export default (ServiceType);