import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import s from './FirmListItem.module.css';
import { useState } from 'react';
import FirmListItemSchedule from './firm-list-item-schedule/FirmListItemSchedule';
import { ImageCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';

export const FirmListItem = (props) => {
    const state = useSelector(state => state);

    var [firm, setFirm] = useState(props.firm);

    useEffect(() => {
        setFirm(props.firm);
        console.log(props.firm);
    }, [props.firm]);

    return (
        <div className={s.wrapper}  id={state.settings.theme == "dark" ? s.dark: s.light}>
            <div className={s.imageWrapper}>
                <ImageCustom src={`${ConfigCustomService.getUrl()}${firm?.logo}`} />
            </div>
            <div className={s.descriptionWrapper}>
                <h6>{firm.name}</h6>
                { /*<div>{firm.number}</div>*/ }
                { /* <div>Рейтинг: {firm.rate}</div>*/ }
                <div>Адрес: 
                    ул.&nbsp;<span>{firm.address?.street ?? '(улица не указана)'}</span>&nbsp;
                    д.&nbsp;<span>{firm.address?.building ?? '(Номер дома не указан)'}</span>&nbsp;
                </div>
                { /* <FirmListItemSchedule schedule={firm.schedule} /> */}
            </div>
        </div>
    )
}

export default (FirmListItem);