import React, { useEffect, useState } from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faCalendar, faEnvelope, faHammer, faPerson, faPhone } from '@fortawesome/free-solid-svg-icons';
import { InputTextCustom } from 'omni-services-base';
import SelectStaffStatuses from '../tab-user-link/select-staff-statuses/SelectStaffStatuses';

export const TabMain = forwardRef((props, ref) => {

    const selectStaffRef = useRef();

    const dispatch = useDispatch();
    const done = useSelector(state => state.done)

    const state = useSelector(state => state)

    var [userName, setUserName] = useState(props.staff?.userName);
    var [positionName, setPositionName] = useState(props.staff?.positionName);
    var [theme, setTheme] = useState(props.theme);

    var [editMode, setEditMode] = useState(props.editMode);

    var [staff, setStaff] = useState(props.staff);

    var [url, setUrl] = useState(props.url);


    useEffect(() => {
        setStaff(props.staff);
        setEditMode(props.editMode);
        setUserName(props.staff?.userName);
        setPositionName(props.staff?.positionName);
        setTheme(props.theme);
        setUrl(props.url);
    }, [props.firm, props.theme, props.staff, props.editMode, props.url]);

    const nameValidation = (text) => {
    
        if (!text) {
            return "Поле обязательно";
        }

        if (text.length == 0) {
            return "Поле обязательно";
        }

        if (/^[0-9a-zA-Zа-яА-ЯёЁ\s-=+!?&$%*]*$/.test(text) == false) {
            return "только буквы и пробел";
        }

        return null;
    }

    const positionValidation = (text) => {
    
        if (!text) {
            return "Поле обязательно";
        }

        if (text.length == 0) {
            return "Поле обязательно";
        }

        if (/^[0-9a-zA-Zа-яА-ЯёЁ\s-=+!?&$%*]*$/.test(text) == false) {
            return "только буквы и пробел";
        }

        return null;
    }

    useImperativeHandle(ref, () => ({

        validation () {

            var err = null;
    
            console.log(userName);
            err = nameValidation(userName);
            if (err) {
                return "Поле имя: " + err;
            }
    
            err = positionValidation(positionName);
            if (err) {
                return "Поле должность: " + err;
            }
    
            return null;
        },
        
        getResult() {

            return {
                userName: userName,
                positionName: positionName
            };
        },

      }));


    return (
        <div>
                <InputTextCustom
                    theme={theme}
                    controlId="userName"
                    label="Имя работника"
                    required={true}
                    type="text"
                    name="userName"
                    placeholder="Имя работника..."
                    muted=""
                    message={userName}
                    readOnly={!editMode}
                    setMessage={setUserName}
                    leftText={<FontAwesomeIcon icon={faPerson}/>}
                    validation={nameValidation}
                />
                <InputTextCustom
                    theme={theme}
                    controlId="position"
                    label="Должность"
                    required={true}
                    type="text"
                    name="positionName"
                    placeholder="Название должности..."
                    muted=""
                    message={positionName}
                    readOnly={!editMode}
                    setMessage={setPositionName}
                    leftText={<FontAwesomeIcon icon={faHammer}/>}
                    validation={positionValidation}
                />
        </div>
    );
});

export default TabMain;