import React, { useEffect, useState } from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import s from './TabRange.module.css';
import style from '../../../../../../special/CommonStyles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faCalendar, faClock, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import SlotAddRange from './SlotAddRangeObject';
import { InputTextCustom } from 'omni-services-base';


export const TabRange = forwardRef((props, ref)=> {

    const dispatch = useDispatch();
    const done = useSelector(state => state.done)

    const state = useSelector(state => state)

    var [theme, setTheme] = useState(props.theme);
    var [editMode, setEditMode] = useState(false);
    var [rangeBegin, setRangeBegin] = useState();
    var [rangeEnd, setRangeEnd] = useState();
    var [rangeDuration, setRangeDuration] = useState();
    var [required, setRequired] = useState(props.required);
    var [firmNumber, setFirmNumber] = useState(props.selectedFirmNumber);

    const beginValidation = (date) => {

        if (!date) {
            return "Поле обязательно";
        }

        if (date.length == 0) {
            return "Поле обязательно";
        }

        const formDate = Date.parse(date);
        const nowDate = Date.parse(new Date());

        if (formDate < nowDate) {
            return "дата не может быть прошедшей";
        }

        return null;
    }

    const endValidation = (date) => {

        if (!date) {
            return "Поле обязательно";
        }

        if (date.length == 0) {
            return "Поле обязательно";
        }

        const formDate = Date.parse(date);
        const nowDate = Date.parse(new Date());

        if (formDate < nowDate) {
            return "дата не может быть прошедшей";
        }

        return null;
    }

    const durationValidation = (text) => {
    
        if (!text) {
            return "Поле обязательно";
        }

        if (text.length == 0) {
            return "Поле обязательно";
        }

        if (/^\d+$/.test(text) == false) {
            return "длительность слота должна быть положительной";
        }

        return null;
    }

    

    useImperativeHandle(ref, () => ({

        validation() {
   
            var err = null;
    
            err = beginValidation(rangeBegin);
            if (err != null) {
                return "Поле дата слота: " + err;
            }
    
            err = endValidation(rangeEnd);
            if (err != null) {
                return "Поле дата слота: " + err;
            }
    
            err = durationValidation(rangeDuration);
            if (err != null) {
                return "Поле длительность слота (в минутах): " + err;
            }
    
            return null;
        },

        getResult() {
            var range = SlotAddRange;

            range.begin = rangeBegin;
            range.end = rangeEnd;
            range.duration = rangeDuration;

            return range;
        },

      }));


    useEffect(() => {
        setTheme(props.theme);
        setEditMode(props.editMode);
        setRequired(props.required);
        //forceUpdate();
        if (props.validate) {
            //validation();
        }  
    }, [props.theme, props.editMode, props.required, props.doValidate])
    return (
        <div>
            <InputTextCustom
                theme={state.settings.theme}
                controlId="rangeBegin"
                label="Дата начала периода"
                required={required}
                type="datetime-local"
                name="rangeBegin"
                placeholder="Дата слота..."
                muted=""
                message={rangeBegin}
                readOnly={!editMode}
                setMessage={setRangeBegin}
                leftText={<FontAwesomeIcon icon={faCalendar} />}
                validation={beginValidation}
            />
            <InputTextCustom
                theme={state.settings.theme}
                controlId="rangeEnd"
                label="Дата конца периода"
                required={required}
                type="datetime-local"
                name="rangeEnd"
                placeholder="Дата слота..."
                muted=""
                message={rangeEnd}
                readOnly={!editMode}
                setMessage={setRangeEnd}
                leftText={<FontAwesomeIcon icon={faCalendar} />}
                validation={endValidation}
            />
            <InputTextCustom
                theme={state.settings.theme}
                controlId="rangeDuration"
                label="Длительность слота (в минутах)"
                required={required}
                type="number"
                name="rangeDuration"
                placeholder="Длительность слота..."
                muted=""
                message={rangeDuration}
                readOnly={!editMode}
                setMessage={setRangeDuration}
                leftText={<FontAwesomeIcon icon={faClock} />}
                validation={durationValidation}
            />
        </div>
    );
});

export default TabRange;