import React, { useState, useEffect } from 'react';
import s from './Slot.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { chooseSlot } from '../../../rootSlice';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import SlotListItem from './slot-list-item/SlotListItem';
import ChooseCommonForm from '../choose-common-form/ChooseCommonForm';
import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';

export const Slot = () => { 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const base = useSelector(state => state.base)

    const firmNumber = useSelector(state => state.order.firm.number);
    const [mounted, setMounted] = useState(false);

    const [slots, setSlots] = useState();

    const { register, handleSubmit } = useForm({ defaultValues: { base } })

    const state = useSelector(state => state)

    var slotItems = [];

    var slotCode = null;

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const submitClick = (selectedValue) => {
        dispatch(chooseSlot(selectedValue));
        navigate("./../ticket");
    }

    async function getSlotsFromServer() {

        RestCustomService.Get(
            `/slot/get/many/${firmNumber}`, 
            null, 
            true, 
            (data) => {
                fillSlots(data);
            }, 
            (err) => {}) 
    }

    useEffect(() => {
        getSlotsFromServer();
    }, [])

    const fillSlots = (data) => {
        setSlots(data);
        for (var i = 0; i < data.length; i++) {
            slotItems.push(<SlotListItem slot={data[i]} />);
        }
    }

    if ((slots != undefined)) {
        return (
            <div>
                <h3 className={s.headerWrapper}>Выберите дату и время записи: </h3>
                <ChooseCommonForm title={"Выберите слот"} previous={"/select-firm"} />
                <div className={s.slotListWrapper}>
                    {
                        slots.map((item) => (
                            <div className={s.slotWrapper} onClick={() => {submitClick(item); }}>
                                <SlotListItem slot={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default (Slot);