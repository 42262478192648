import React, { useState, useEffect } from 'react';
import s from './SelectParkingDateInterval.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button } from 'react-bootstrap';
import { chooseParkingInterval, setDone, setErrorCustom } from '../../../rootSlice';
import config from "../../../config/config.json";
import style from '../../../special/CommonStyles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faCalendar, faCar, faClock, faIndustry, faUser, faWrench } from '@fortawesome/free-solid-svg-icons';
import { InputTextCustom } from 'omni-services-base';
import { FormCustom } from 'omni-services-common';

export const SelectParkingDateInterval = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const firm = useSelector(state => state.order.firm);
    const slot = useSelector(state => state.order.slot);
    const serviceType = useSelector(state => state.order.serviceType);
    const service = useSelector(state => state.order.service);
    const manufacturer = useSelector(state => state.order.manufacturer);
    const manufacturerModel = useSelector(state => state.order.manufacturerModel);

    var [theme, setTheme] = useState();
    var [error, setError] = useState();
    var [begin, setBegin] = useState();
    var [end, setEnd] = useState();
    var [editMode, setEditMode] = useState();

    const state = useSelector(state => state)

    const options = {
        showFooterButtons: true,
        showMenuButtons: false,
        readOnly: false,
        submitText: "Выбрать период"
    };


    const submitClick = () => {

        dispatch(chooseParkingInterval({begin: begin, end: end}));
        navigate("./../parking-area");
    }

    useEffect(() => {
    }, [])

    return (
        <div className={style.formWrapper} id={state.settings.theme == "dark" ? style.dark : style.light}>
        <FormCustom
            title={"Новая запись"}
            options={options}
            onSubmit={submitClick}
            body={
                <div>
                    <InputTextCustom
                            theme={state.settings.theme}
                            controlId="parkingPlaceDateBegin"
                            label="Планируемая дата начала периода стоянки"
                            required={true}
                            type="datetime-local"
                            name="parkingPlaceDateBegin"
                            placeholder="Планируемая дата..."
                            muted=""
                            message={begin}
                            readOnly={false}
                            setMessage={setBegin}
                            leftText={<FontAwesomeIcon icon={faCalendar}/>}
                    />
                    <InputTextCustom
                            theme={state.settings.theme}
                            controlId="parkingPlaceDateEnd"
                            label="Планируемая дата окончания периода стоянки"
                            required={true}
                            type="datetime-local"
                            name="parkingPlaceDateEnd"
                            placeholder="Планируемая дата..."
                            muted=""
                            message={end}
                            readOnly={false}
                            setMessage={setEnd}
                            leftText={<FontAwesomeIcon icon={faCalendar}/>}
                    />
                </div>
            }
            theme={state.settings.theme}
            error={error}
        />

    </div>
    )
}

export default (SelectParkingDateInterval);