import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import style from '../../../../special/CommonStyles.module.css';
import { useNavigate } from 'react-router-dom';
import { FormCustom, InfoBlockCustom } from 'omni-services-common';
import { CheckboxCustom } from 'omni-services-base';

export const AddParkingArea = (props) => {

    const navigate = useNavigate();
    var [theme, setTheme] = useState();
    var [text, setText] = useState();
    var [editMode, setEditMode] = useState(false);
    var [error, setError] = useState();

    const state = useSelector(state => state)

    const options = {
        readonly: false,
        canRefresh: false,
        canSave: true,
        canDelete: true,
        showMenuButtons: false,
        showFooterButtons: true,
        submitText: "Добавить парковочное место",
        showSelectFirm: true,
        editMode: true
    };

    const handleSubmit = (event) => {
        navigate('/edit-ticket/');
    }

    const setEditModeFunc = () => {
        setEditMode(!editMode);
    }

    useEffect(() => {
        setTheme(props.theme);
        setText(props.text);
    },
        [props.theme, props.text])

    return (
        <div className={style.formWrapper} id={(state.settings.theme == "dark") ? style.dark : style.light}>
            <FormCustom
                title={"Парковка"}
                onSubmit={handleSubmit}
                editMode={editMode}
                setEditMode={setEditModeFunc}
                body={
                    <div>
                        <CheckboxCustom
                            label={"Активировать парковочное место"} 
                            checked={false} 
                            onChange={null} 
                            name="isEnable" 
                            id="isEnable" 
                            readOnly={null}/>
                        <InfoBlockCustom info={"Здесь настраиваются парковка и парковочные места"}/>
                    </div>}
                theme={state.settings.theme}
                error={error}
                options={options}
            />
        </div>
    )
}

export default AddParkingArea;