import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import s from './CarouselCustom2.module.css'
import { FaBeer } from 'react-icons/fa';
import CarouselCustomButton2 from './carousel-custom-button-2/CarouselCustomButton2';

export const CarouselCustom2 = (props) => {

  var [theme, setTheme] = useState();
  var [items, setItems] = useState([]);

  useEffect(() => {
    setTheme(props.theme);
    setItems(props.items ? props.items : []);
  }, [
    props.theme,
    props.items
  ]);

  return (
    <div id={theme == "light" ? s.light : s.dark}>
      <Carousel>
        {
          items.map((item, index) => (
            <Carousel.Item key={index}>
              <div className={s.commonWrapper}>
                <div className={s.colorWrapper} style={{background: item.background, color: item.color}}>
                    <div className={s.topWrapper}>
                    <div className={s.leftWrapper}>
                        {/*<div className={s.titleWrapper} data-word={item.title}>
                        {item.title}
                        </div>*/}
                        <h1 className={s.titleWrapper} data-text="U">
                        {item.title}  
                        </h1>
                        <div className={s.descriptionWrapper}>
                          {item.description}
                        </div>
                        <div className={s.buttonWrapper}>
                          <CarouselCustomButton2 text={"Записаться"} theme={theme} to={item.to} color={item.color}/>
                        </div>
                    </div>
                    <div className={s.rightWrapper}>
                        <div className={s.iconWrapper}>
                          {item.icon}
                        </div>
                    </div>
                    </div>
                </div>
              </div>
              {/*<Carousel.Caption>
                <h3 className={s.title}>{item.title}</h3>
                <p className={s.description}>{item.description}</p>
          </Carousel.Caption>*/}

            </Carousel.Item>
          ))
        }
      </Carousel>
    </div>
  );
}