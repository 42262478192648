import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import style from '../../../../special/CommonStyles.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { FormCustom, InfoBlockCustom } from 'omni-services-common';

export const AddStaff = (props) => {

    const navigate = useNavigate();
    const { firmNumber } = useParams();

    var [theme, setTheme] = useState();
    var [text, setText] = useState();
    var [editMode, setEditMode] = useState(false);
    var [error, setError] = useState();

    const state = useSelector(state => state)

    const options = {
        readonly: false,
        canRefresh: false,
        canSave: true,
        canDelete: true,
        showMenuButtons: false,
        showFooterButtons: true,
        submitText: "Добавить запись",
        showSelectFirm: true,
        editMode: true,
        needToLoggedIn: true,
        enableBackButton: true
    };

    const handleSubmit = (event) => {
        navigate(`/firm/${firmNumber}/edit-staff/`);
    }

    const setEditModeFunc = () => {
        setEditMode(!editMode);
    }

    useEffect(() => {
        setTheme(props.theme);
        setText(props.text);
    },
        [props.theme, props.text])

    return (
        <div className={style.formWrapper} id={(state.settings.theme == "dark") ? style.dark : style.light}>
            <FormCustom
                title={"Сотрудники"}
                onSubmit={handleSubmit}
                editMode={editMode}
                setEditMode={setEditModeFunc}
                body={<div>
                    <InfoBlockCustom info={`
                        Редактирование персонала.
                    `} />
                    </div>}
                theme={state.settings.theme}
                error={error}
                options={options}
            />
        </div>
    )
}

export default AddStaff;