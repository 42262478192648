import React, { useEffect, useState } from 'react';
import s from './NotificationItemCustom.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RestCustomService } from 'omni-services-utils';

export const NotificationItemCustom = (props) => {

    const dispatch = useDispatch();
    const state = useSelector(state => state)


    const [theme, setTheme] = useState(props.theme);

    console.log(theme);

    const [notification, setNotification] = useState(props.notification);
    const [text, setText] = useState(props.notification?.text);
    const [firmNumber, setFirmNumber] = useState(props.notification?.firmNumber);
    const [ticketId, setTicketId] = useState(props.notification?.ticketId);
    const [userId, setUserId] = useState(props.notification?.userId);
    const [link, setLink] = useState(props.notification?.link);


    const setReadedOnServer = (id) => {
        
        var body = {
            id: id,
        };

        RestCustomService.Patch(
            `/notification/readed`,
            body,
            true,
            (data) => { 
                console.log(data);
                if (props.refresh != undefined) {
                    props.refresh();
                } 
            
            },
            (err) => { console.error(err); }
        );
    }

    const onClickHandler = () => {
        console.log("CLICCKKK!");
        console.log(notification);

        setReadedOnServer(notification?.id);
    }

    useEffect(() => {

        setText(props.notification?.text);
        setFirmNumber(props.notification?.firmNumber);
        setTicketId(props.notification?.ticketId);
        setUserId(props.notification?.userId);
        setLink(props.notification?.link);

        setNotification(props.notification);

    }, [
        props.notification
    ]);

    useEffect(() => {
        setTheme(props.theme);
    }, [props.theme]);


    return (
        <div id={(theme == "dark") ? s.dark : s.light}>
        <div 
            
            className={notification?.state == 0 ? `${s.wrapper} ${s.new}` : s.wrapper} 
            onClick={onClickHandler}>
            <div>{notification?.text}</div>
        </div>
        </div>

    );
}

export default NotificationItemCustom;