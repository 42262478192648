import React, { useState, useEffect, useRef } from 'react';
import s from './MapFirmsCustom.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { RestCustomService } from 'omni-services-utils';
import { OpenStreetMapCustom } from 'omni-services-common';
import { Circle, CircleMarker, Pane, SVGOverlay } from 'react-leaflet';
import MapFirmItemCustom from '../MapFirmItemCustom';

export const MapFirmsCustom = (props) => {

    const localityRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [firms, setFirms] = useState();

    const [locality, setLocality] = useState(props.locality);
    const [showUserLocation, setShowUserLocation] = useState(props.showUserLocation);

    var options = {
        showSelectFirm: true,
        needToLoggedIn: false,
        enableBackButton: true
    };

    useEffect(() => {
        setFirms(props.firms);
        console.log(firms);
    }, [
        props.firms
    ]);

    useEffect(() => {

        setLocality(props.locality);
        setShowUserLocation(props.showUserLocation);
    }, [props.locality, props.showUserLocation])


    const submitClick = (firm) => {
        console.log(firm);
        if (props.onClick != undefined) {
            props.onClick(firm);
        }
    }

    return (
        <div>
            <OpenStreetMapCustom
                height={"90vh"}
                zoom={11}
                showUserLocation={showUserLocation}
                locality={locality}
                ref={localityRef}
                overlays={firms?.map((firm) => (
                    <MapFirmItemCustom 
                        locality={firm?.address?.locality} 
                        name={firm?.name} number={firm?.number} 
                        text={firm?.name}
                        onChoose={() => {submitClick(firm);}} 
                    /> 
                ))}
            />
        </div>
    )

}

export default (MapFirmsCustom);