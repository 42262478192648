import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { needAuthFastShow, setIsAuthorized, setOwnersFirms, setSelectedOwnerFirm, setUser } from '../../rootSlice';
import s from './AuthFormFast.module.css';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { RestCustomService } from 'omni-services-utils';
import { FormCustom, UserProcessingService } from 'omni-services-common';
import { InputTextCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../services/base/config-custom-service/ConfigCustomService';
 

export var AuthFormFast = (props) => {

    const formRef = useRef(null)
    const dispatch = useDispatch();

    const state = useSelector(state => state);
    const auth = useSelector(state => state.auth);

    var [roles, setRoles] = useState([]);

    var [error, setError] = useState();

    var [name, setName] = useState();
    var [phone, setPhone] = useState();
    var [code, setCode] = useState('');

    var [showMain, setShowMain] = useState(true);

    const options = {
        showFooterButtons: true,
        showMenuButtons: false,
        readOnly: false,
        submitText: "Получить код",
        enableCaptcha: true,
        editMode: true
    };

    var [opts, setOpts] = useState(options);

    RestCustomService.setUrl(ConfigCustomService.getUrl());


    const fillRoles = () => {

        RestCustomService.Get(
            `/user/get/roles`, 
            null, 
            false, 
            (json) => {
                setRoles(json);
            }, 
            (err) => {}) 
    }

    const fillFirm = (user) => {
        if (!user) {
            return;
        }

        if (!user.role) {
            return;
        }

        if (UserProcessingService.hasOwnerAccess()) {
            RestCustomService.Get(
                `/firm/owner/get-owners-firms`, 
                null, 
                true, 
                (json) => { dispatch(setOwnersFirms(json)); dispatch(setSelectedOwnerFirm(json[0]?.number)); }, 
                (err) => {})
        }
    }

    const handleRequest = () => {

        RestCustomService.Post(
            `/user/fast-register-or-sign-in-by-sms`,
            { "name": name, "phone": phone, "captchaCode": formRef.current.getCaptchaCode() },
            null,
            (user) => {
                var o = options;
                o.enableCaptcha = false;
                setOpts(o);
                setShowMain(false);
                setError('');
            }, (err) => {
                setError(err); 
            });
    };

    const sendSmsCode = () => {
        RestCustomService.Post(
            `/confirmationcode/register-or-auth/fast/sms/${code}`,
            null,
            true,
            (user) => {
                UserProcessingService.update(user);
                closeForm();
                setError('');
            }, (err) => {
                setError(err);
        });
    };

    const handleSendCode = () => {
        sendSmsCode(code);
    };

    const closeForm = () => {
        dispatch(needAuthFastShow(false));
    }

    useEffect(() => {
        fillRoles();
        fillFirm();
    }, [])

    const validation = () => {

    }

    const handleSubmit = () => {

        if (showMain) {
            handleRequest();
        } else {
            setShowMain(true);

            var o = options;
            o.enableCaptcha = true;
            setOpts(o);
            handleSendCode();
        }

    }

    return (
        <div className={auth.needToShowFast ? s.visible : s.invisible}>
            <div className={s.authForm} id={state.settings.theme === "dark" ? s.dark : s.light}>
                
                <FormCustom
                title={"Регистрация"}
                options={opts}
                success={''}
                error={error}
                onSubmit={handleSubmit}
                captchaUrl={ConfigCustomService.getUrl()}
                body={
                    <div>
                        {showMain ?
                        <div>
                        <Form.Group>
                                <InputTextCustom
                                    label="Телефон"
                                    required={false}
                                    name='phone'
                                    type='phone'
                                    placeholder="Ваш номер телефона..."
                                    message={phone}
                                    setMessage={setPhone}
                                />
                            </Form.Group>
                            <Form.Group>
                                <InputTextCustom
                                    label="Ваше имя"
                                    required={false}
                                    name='username'
                                    type='text'
                                    placeholder='Как к Вам обращаться?...'
                                    errorText="Пользователь не найден или неверный пароль"
                                    message={name}
                                    setMessage={setName}
                                />
                            </Form.Group>
                            </div>
                            : 
                            <div>
                                <Form.Group>
                                <InputTextCustom
                                        label="Последние 4 цифры номера"
                                        required={false}
                                        name='smsCode'
                                        type='text'
                                        placeholder='Введите последние 4 цифры номера...'
                                        errorText="Пользователь не найден или неверный пароль"
                                        message={code}
                                        setMessage={setCode}
                                    />
                                </Form.Group>
                            </div>
                        }
                    </div>
                }
                validation={validation}
                ref={formRef}
            />
            </div>
        </div>
    );
}

export default AuthFormFast;