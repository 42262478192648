import React, { useEffect, useRef, useState } from 'react';
import s from './FilterSlot.module.css';
import style from "../../../../special/CommonStyles.module.css";
import { useDispatch, useSelector } from 'react-redux';
import SelectStaff from '../../../choose/selectStaff/SelectStaff';
import { useParams } from 'react-router-dom';
import { RestCustomService } from 'omni-services-utils';
import { FormCustom } from 'omni-services-common';
import { DateCustomService } from 'omni-services-utils';
import { InputTextCustom, TabCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const FilterSlot = (props) => {

    const dispatch = useDispatch();
    const done = useSelector(state => state.done)

    const { firmNumber } = useParams();

    const state = useSelector(state => state)
    const tabSingleRef = useRef(null);
    const tabRangeRef = useRef(null);
    const tabAutoRef = useRef(null);


    var firms = useSelector(state => state.firms)
    var selectedNumber = useSelector(state => state.firms.selectedNumber ? state.firms.selectedNumber : null)
    var selectedFirmNumber = selectedNumber ? selectedNumber : firms?.list[0]?.number;
    var [selectedFirm, setSelectedFirm] = useState(firmNumber);

    var [tabId, setTabId] = useState('single');

    var [validateRange, setValidateRange] = useState()

    var [theme, setTheme] = useState(props.theme);
    var [editMode, setEditMode] = useState(false);
    var [filterDate, setFilterDate] = useState(DateCustomService.getDateFormatted(new Date()));
    var [duration, setDuration] = useState();
    var [required, setRequired] = useState(props.required);

    const handlingSetCalendar = (date) => {
        setFilterDate(date);
        props.setFilterDate(date);
    }

    useEffect(() => {
        setTheme(props.theme);
        setEditMode(props.editMode);
        setRequired(props.required);
        //props.setFilterDate(filterDate);
        //forceUpdate();
    }, [props.theme, props.editMode, props.required, props.setFilterDate, filterDate])

    const options = {
        readOnly: false,
        canRefresh: false,
        canSave: true,
        canDelete: true,
        showMenuButtons: false,
        showFooterButtons: false,
        submitText: "Применить",
        showSelectFirm: true,
        needToLoggedIn: true,
        editMode: true
    };

    
    var [success, setSuccess] = useState();
    var [error, setError] = useState();
    var [loading, setLoading] = useState();

    var [editMode, setEditMode] = useState(true);

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const setEditModeFunc = () => {
        setEditMode(!editMode);
    }

    const handleSubmit = (event) => {
        setError(null);
        setSuccess('');
        setError('');

        if (props.setFilterDate) {
            props.setFilterDate(filterDate);
        }

        if (props.refresh != undefined) {
            props.refresh();
        }
    }

    const isAppointmentStrategyForStaff = () => {
        return selectedFirm?.settings?.appointmentStrategy == 2;
    }

    useEffect(() => {
        
    }, [props.setFilterDate]);

    return (
        <div className={style.formWrapper} id={state.settings.theme == "dark" ? style.dark : style.light}>
            <FormCustom
                error={error}
                success={success}
                title={"Фильтр"}
                options={options}
                onSubmit={handleSubmit}
                editMode={editMode}
                setEditMode={setEditModeFunc}
                theme={state.settings.theme}
                body={
                    <div>
                        {
                            isAppointmentStrategyForStaff() ? <SelectStaff /> : null
                        }
                        <InputTextCustom
                            theme={state.settings.theme}
                            controlId="email"
                            label="Список слотов на дату"
                            required={required}
                            type="date"
                            name="filterDate"
                            placeholder="Слоты на дату..."
                            muted=""
                            message={filterDate}
                            readOnly={false}
                            setMessage={setFilterDate}
                            leftText={<FontAwesomeIcon icon={faCalendar} />}
                        />
                    </div>
                } 
                loading={loading}
                />
        </div>
    );
}

export default FilterSlot;