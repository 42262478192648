import React from 'react';

class AutoService extends React.Component {

    render() {
        return (
            <div>
            </div>
        )
    }

}

export default AutoService;