import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import s from './ConfirmByEmail.module.css';
import { useEffect } from 'react';
import { setDone } from '../../rootSlice';
import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../services/base/config-custom-service/ConfigCustomService';

function ConfirmByEmail(props) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const state = useSelector(state => state);

    const { code } = useParams();

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const handleConfirmByEmail = () => {


        RestCustomService.Get(
            `/user/confirm/${code}`, 
            null, 
            false, 
            () => {
                dispatch(setDone({
                    title: "Почтовый адрес успешно подтвержден!",
                    description: "Почтовый адрес был успешно подтвержден",
                    link: "/",
                    linkText: "Авторизация"
                }));
                navigate("/done");
            }, 
            (err) => {

                dispatch(setDone({
                    title: "Неверный код подтверждения",
                    description: "Неверный код подтверждения " + err,
                    link: "/",
                    linkText: "Авторизация"
                }));
                navigate("/");
            });
    };

    useEffect(() => {
        handleConfirmByEmail();
    }, 
    [code]);

    return (
        <div className={s.formWrapper} id={state.settings.theme === "dark" ? s.dark : s.light}>
            <h4>Смена пароля</h4>
           
        </div>
    );
}

export default ConfirmByEmail;