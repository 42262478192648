import React from 'react';
import s from './errorCustom.module.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloseButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setErrorCustom } from '../../rootSlice';

export const ErrorCustom = (props) => {

    const dispatch = useDispatch();
    const base = useSelector(state => state.base)

    const state = useSelector(state => state)
    const error = useSelector(state => state.error);


    const handleClose = () => {
        dispatch(setErrorCustom(null));
    }


    return (
        <div className={state.error.message == null ? s.invisibleModal : s.visibleModal }>
            <div className={s.errorWrapper}>
                <Modal.Dialog>
                    <Modal.Header >
                        <Modal.Title>Произошла ошибка</Modal.Title>
                        <CloseButton variant="white" onClick={() => { handleClose() }} />
                    </Modal.Header>

                    <Modal.Body>
                        <p>{state.error.message}</p>
                    </Modal.Body>

                    <Modal.Footer>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
        </div>
    );
}

export default ErrorCustom;