import React, { useEffect, useState } from 'react';
import ChooseSimple from '../choose-simple/ChooseSimple';
import { useDispatch, useSelector } from 'react-redux';
import { chooseCity, chooseRegion, chooseService, chooseServiceType } from '../../../rootSlice';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import config from "../../../config/config.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { RestCustomService } from 'omni-services-utils';
import { SelectCustomSearch } from 'omni-services-base';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';

export const SelectTicketStatuses = forwardRef((props, ref)=> {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';

    var firms = useSelector(state => state.firms)
    var selectedNumber = useSelector(state => state?.firms?.selectedNumber ?? firms?.list[0]?.number)

    var selectedFirmNumber = selectedNumber;

    const state = useSelector(state => state)

    var [slots, setSlots] = useState([]);
    var [options, setOptions] = useState()
    var [selected, setSelected] = useState(props.selected);
    var [theme, setTheme] = useState(props.theme);

    RestCustomService.setUrl(ConfigCustomService.getUrl());


    const submitClick = (type, service) => {
    }


    const getStatusesFromServer = () => {
        RestCustomService.Get(
                `/ticket/get-ticket-statuses`,
                null,
                true,
                (data) => { fillStatuses(data) },
                (err) => { console.error(err); })
        
    }

    const fillStatuses = (data) => {

        var opts = [];
        for (var i = 0; i < data.length; i++) {
            var opt = { key: data[i].value, label: data[i].description, value: data[i].index };
            opts.push(opt);
        }

        setOptions(opts);
    }

    useEffect(() => {
        getStatusesFromServer();
        setSelected(props.selected);
        setTheme(props.theme);
    }, [props.selected, props.theme]);

    useImperativeHandle(ref, () => ({

        validation() {

            var err = null;
    
            return null;
        },
        
        getResult() {
            return selected;
        },

      }));

    return (
        <SelectCustomSearch
            options={options}
            title={"Статус записи"}
            name="choosedSlot"
            selected={selected?.toString()}
            leftText={<FontAwesomeIcon icon={faStar}/>}
            theme={theme}
            setSelectedValue={setSelected}
        />
    )


});

export default SelectTicketStatuses;