import { useDispatch, useSelector } from 'react-redux';
import s from './ChangeRestoredPasswordPhone.module.css';
import { setDone } from '../../../rootSlice';
import { RestCustomService } from 'omni-services-utils';
import { ChangeRestoredPasswordPhoneCommon, FormCustom } from 'omni-services-common';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';

function ChangeRestoredPasswordPhone(props) {

    const dispatch = useDispatch();
    const registeredUser = useSelector(state => state.registeredUser);

    const state = useSelector(state => state);

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const onSuccess = () => {
        dispatch(setDone({
            title: "Пароль изменен!",
            description: "Пароль был успешно изменен",
            link: "/auth",
            linkText: "Авторизация"
        }));
    }

    return (
        <div className={s.formWrapper} id={state.settings.theme === "dark" ? s.dark : s.light}>
            <ChangeRestoredPasswordPhoneCommon 
                url={ConfigCustomService.getUrl()}
                theme={state.settings.theme}
                onSuccess={onSuccess}
                registeredUser={registeredUser}
            />
        </div>
    );
}

export default ChangeRestoredPasswordPhone;