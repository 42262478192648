import React, { useEffect, useState } from 'react';
import s from './ChooseDoubleSmall.module.css';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { SelectCustomSearch } from 'omni-services-base';

export const ChooseDoubleSmall = forwardRef((props, ref) => {

    const [options, setOptions] = useState();
    const [subOptions, setSubOptions] = useState();

    var [value, setValue] = useState();
    var [subValue, setSubValue] = useState();
    var [theme, setTheme] = useState(props.theme);
    var [editMode, setEditMode] = useState(props.editMode);

    

    const fillDirectory = (data) => {
        var opts = [];
        for (var i = 0; i < data.length; i++) {
            opts.push({label: data[i].name, value: data[i].code});
        }
        setOptions(opts);
    }

    const fillSubdirectory = (data) => {
        var opts = [];
        setSubOptions(opts);
        for (var i = 0; i < data.length; i++) {
            var ChooseSimple = { label: data[i].name, value: data[i].code };
            opts.push(ChooseSimple);
        }
        setSubOptions(opts);
    }

    useEffect(() => {
;
        props.selectDirectoryFunc().then(response => fillDirectory(response));

        if (props.value) {
            setValue(props.value);
        }

        if (props.subValue != undefined) {
            setSubValue(props.subValue);
            props.selectSubdirectoryFunc(props.value).then(response => fillSubdirectory(response));
        }
        setTheme(props.theme);
        setEditMode(props.editMode);
    }, [props.value, props.subValue, props.theme, props.editMode]
    );

    const handleClick = (val) => {
        props.selectSubdirectoryFunc(val).then(response => fillSubdirectory(response));
        setValue(val);
        setSubOptions(null);
    }

    const handleSubClick = (val) => {
        setSubValue(val);
    }

    useImperativeHandle(ref, () => ({

        validation() {
    
            var err = null;
    
        
            return err;
        },
        
        getResult() {
            

            return {value: value, subValue: subValue};
        },

    }));

    return (
        <div>
           <SelectCustomSearch
                name={props.name}
                title={props.directoryName}
                options={options} 
                setSelectedValue={handleClick}
                selected={value}
                theme={theme}
                editMode={editMode}
            /> 
            <SelectCustomSearch 
                name={props.subName}
                title={props.subDirectoryName}
                options={subOptions} 
                setSelectedValue={handleSubClick}
                selected={subValue}
                theme={theme}
                editMode={editMode}
            /> 
        </div>
    )


});

export default (ChooseDoubleSmall);