import React, { useEffect } from 'react';
import s from './ChooseSimple.module.css';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { chooseBase } from '../../../rootSlice';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { faArrowLeft, faArrowRight, faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ChooseSimple = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const base = useSelector(state => state.base)
    const { register, handleSubmit } = useForm({ defaultValues: { base } })

    const state = useSelector(state => state)

    

    var selectedValue = null;

    var options = [];

    const onSubmit = () => {
        props.chooseFunc(selectedValue);
    }

    const handleClick = (val) => {
        selectedValue = {code: val.value, name: val.label};
    }


    return (
        <div className='centralWrapper'>
            <form>
                <div className={s.formWrapper}  id={state.settings.theme == "dark" ? s.dark: s.light}>
                    <div className={s.headerWrapper}>
                        <Link
                            className={"btn btn-light " + s.chooseButtons}
                            to={props.previous}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </Link><h4>{props.directoryName}</h4>
                    </div>
                    <div className={s.bodyWrapper}>
                        <Form.Group className="mb-3">
                            <Form.Label>{props.directoryDescription}</Form.Label>
                            <Select options={props.options} placeholder="Начните набирать название..."
                                onChange={(value) => handleClick(value)}
                            />
                        </Form.Group>
                    </div>
                    <div className={s.footerWrapper}>
                        <Button
                            className={"btn btn-light btn-bg " + s.chooseButtons}
                            id={state.settings.theme == "dark" ? s.dark: s.light}
                            role="button"
                            onClick={onSubmit}>
                            Далее <FontAwesomeIcon icon={faArrowRight} />
                        </Button>
                    </div>
                    <div/>
                </div>
            </form>
        </div>
    )


}

export default (ChooseSimple);