import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService.jsx';
import { UserProcessingService } from 'omni-services-common';


export const SlotService = {

    // MANAGER
    
    getByManager: function (onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        RestCustomService.Get(
            `/slot/manager/get/many/`,
            null,
            true,
            (data) => { onSuccess(data); },
            (err) => { onError(err); }
        );
    },

    addByManager: function (slot, onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        RestCustomService.Post(
            `/slot/manager/add/`,
            slot,
            true,
            (data) => { onSuccess(data); },
            (err) => { onError(err); }
        );
    },

    // OWNER

    getByOwner: function (body, onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        RestCustomService.Post(
            `/slot/owner/get/`,
            body,
            true,
            (data) => { onSuccess(data); },
            (err) => { onError(err); }
        );
    },

    addByOwner: function (slot, onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        RestCustomService.Post(
            `/slot/owner/add/`,
            slot,
            true,
            (data) => { onSuccess(data); },
            (err) => { onError(err); }
        );
    },

    addByDateRangeByOwner: function (slot, onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        RestCustomService.Post(
            `/slot/owner/add-by-date-range/`,
            slot,
            true,
            (data) => { onSuccess(data); },
            (err) => { onError(err); }
        );
    },

    addSlotAutoScheduleByOwner: function (slotAutoSchedule, onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        RestCustomService.Post(
            `/slotAutoSchedule/owner/save/`,
            slotAutoSchedule,
            true,
            (data) => { onSuccess(data); },
            (err) => { onError(err); }
        );
    },

    updateByOwner: function (slot, onSuccess, onError) {

        RestCustomService.setUrl(this.getUrl());

        RestCustomService.Patch(
            `/Slot/owner/edit`, 
            slot, 
            true, 
            (data) => { onSuccess(data); }, 
            (err) => {onError(err);}
            );

    },

    deleteByOwner: function (slot, onSuccess, onError) {

        RestCustomService.setUrl(this.getUrl());

        RestCustomService.Delete(
            `/Slot/owner/delete/${slot?.id}`, 
            null, 
            true, 
            (data) => { onSuccess(data); }, 
            (err) => {onError(err);}
        );
        
    },

    // ADMIN

    getByAdmin: function (body, onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        RestCustomService.Get(
            `/slot/admin/get/all/${body?.firmNumber}`,
            null,
            true,
            (data) => { onSuccess(data); },
            (err) => { onError(err); }
        );
    },

    get: function (body, onSuccess, onError) {

        if (UserProcessingService.hasAdminAccess()) {

            this.getByAdmin(body, onSuccess, onError);
        } else if (UserProcessingService.hasOwnerAccess()) {

            this.getByOwner(body, onSuccess, onError);

        } else if (UserProcessingService.hasManagerAccess()) {

        } else if (UserProcessingService.hasWorkerAccess()) {

        } else {
            
        }
    },


    update: function (firmEdit, onSuccess, onError) {

        if (UserProcessingService.hasAdminAccess()) {
            

        } else if (UserProcessingService.hasOwnerAccess()) {

        } else if (UserProcessingService.hasManagerAccess()) {

        }
    },
}