import React, { useState, useEffect, useRef } from 'react';
import s from './FirmMap.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { chooseFirm } from '../../../rootSlice';
import { useNavigate } from 'react-router-dom'
import { getFromFirmPath } from '../chooseProcessing/ChooseProcessing';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';
import { RestCustomService } from 'omni-services-utils';
import { AddressFiasCustom, FormCustom } from 'omni-services-common';
import MapFirmsCustom from './firm-map-list-item/map-firms/MapFirmsCustom';
import { InputTextCustom } from 'omni-services-base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime } from '@fortawesome/free-solid-svg-icons';
import FirmMapDescription from './firm-map-description/FirmMapDescription';
import FirmMapBackCustom from './firm-map-back/FirmMapBackCustom';

export const FirmMap = (props) => {

    const addressRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const region = useSelector(state => state.order.region);
    const city = useSelector(state => state.order.city);
    const serviceType = useSelector(state => state.order.serviceType);
    const service = useSelector(state => state.order.service);

    const [firms, setFirms] = useState();
    const [firm, setFirm] = useState();

    const [url, setUrl] = useState(props.url);

    const state = useSelector(state => state)

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    var firmItems = [];

    var options = {
        showFooterButtons: true,
        showMenuButtons: false,
        readOnly: true,
        submitText: "Выбрать фирму",
        showSelectFirm: true,
        needToLoggedIn: false,
        enableBackButton: true,
    };

    const submitClick = (selectedValue) => {
        dispatch(chooseFirm(selectedValue));
        if (serviceType.name === "Стоянка") {

            navigate("./../parking-date-interval");
            return;
        }

        navigate(getFromFirmPath(selectedValue));
    }

    const onChoose = (choosedFirm) => {
        setFirm(choosedFirm);
    }

    async function getFirmsFromServer() {

        var body = {
            regionCode: region.code,
            cityCode: city.code,
            serviceTypeCode: serviceType.code,
            serviceCode: service.code,
            transportCategoryCode: 1,
            transportSubcategoryCode: 1
        };

        RestCustomService.Post(
            `/firm/get/for-customer`,
            body,
            false,
            (data) => {
                console.log(data);
                fillFirms(data)
            },
            (err) => {/*setError(err)*/
                console.log(err);

            }
        );
    }

    useEffect(() => {
        getFirmsFromServer();
    }, [])

    const fillFirms = (data) => {
        setFirms(data);
        for (var i = 0; i < data.length; i++) {
            //firmItems.push( <FirmListItem firm={data[i]}/> );
        }
    }

    const onClick = (firm) => {
        console.log("$$$$$$$");
        console.log(firm);
    }

    if ((firms !== undefined)) {
        return (
            <div className={s.mainWrapper}>

                <MapFirmsCustom
                    showMap={true}
                    showLiveSearch={false}
                    showAddress={false}
                    showUserLocation={true}
                    address={null}
                    theme={state.settings.theme}
                    ref={addressRef}
                    editMode={true}
                    url={ConfigCustomService.getUrl()}
                    firms={firms}
                    onClick={onChoose}
                />
                {
                    !!firm ?
                        <div className={s.aboutFirmWrapper}>
                            <div className={s.formWrapper} id={state.settings.theme === "dark" ? s.dark : s.light}>
                                <FirmMapDescription
                                    theme={state.settings.theme}
                                    firm={firm}
                                    url={ConfigCustomService.getUrl()}
                                    onSubmit={submitClick}

                                />
                            </div>
                        </div>
                        :
                        <div className={s.aboutFirmWrapper}>
                            <div className={s.formWrapper} id={state.settings.theme === "dark" ? s.dark : s.light}>
                                <FirmMapBackCustom theme={state.settings.theme} />
                            </div>
                        </div>
                }
            </div>
        )
    } else {
        <div>Не найдено фирм по Вашему запросу</div>
    }
}

export default (FirmMap);