import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './ManageParkingTicket.module.css';
import style from '../../../../special/CommonStyles.module.css';
import AddParkingTicket from '../add-parking-ticket/AddParkingTicket';
import CardParkingTicket from '../card-parking-ticket/CardParkingTicket';
import { useParams } from 'react-router-dom';
import CardParkingTicketSmall from '../card-parking-ticket-small/CardParkingTicketSmall';
import DraggableParkingTickets from '../draggable-field/DraggableParkingTickets';
import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';

export const ManageParkingTicket = (props) => {

  
  const { firmNumber } = useParams();
  var [theme, setTheme] = useState();
  var [text, setText] = useState();
  var [editMode, setEditMode] = useState(false);
  var [error, setError] = useState();

  const state = useSelector(state => state)
  var firms = useSelector(state => state.firms)
  var selectedNumber = useSelector(state => state.firms.selectedNumber ? state.firms.selectedNumber : null)
  var selectedFirmNumber = firmNumber; //selectedNumber ?? firms?.list[0]?.number;

  var [tickets, setTickets] = useState([]);
  var [parkingArea, setParkingArea] = useState();

  RestCustomService.setUrl(ConfigCustomService.getUrl());

  const handleSubmit = (event) => {

  }

  const setEditModeFunc = () => {
    setEditMode(!editMode);
  }

  const getAllTicketByAdmin = () => {

    RestCustomService.Get(
      `/parkingticket/owner/get/${selectedFirmNumber}`,
      null, 
      true, 
      (data) => { fillTickets(data); }, 
      (err) => { console.error(err); })
  }

  const getParkingAreaFromServer = () => {
    RestCustomService.Get(
        `/parkingarea/owner/get/${selectedFirmNumber}`,
        null,
        true,
        (data) => {
            if (data[0]) {
                setParkingArea(data[0]);
            }
        },
        (err) => { console.error(err); })
}

  const fillTickets = (data) => {
    setTickets([]);
    var st = [];
    for (var i = 0; i < data?.length; i++) {
      st.push(data[i]);
    }
    setTickets(st);
  }

  useEffect(() => {
    setTheme(props.theme);
    setText(props.text);
    getAllTicketByAdmin();
    getParkingAreaFromServer();
  }, 
  [props.theme, props.text, selectedFirmNumber])

  return (
    <div >
        <AddParkingTicket parkingArea={parkingArea} />
        <div className={s.slotListWrapper}>
        { /*tickets.map((item) => (<CardParkingTicketSmall parkingTicket={item} refresh={() => {}} />))*/ }
        <DraggableParkingTickets tickets={tickets} theme={state.settings.theme} />
        </div>
    </div>
  )
}

export default ManageParkingTicket;