import { UserProcessingService } from "omni-services-common";
import config from "../../../config/config.json";

const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';

export const refresh = () => {
 
    const requestOptions = {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'accessKey': UserProcessingService.getToken()
        }
    };
    return fetch(`${url}/api/user/get`, requestOptions)
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {throw error;});
}

export const edit = (name, email, phone) => {
    
    const requestOptions = {
        method: 'PATCH',
        headers: {
            'Content-Type': 'application/json',
            'accessKey': UserProcessingService.getToken()
        },
        body: JSON.stringify({ "name": name, "email": email, "phone": phone })
    };
    
    return fetch(`${url}/api/user/update`, requestOptions)
        .then((response) => response.json())
        .then((responseData) => {
            return responseData;
        })
        .catch(error => {throw error;});
}