import React from 'react';
import s from './Done.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

export const Done = (props) => {

    const dispatch = useDispatch();
    const done = useSelector(state => state.done)

    const state = useSelector(state => state)


    return (
        <div className={s.doneWrapper}>
            <h4>{done.title}</h4>
            <div>{done.description}</div>
            <NavLink to={done.link} end>
               {done.linkText}
            </NavLink>
        </div>
    );
}

export default Done;