export var ticketDefault = {
    slotId: null,
    firmNumber: null,
    userId: null,
    userPhone: null,
    userName: null,
    staffId: null,
    state: null,
    serviceTypeCode: null,
    serviceCode: null,
    transportCategoryCode: null,
    transportSubcategoryCode: null,
    manufacturerCode: null,
    manufacturerModelCode: null,
    date: null
}

export default ticketDefault;