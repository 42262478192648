import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chooseCity, chooseRegion } from '../../rootSlice.js';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import config from "../../config/config.json";
import ChooseDoubleSmall from '../choose-double-small/ChooseDoubleSmall.jsx';
import {forwardRef, useImperativeHandle, useRef} from 'react';

export const SelectManufacturerModelSmall = forwardRef((props, ref) => {

    const chooseRef = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    var [manufacturerCode, setManufacturerCode] = useState('')
    var [manufacturerModelCode, setManufacturerModelCode] = useState('')
    const { register, handleSubmit } = useForm({ defaultValues: { manufacturerModelCode } })
    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';
    const state = useSelector(state => state)
    var [editMode, setEditMode] = useState(props.editMode);

    var [theme, setTheme] = useState(props.theme);

    var cities = [];
    var options = [];

    const submitClick = (selectedRegion, selectedCity) => {
        dispatch(chooseRegion(selectedRegion));
        dispatch(chooseCity(selectedCity));
        navigate("./../select-service");
    }

    const getManufacturersFromServer = () => {
        const apiUrl = `${url}/api/manufacturer/get/all`;
        return fetch(apiUrl)
            .then((response) => response.json())
            .then((responseData) => {
                return responseData;
            })
            .catch(error => console.warn(error));
    }

    const getManufacturerModelsFromServer = (manufacturerCode) => {
        const apiUrl = `${url}/api/manufacturerModel/get/manufacturer/${manufacturerCode}`;
        return fetch(apiUrl)
            .then((response) => { return response.json() })
    }

    useEffect(() => {
        getManufacturersFromServer();
        
        if (props.manufacturerCode) {
            setManufacturerCode(props.manufacturerCode);
        }
        if (props.manufacturerModelCode) {
            setManufacturerModelCode(props.manufacturerModelCode);
            getManufacturerModelsFromServer(props.manufacturerCode);
        }
        setTheme(props.theme);
        setEditMode(props.editMode);
    }, [props.manufacturerCode, props.manufacturerModelCode, props.theme, props.editMode]);

    useImperativeHandle(ref, () => ({

        validation() {
    
            var err = null;
    
        
            return err;
        },
        
        getResult() {
            
            var obj = chooseRef.current.getResult();



            return {manufacturerCode: obj.value, manufacturerModelCode: obj.subValue};
        },

    }));

    return (
        <ChooseDoubleSmall
            ref={chooseRef}
            options={options}
            name={"manufacturerCode"}
            subName={"manufacturerModelCode"}
            value={manufacturerCode}
            subValue={manufacturerModelCode}
            directoryName="Марка"
            subDirectoryName="Модель"
            directoryDescription="Выберите регион" 
            subDirectoryDescription="Выберите город"
            previous="./manufacturerCode"
            next="./select-service"
            chooseFunc={submitClick}
            selectDirectoryFunc={getManufacturersFromServer}
            selectSubdirectoryFunc={getManufacturerModelsFromServer} 
            theme={theme}
            editMode={editMode}/>
    )


});

export default SelectManufacturerModelSmall;