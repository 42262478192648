export var DayScheduleObject = {
    dayOfWeek: null,
    beginningTime: null,
    endingTime: null,
    brakeTimeBegin: null,
    brakeEnabled: false,
    brakeTimeEnd: null,
    brakeDuration: null,
    isWorking: false
}

export default DayScheduleObject;