export var parkingTicketDefault = {
    id: null,
    begin: null,
    end: null,
    firmNumber: null,
    userId: null,
    state: null,
    serviceTypeCode: null,
    serviceCode: null,
    transportCategoryCode: null,
    transportSubcategoryCode: null,
    manufacturerCode: null,
    manufacturerModelCode: null,
    parkingPlaceNumber: null
}

export default parkingTicketDefault;