import React, { useState } from 'react';
import { useEffect } from 'react';
import style from './../../special/CommonStyles.module.css';
import s from './ParkingArea.module.css'
import ParkingPlace from './parking-place/ParkingPlace';
import { useDispatch, useSelector } from 'react-redux';
import { chooseParkingPlace } from '../../rootSlice';
import { useNavigate } from 'react-router-dom';
import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../services/base/config-custom-service/ConfigCustomService';


export const ParkingArea = (props) => {

    const state = useSelector(state => state)

    const firm = useSelector(state => state.order.firm);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    var [text, setText] = useState();
    var [theme, setTheme] = useState(state.settings.theme);
    var [error, setError] = useState();

    var [places, setPlaces] = useState([]);

    var firms = useSelector(state => state.firms)
    var selectedNumber = useSelector(state => state?.firms?.selectedNumber ?? firms?.list[0]?.number)

    var selectedFirmNumber = selectedNumber;

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const getPlacesFromServer = (parkingAreaId) => {
        RestCustomService.Get(
            `/parkingplace/guest/get/${parkingAreaId}`,
            null,
            true,
            (data) => { setPlaces(data); },
            (err) => { setError(err); }
        );
    }

    const getAreasFromServer = () => {
        RestCustomService.Get(
            `/parkingarea/guest/get/${firm?.number}`,
            null,
            true,
            (data) => { getPlacesFromServer(data[0].id); },
            (err) => { setError(err); }
        );
    }

    const onChoose = (place) => {
        dispatch(chooseParkingPlace({id: place.id, number: place.number}));
        navigate("./../parking-ticket");
    }

    useEffect(() => {
        setText(props.text);
        setTheme(props.theme);
        getAreasFromServer();
    }, [
        props.text,
        props.theme
    ]);


    return (
        <div id={(state.settings.theme == "dark") ? s.dark : s.light}>
            <h3>Выберите место</h3>
            <div className={s.wrapper}>
                {
                    places.map((item, index) => (
                        <ParkingPlace 
                            top={item?.leftTopPoint?.y} 
                            left={item?.leftTopPoint?.x} 
                            state={item?.state} 
                            number={item?.number} 
                            id={item?.id}
                            onChoose={(place) => onChoose(place)}/>
                    ))
                }
            </div>
        </div>
    )
}

export default ParkingArea;