import React, { useState, useEffect } from 'react';
import s from './ToFirmTicket.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom'
import { needAuthFastShow, needAuthShow, setDone, setErrorCustom } from '../../../rootSlice';
import config from "../../../config/config.json";
import style from '../../../special/CommonStyles.module.css';
import { RestCustomService } from 'omni-services-utils';
import SelectServiceToFirm from '../selectServiceToFirm/SelectServiceToFirm';
import InfoToFirm from './infoToFirm/InfoToFirm';
import { useRef } from 'react';
import SelectModelToFirm from '../selectModelToFirm/SelectModelToFirm';
import SelectSlotTimeToFirm from '../selectSlotTimeToFirm/SelectSlotTimeToFirm';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';
import { FormCustom, UserProcessingService } from 'omni-services-common';

export const ToFirmTicket = () => {

    const {firmNumber} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const themeState = useSelector(state => state.settings.theme);

    var infoRef = useRef();
    var serviceRef = useRef();
    var modelRef = useRef();
    var slotRef = useRef();

    var [theme, setTheme] = useState(themeState);
    var [error, setError] = useState();

    const state = useSelector(state => state)

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const options = {
        showFooterButtons: true,
        showMenuButtons: false,
        readOnly: true,
        submitText: "Записаться",
        cancelText: "Назад",
        enableBackButton: true
    };


    const submitClick = () => {

        if (!UserProcessingService.hasStoredUser()) {
            dispatch(needAuthFastShow(true));
            return;
        }

        var firm = infoRef.current.getResult();
        var serviceObj = serviceRef.current.getResult(); 
        var manufacturerObj = modelRef.current.getResult();
        var slotId = slotRef.current.getResult();
        
        var body = {
            slotId: slotId,
            firmNumber: firm.number,
            userId: UserProcessingService.get().id,
            serviceTypeCode: serviceObj.selectedValue,
            serviceCode: serviceObj.selectedSubValue,
            manufacturerCode: manufacturerObj.selectedValue,
            manufacturerModelCode: manufacturerObj.selectedSubValue
        }
        
        RestCustomService.Put(
            `/ticket/user/make-appointment`,
            body,
            true,
            () => {
                dispatch(setDone({
                    title: "Успешная запись!",
                    description: "Вы были успешно записаны",
                    link: "/",
                    linkText: "Перейдите по ссылке, чтобы выбрать что-нибудь ещё!"
                }));
                navigate("/done");
            },
            (err) => {setError(err)}
        );
    }

    useEffect(() => {

    }, [])

    return (
        <div className={style.formWrapper} id={state.settings.theme == "dark" ? style.dark : style.light}>
            <FormCustom
                title={"Новая запись"}
                options={options}
                onSubmit={submitClick}
                body={
                    <div>
                        <InfoToFirm 
                            firmNumber={firmNumber} 
                            ref={infoRef} />
                        <br />
                        <SelectServiceToFirm 
                            firmNumber={firmNumber} 
                            ref={serviceRef}
                            />
                        <br />
                        <SelectModelToFirm
                            firmNumber={firmNumber} 
                            ref={modelRef}
                            />
                        <br />
                        <SelectSlotTimeToFirm
                            firmNumber={firmNumber}
                            theme={state.settings.theme}
                            ref={slotRef} 
                            />
                        <br />
                    </div>
                }
                theme={state.settings.theme}
                error={error}
            />

        </div>
    )
}

export default (ToFirmTicket);