import React, { useEffect, useState } from 'react';
import s from './ConfirmEmailCustom.module.css';
import style from "../../special/CommonStyles.module.css";
import { useDispatch, useSelector } from 'react-redux';
import { needAuthShow } from '../../rootSlice';
import { faEnvelope, faShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { RestCustomService } from 'omni-services-utils';
import { InputTextCustom } from 'omni-services-base';
import { ConfirmEmailCustomCommon, FormCustom } from 'omni-services-common';
import { ConfigCustomService } from '../../services/base/config-custom-service/ConfigCustomService';

export const ConfirmEmailCustom = (props) => {

    const registeredUser = useSelector(state => state.registeredUser);

    const state = useSelector(state => state)


    return (
        <div>
            <ConfirmEmailCustomCommon 
                url={ConfigCustomService.getUrl()}
                theme={state.settings.theme}
                registeredUser={registeredUser}
            />
        </div>
    );
}

export default ConfirmEmailCustom;