import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './CardTicketSmall.module.css';
import style from '../../../../special/CommonStyles.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { RestCustomService } from 'omni-services-utils';
import { FormCustom } from 'omni-services-common';
import { LabelCustom } from 'omni-services-common';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';

export const CardTicketSmall = (props) => {

  const navigate = useNavigate();
  const {firmNumber} = useParams();

  var [theme, setTheme] = useState();
  var [text, setText] = useState();
  var [editMode, setEditMode] = useState(false);
  var [error, setError] = useState();

  var [meta, setMeta] = useState();

  var [id, setId] = useState(props.ticket.id);

  var [userName, setUserName] = useState(props.ticket?.user?.name ?? "(имя не указано)");
  var [dateTime, setDateTime] = useState(props.ticket?.slot?.begin);

  var [serviceName, setServiceName] = useState(props.ticket?.service?.name);

  var [manufacturer, setManufacturer] = useState(props.ticket?.manufacturer?.name ?? "(не указано)");
  var [manufacturerModel, setManufacturerModel] = useState(props.ticket?.manufacturerModel?.name ?? "");

  var [shortCode, setShortCode] = useState(props.ticket?.shortCode);

  var firms = useSelector(state => state.firms)
  var selectedNumber = useSelector(state => state.firms.selectedNumber ? state.firms.selectedNumber : null)
  var selectedFirmNumber = selectedNumber ?? firms?.list[0]?.number;

  /*var [staff, setStaff] = useState(props.staff);*/

  const state = useSelector(state => state)

  RestCustomService.setUrl(ConfigCustomService.getUrl());


  const handleSubmit = (event) => {

  }

  const setEditModeFunc = () => {
    //setEditMode(!editMode);

  }

  const goTo = () => {
    navigate(`/firm/${firmNumber}/edit-ticket/${id}`);
  }

  const handlingRefresh = () => {

  }

  const getFormattedDate = (dateTime) => {

    var optionsDate = { year: 'numeric', month: 'numeric', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit' };

    if (dateTime == null) {
      return null;
    }

    return new Date(dateTime ?? null).toLocaleDateString("ru-RU", optionsDate);
  }

  const handlingDelete = () => {
    
    RestCustomService.Patch(
      `/ticket/owner/decline/${selectedFirmNumber}/${id}`,
      null,
      true,
      (data) => {  },
      (err) => { console.error(err); })
  }

  const getStateTagById = (state) => {

    switch (state) {
      case 0:
        return {text: "Открыта", color: "green", class: s.lightBlue};
      case 1:
        return {text: "Взята в работу", color: "orange", class: s.orange}
      case 2:
        return {text: "Отклонена", color: "orange", class: s.orange};
      case 3:
        return {text: "Завершена", color: "red", class: s.red};
      case 4:
        return {text: "Перезаписана", color: "blue", class: s.blue};
      default:
        return {text: "Не определен", color: "black", class: s.black};
    }
  }

  useEffect(() => {

    var inMeta = {
      topLeft: <LabelCustom obj={getStateTagById(props.ticket?.state)} />,
      topRight: null,
      bottomLeft: null,
      bottomRight: null
    }


    setMeta(inMeta);
    setTheme(props.theme);
    setText(props.text);
  },
    [props.theme, props.text, props.meta, props.ticket])

  var options = {
    showSelectFirm: false,
    showMenuButtons: true
  };

  return (
    <div className={`${s.wrapper} ${getStateTagById(props.ticket?.state).class }`} id={(state.settings.theme == "dark") ? style.dark : style.light}>
      <FormCustom
        title={''}
        onSubmit={handleSubmit}
        editMode={editMode}
        setEditMode={setEditModeFunc}
        refresh={handlingRefresh}
        delete={handlingDelete}
        body={
          <div className={s.descriptionWrapper} onClick={goTo}>
            <h5>{shortCode}</h5>
            <p>{`Клиент: ${userName}`}</p>
            <p>{`Услуга: ${props.ticket?.serviceType?.name + ", " + props.ticket?.service?.name ?? ''}`}</p>
            <p>{`Авто: ${manufacturer} ${manufacturerModel}`}</p>
            <p>{`На дату: ${getFormattedDate(props.ticket?.slot?.begin) ?? '(отсутствует)'}`}</p>
            <p>{`Сообщений: ${0}`}</p>
            {/*<InputTextCustom
                            theme={state.settings.theme}
                            controlId="manufacturerModel"
                            label="Авто"
                            required={true}
                            type="text"
                            name="manufacturerModel"
                            placeholder="(модель не указана)"
                            muted=""
                            message={manufacturerModel}
                            setMessage={setUserName}
                            readOnly={!editMode}
                            leftText={<FontAwesomeIcon icon={faCar} />}
                        />
                        <InputTextCustom
                            theme={state.settings.theme}
                            controlId="userName"
                            label="Имя"
                            required={true}
                            type="text"
                            name="userName"
                            placeholder="(имя не указано)"
                            muted=""
                            message={userName}
                            setMessage={setUserName}
                            readOnly={!editMode}
                            leftText={<FontAwesomeIcon icon={faPerson} />}
                      />*/}
          </div>
        }
        theme={state.settings.theme}
        error={error}
        options={options}
        meta={{
          topLeft: <LabelCustom obj={getStateTagById(props.ticket?.state)} />,
          topRight: null,
          bottomLeft: null,
          bottomRight: null
        }}
      />
    </div>
  )
}

export default CardTicketSmall;