import React, { useEffect, useState } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faCalendar, faEnvelope, faHammer, faPerson, faPhone } from '@fortawesome/free-solid-svg-icons';
import { InputTextCustom } from 'omni-services-base';
import UserSearchCustom from './user-search-custom/UserSearchCustom';
import { NavLink } from 'react-router-dom';
import { InfoBlockCustom } from 'omni-services-common';

export const TabUserLink = forwardRef((props, ref) => {

    const searchedUserRef = useRef();
    const dispatch = useDispatch();
    
    const done = useSelector(state => state.done)

    const state = useSelector(state => state)

    var [userName, setUserName] = useState(props.staff?.userName);
    var [positionName, setPositionName] = useState(props.staff?.positionName);
    var [theme, setTheme] = useState(props.theme);

    var [editMode, setEditMode] = useState(props.editMode);

    var [unlink, setUnlink] = useState(false);

    var [staff, setStaff] = useState(props.staff);

    const [url, setUrl] = useState(props.url);




    useEffect(() => {
        setStaff(props.staff);
        setEditMode(props.editMode);
        setUserName(props.staff?.userName);
        setPositionName(props.staff?.positionName);
        setTheme(props.theme);
        setUrl(props.url);
    }, [props.firm,
    props.theme,
    props.staff,
    props.editMode,
    props.url,
    ]);

    const nameValidation = (text) => {

        if (!text) {
            return "Поле обязательно";
        }

        if (text.length == 0) {
            return "Поле обязательно";
        }

        if (/^[0-9a-zA-Zа-яА-ЯёЁ\s-=+!?&$%*]*$/.test(text) == false) {
            return "только буквы и пробел";
        }

        return null;
    }

    const positionValidation = (text) => {

        if (!text) {
            return "Поле обязательно";
        }

        if (text.length == 0) {
            return "Поле обязательно";
        }

        if (/^[0-9a-zA-Zа-яА-ЯёЁ\s-=+!?&$%*]*$/.test(text) == false) {
            return "только буквы и пробел";
        }

        return null;
    }

    const unlinkUser = () => {
        setUnlink(true);
    }



    useImperativeHandle(ref, () => ({

        validation() {

            var err = null;

            console.log(userName);
            err = nameValidation(userName);
            if (err) {
                return "Поле имя: " + err;
            }

            err = positionValidation(positionName);
            if (err) {
                return "Поле должность: " + err;
            }

            err = searchedUserRef.current.validation();
            if (err) {
                return "Поле Статус: " + err;
            }

            return null;
        },

        getUnlink() {
            return unlink;
        },

        getResult() {

            var selectedUser = searchedUserRef.current?.getResult();

            console.log(selectedUser);

            return selectedUser;

        },

    }));


    return (
        <div>
            {
                !staff?.userId ?
                    <div>
                        <p>Пригласить пользователя</p>
                        <br />
                        <InfoBlockCustom info={`Здесь Вы можете привязать вашего сотрудника и его аккаунт. 
                        После этого, Ваш сотрудник сможет получать оповещения. 
                        Для привязки, необходимо, чтобы сотрудник зарегистрировался в системе. После его регистрации, Вы сможете найти его одним из способов:
                         по телефону, по почте или ссылке в Telegram.`}/>
                        <br />
                        <UserSearchCustom theme={theme} editMode={editMode} url={url} ref={searchedUserRef} />
                    </div>
                    : 
                    <div>
                        {
                            unlink ?
                            <div>
                                Пользователь отвязан. Необходимо сохранить форму.
                            </div>
                            :
                            <NavLink onClick={unlinkUser} >
                                Отвязать пользователя
                            </NavLink>
                        }
                    </div>
            }
        </div>
    );
});

export default TabUserLink;