import React, { useEffect, useState } from 'react';
import s from './ChooseServiceSettings.module.css';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Select from 'react-select'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { faArrowLeft, faArrowRight, faCircleArrowLeft, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import config from "../../../config/config.json";
import SettingsCheckDouble from '../../../forms/settings-check-double/SettingsCheckDouble';

export const ChooseServiceSettings = forwardRef((props, ref) => {

    var chooseServiceRefs = useRef([]);

    var [counter, setCounter] = useState(0);

    var [options, setOptions] = useState(null);
    var [directions, setDirections] = useState(props.directions);
    var [editMode, setEditMode] = useState(props.editMode);

    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';;

    const getServiceTypesFromServer = () => {
        const apiUrl = `${url}/api/serviceType/get/all`;
        return fetch(apiUrl)
            .then((response) => response.json())
            .then((responseData) => {
                return responseData;
            })
            .catch(error => console.warn(error));
    }

    const getServicesFromServer = (serviceTypeCode) => {
        const apiUrl = `${url}/api/service/get/type/${serviceTypeCode}`;
        return fetch(apiUrl)
            .then((response) => { return (response.json()) })
    }

    const fillServiceTypes = (data) => {
        var opts = [];

        for (var i = 0; i < data.length; i++) {
            var obj = { label: data[i].name, value: data[i].code, checked: directions?.serviceTypesCode?.some(e => e == data[i].code) ?? false };
            opts.push(obj);
        }
    
        {
            setOptions(opts);
        }
    }

    const fillServices = (data) => {
        /*for (var i = 0; i < data.length; i++) {
            var obj = { label: data[i].name, value: data[i].code };
            opts.push(obj);
        }
        setOptions(opts);*/
    }

    useEffect(() => {
        {
            getServiceTypesFromServer().then(response => fillServiceTypes(response));
        }
        {
            setDirections(props.directions);
        }
        setEditMode(props.editMode);

    }, [props.directions, props.editMode])

    useImperativeHandle(ref, () => ({
        
        getResult() {

            var serviceTypesCode = [];
            var servicesCode = [];

            for (var i = 0; i < options.length; i++) {
                var res = chooseServiceRefs.current[i].getResult();
                
                if (res.val.checked) {
                    serviceTypesCode.push(res.val.value)
                }

                for (var j = 0; j < res.arr.length; j++) {
                    if (res.arr[j].checked) {
                        servicesCode.push(+res.arr[j].value)
                    }
                }

                // TODO: заполнить объекты
                
            }

            return {serviceTypesCode: serviceTypesCode, servicesCode: servicesCode};
        },

    }));

    return (
        <div>
            {options?.map((object, index) =>
                <SettingsCheckDouble
                    label={object.label}
                    code={object.value}
                    value={object.value}
                    checked={object.checked}
                    get={getServicesFromServer}
                    fill={fillServices}
                    subNotes={directions?.servicesCode}
                    index={index}
                    counter={counter}
                    setCounter={(val) => setCounter(val)}
                    editMode={editMode}
                    ref={(element) => {chooseServiceRefs.current[index] = element}}
                />)}
        </div>
    )


});

export default (ChooseServiceSettings);