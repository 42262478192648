import React, { useEffect, useState } from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chooseCity, chooseRegion, chooseService, chooseServiceType } from '../../../rootSlice';
import { useNavigate, useParams } from 'react-router-dom'
import config from "../../../config/config.json";
import SelectStaffObject from './SelectStaffObject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson } from '@fortawesome/free-solid-svg-icons';
import { RestCustomService } from 'omni-services-utils';
import { SelectCustomSearch } from 'omni-services-base';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';

export const SelectStaff = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { firmNumber } = useParams();

    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';

    var firms = useSelector(state => state.firms)
    var selectedNumber = useSelector(state => state?.firms?.selectedNumber ?? firms?.list[0]?.number)

    var selectedFirmNumber = firmNumber;

    const state = useSelector(state => state)

    var [staffs, setStaffs] = useState([]);
    var [options, setOptions] = useState()
    var [value, setValue] = useState();
    var [staffId, setStaffId] = useState(props.staffId);
    var [theme, setTheme] = useState(props.theme);

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const submitClick = (type, service) => {
        dispatch(chooseServiceType(type));
        dispatch(chooseService(service));
        navigate("./../select-model");
    }

    const getFormattedDate = (dateTime) => {

        var optionsDate = { year: 'numeric', month: 'numeric', day: 'numeric', hour12: false, hour:'2-digit', minute:'2-digit' };
    
        if (dateTime == null) {
            return null;
        }
    
        return new Date(dateTime ?? null).toLocaleDateString("ru-RU", optionsDate);
      } 

    const getStaffsFromServer = () => {
        RestCustomService.Get(
            `/staff/owner/get/${selectedFirmNumber}/all`,
            null,
            true,
            (data) => { fillStaffs(data) },
            (err) => { console.error(err); })
    }

    const fillStaffs = (data) => {

        var opts = [];
        for (var i = 0; i < data.length; i++) {
            var opt = { key: data[i].id, label: `${data[i].userName} (${data[i].positionName}) `, value: data[i].id };
            opts.push(opt);
        }
        
        setOptions(opts);
    }

    useEffect(() => {
        getStaffsFromServer();
        setStaffId(props.staffId);
        setTheme(props.theme);
    }, [props.staffId, props.theme]);


    useImperativeHandle(ref, () => ({

        validation() {
    
            var err = null;
        
            return err;
        },
        
        getResult() {
            
            var staff = SelectStaffObject;

            staff.id = staffId;

            return staff;
        },

    }));

    return (
        <SelectCustomSearch
            options={options} 
            title={"Работник"} 
            name="choosedStaff"
            setSelectedValue={setStaffId}
            selected={staffId}
            leftText={<FontAwesomeIcon icon={faPerson}/>}
            theme={theme}
            />
    )


});

export default SelectStaff;