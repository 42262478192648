import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chooseCity, chooseRegion } from '../../rootSlice.js';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import config from "../../config/config.json";
import ChooseDoubleSmall from '../choose-double-small/ChooseDoubleSmall.jsx';
import {forwardRef, useImperativeHandle, useRef} from 'react';

export const SelectServiceSmall = forwardRef((props, ref) => {

    const chooseRef = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    var [serviceTypeCode, setRegionCode] = useState('')
    var [serviceCode, setCityCode] = useState('')
    const { register, handleSubmit } = useForm({ defaultValues: { serviceCode } })
    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';
    const state = useSelector(state => state)

    var [theme, setTheme] = useState(props.theme);
    var [editMode, setEditMode] = useState(props.editMode);

    var cities = [];
    var options = [];

    const submitClick = (selectedRegion, selectedCity) => {
        dispatch(chooseRegion(selectedRegion));
        dispatch(chooseCity(selectedCity));
        navigate("./../select-service");
    }

    const getServiceTypeFromServer = () => {
        const apiUrl = `${url}/api/serviceType/get/all`;
        return fetch(apiUrl)
            .then((response) => response.json())
            .then((responseData) => {
                return responseData;
            })
            .catch(error => console.warn(error));
    }

    const getServiceFromServer = (serviceTypeCode) => {
        const apiUrl = `${url}/api/service/get/type/${serviceTypeCode}`;
        return fetch(apiUrl)
            .then((response) => { return response.json() })
    }

    useEffect(() => {
        getServiceTypeFromServer();
        
        if (props.serviceTypeCode) {
            setRegionCode(props.serviceTypeCode);
        }
        if (props.serviceCode) {
            setCityCode(props.serviceCode);
            getServiceFromServer(props.serviceTypeCode);
        }
        setTheme(props.theme);
        setEditMode(props.editMode);
    }, [props.serviceTypeCode, props.serviceCode, props.theme, props.editMode]);

    useImperativeHandle(ref, () => ({

        validation() {
    
            var err = null;
    
        
            return err;
        },
        
        getResult() {
            
            var obj = chooseRef.current.getResult();



            return {serviceType: obj.value, service: obj.subValue};
        },

    }));

    return (
        <ChooseDoubleSmall
            options={options}
            name={"serviceTypeCode"}
            subName={"serviceCode"}
            value={serviceTypeCode}
            subValue={serviceCode}
            directoryName="Тип сервиса"
            subDirectoryName="Сервис"
            directoryDescription="Выберите тип сервиса" 
            subDirectoryDescription="Выберите сервис"
            previous="./serviceTypeCode"
            next="./select-service"
            chooseFunc={submitClick}
            selectDirectoryFunc={getServiceTypeFromServer}
            selectSubdirectoryFunc={getServiceFromServer} 
            theme={theme}
            ref={chooseRef}
            editMode={editMode}
            />
    )


});

export default SelectServiceSmall;