import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './CardParkingTicketSmall.module.css';
import style from '../../../../special/CommonStyles.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { RestCustomService } from 'omni-services-utils';
import { FormCustom } from 'omni-services-common';
import { LabelCustom } from 'omni-services-common';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';

export const CardParkingTicketSmall = (props) => {

  const navigate = useNavigate();

  const { firmNumber } = useParams()

  var [theme, setTheme] = useState();
  var [text, setText] = useState();
  var [editMode, setEditMode] = useState(false);
  var [error, setError] = useState();

  var [meta, setMeta] = useState();

  var [id, setId] = useState(props.parkingTicket.id);

  var [userName, setUserName] = useState(props.parkingTicket?.user?.name ?? "(имя не указано)");
  var [dateTime, setDateTime] = useState(props.parkingTicket?.slot?.begin);

  var [serviceName, setServiceName] = useState(props.parkingTicket?.service?.name);

  var [manufacturer, setManufacturer] = useState(props.parkingTicket?.manufacturer?.name ?? "(не указано)");
  var [manufacturerModel, setManufacturerModel] = useState(props.parkingTicket?.manufacturerModel?.name ?? "");

  var firms = useSelector(state => state.firms)
  var selectedNumber = useSelector(state => state.firms.selectedNumber ? state.firms.selectedNumber : null)
  var selectedFirmNumber = selectedNumber ?? firms?.list[0]?.number;

  /*var [staff, setStaff] = useState(props.staff);*/

  RestCustomService.setUrl(ConfigCustomService.getUrl());

  const state = useSelector(state => state)


  const handleSubmit = (event) => {

  }

  const setEditModeFunc = () => {
    //setEditMode(!editMode);

  }

  const goTo = () => {
    navigate(`/firm/${firmNumber}/edit-parking-ticket/${id}`);
  }

  const handlingRefresh = () => {

  }

  const getFormattedDate = (dateTime) => {

    var optionsDate = { year: 'numeric', month: 'numeric', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit' };

    if (dateTime == null) {
      return null;
    }

    return new Date(dateTime ?? null).toLocaleDateString("ru-RU", optionsDate);
  }

  const handlingDelete = () => {
    
    RestCustomService.Delete(
      `/parkingTicket/owner/delete/${selectedFirmNumber}/${id}`,
      null,
      true,
      (data) => {  },
      (err) => { console.error(err); })
  }

  const getStateTagById = (state) => {

    switch (state) {
      case 0:
        return {text: "Свободен", color: "green", class: s.green};
      case 1:
        return {text: "Клиент записался", color: "orange", class: s.orange}
      case 2:
        return {text: "Обслуживается", color: "orange", class: s.orange};
      case 3:
        return {text: "Отклонен", color: "red", class: s.red};
      case 4:
        return {text: "Готово", color: "blue", class: s.blue};
      case 5:
        return {text: "Перезаписан", color: "green", class: s.green};
      default:
        return {text: "Не определен", color: "black", class: s.black};
    }
  }

  useEffect(() => {

    var inMeta = {
      topLeft: <LabelCustom obj={getStateTagById(props.parkingTicket?.state)} />,
      topRight: null,
      bottomLeft: null,
      bottomRight: null
    }

    setMeta(inMeta);
    setTheme(props.theme);
    setText(props.text);
  },
    [props.theme, props.text, props.meta, props.parkingTicket])

  var options = {
    showSelectFirm: false,
    showMenuButtons: true
  };

  return (
    <div className={`${s.wrapper} ${getStateTagById(props.parkingTicket?.state).class}`} id={(state.settings.theme == "dark") ? style.dark : style.light}>
      <FormCustom
        title={''}
        onSubmit={handleSubmit}
        editMode={editMode}
        setEditMode={setEditModeFunc}
        refresh={handlingRefresh}
        delete={handlingDelete}
        body={
          <div className={s.descriptionWrapper} onClick={goTo}>
            <p>{`Клиент: ${userName}`}</p>
            <p>{`Услуга: ${props.parkingTicket?.serviceType?.name + ", " + props.parkingTicket?.service?.name ?? ''}`}</p>
            <p>{`Авто: ${props.parkingTicket?.manufacturer?.name} ${props.parkingTicket?.manufacturerModel?.name}`}</p>
            <p>{`Место: ${props.parkingTicket?.parkingPlaceNumber}`}</p>
            <p>{`С ${getFormattedDate(props.parkingTicket?.begin) ?? '(отсутствует)'}`}</p>
            <p>{`По ${getFormattedDate(props.parkingTicket?.end) ?? '(отсутствует)'}`}</p>
            <p>{`Сообщений: ${0}`}</p>
            {/*<InputTextCustom
                            theme={state.settings.theme}
                            controlId="manufacturerModel"
                            label="Авто"
                            required={true}
                            type="text"
                            name="manufacturerModel"
                            placeholder="(модель не указана)"
                            muted=""
                            message={manufacturerModel}
                            setMessage={setUserName}
                            readOnly={!editMode}
                            leftText={<FontAwesomeIcon icon={faCar} />}
                        />
                        <InputTextCustom
                            theme={state.settings.theme}
                            controlId="userName"
                            label="Имя"
                            required={true}
                            type="text"
                            name="userName"
                            placeholder="(имя не указано)"
                            muted=""
                            message={userName}
                            setMessage={setUserName}
                            readOnly={!editMode}
                            leftText={<FontAwesomeIcon icon={faPerson} />}
                      />*/}
          </div>
        }
        theme={state.settings.theme}
        error={error}
        options={options}
        meta={
           {
            topLeft: <LabelCustom obj={getStateTagById(props.parkingTicket?.state)} />,
            topRight: null,
            bottomLeft: null,
            bottomRight: null
          }
          
        }
      />
    </div>
  )
}

export default CardParkingTicketSmall;