export var parkingPlaceDefault =  {
	id: null,
	number: 0,
	state: 0,
	leftTopPoint: {
		x: 0,
		y: 0
	},
	length: 500,
	width: 250,
	angle: 0,
	parkingAreaId: null
}