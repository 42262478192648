import React, { useEffect, useState } from 'react';
import s from './ConfirmPhoneCustom.module.css';
import style from "../../special/CommonStyles.module.css";
import { useDispatch, useSelector } from 'react-redux';
import { needAuthShow, setDone, setIsAuthorized, setUser } from '../../rootSlice';
import { faEnvelope, faShield } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { RestCustomService } from 'omni-services-utils';
import { InputTextCustom } from 'omni-services-base';
import { FormCustom, UserProcessingService, ConfirmPhoneCustomCommon } from 'omni-services-common';
import { ConfigCustomService } from '../../services/base/config-custom-service/ConfigCustomService';

export const ConfirmPhoneCustom = (props) => {

    const dispatch = useDispatch();
    const registeredUser = useSelector(state => state.registeredUser);
    const navigate = useNavigate();

    const state = useSelector(state => state)
    const auth = useSelector(state => state.auth);
    
    const isOrderExists = useSelector(state => state.order.exists);
    

    const onSuccess = (user) => {

        dispatch(setUser(user));
        dispatch(needAuthShow(false));

        console.log(11);
        if (isOrderExists == true && !!auth.link) {
            console.log(12);
            navigate(auth.link);
            return;
        }

        console.log(13);

        //fillFirm(user);*/
        dispatch(setDone({
            title: "Вы успешно зарегистрированы!",
            description: `Пользователь: ${user?.name}, почта: ${user?.email}, телефон: ${user?.phone}`,
            link: "/",
            linkText: "Теперь можно сделать запись!"
        }));
        navigate("/done");
    }


    return (
        <div className={s.formWrapper} id={state.settings.theme == "dark" ? style.dark : style.light}>
            <ConfirmPhoneCustomCommon
                url={ConfigCustomService.getUrl()}
                theme={state.settings.theme}
                onSuccess={onSuccess}
                registeredUser={registeredUser}
            />
        </div>
    );
}

export default ConfirmPhoneCustom;