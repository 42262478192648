import React from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import Kanban from './example/kanban/Kanban';
import { v4 as uuidv4 } from 'uuid';
import { useEffect } from 'react';
import { useState } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';

const DraggableParkingTickets = forwardRef((props, ref) => {

  const kanbanRef = useRef();

  var [tickets, setTickets] = useState(props.tickets);
  var [columns, setColumns] = useState([]);
  var [statuses, setStatuses] = useState([]);
  var [theme, setTheme] = useState(props.theme);

  var { firmNumber } = useParams();

  RestCustomService.setUrl(ConfigCustomService.getUrl());

  var getDataInColumns = (data) => {
    
    return {
          [uuidv4()]: {
          id: 0,
          title: statuses?.filter(s => s.index == 0)[0]?.description ?? "Статус неизвестен",
          items: data?.filter(d => d.state == 0) ?? [],
          },
          [uuidv4()]: {
            id: 1,
            title: statuses?.filter(s => s.index == 1)[0]?.description ?? "Статус неизвестен",
            items: data?.filter(d => d.state == 1) ?? [],
          },
          [uuidv4()]: {
            id: 2,
            title: statuses?.filter(s => s.index == 2)[0]?.description ?? "Статус неизвестен",
            items: data?.filter(d => d.state == 2) ?? [],
          },
        };
  }

  const handlingServerUpdate = (col) => {

    var parkingTickets = [];
    var needUpdate = false;

    for (var i = 0; i < col.length; i++) {
      for (var j = 0; j < col[i].items.length; j++) {
        var parkingTicket = col[i].items[j];
        parkingTicket.state = col[i].id;
        parkingTickets.push(col[i].items[j]);
        needUpdate = true;
      }
    }
    
    RestCustomService.Patch(
      `/parkingticket/owner/update-kanban/${firmNumber}`,
      parkingTickets,
      true,
      () => {  },
      (err) => {  });
    
  }


  const handlingColumnsUpdate = (cols) => {
    
    var c = [];
    for (const key in cols) {
      if (cols.hasOwnProperty(key)) {
        c.push(cols[key]);
      }
    }
    handlingServerUpdate(c);
  }

  const fillStatuses = (st) => {
    
    setStatuses(st);
  }

  const handlingGetStatuses = () => {

    RestCustomService.Get(
      `/parkingticket/get-parking-ticket-statuses/`,
      null,
      true,
      (st) => { fillStatuses(st); /*setColumns(getDataInColumns(tickets, st));*/ },
      (err) => {  });
  }

  useEffect(() => {
    setTickets(props.tickets);
    setColumns(getDataInColumns(props.tickets));
    handlingGetStatuses();
    setTheme(props.theme);
  }, [props.tickets, props.theme]);

  useEffect(() => {
    setColumns(getDataInColumns(props.tickets));
  }, [statuses]);

  useImperativeHandle(ref, () => ({

    getResult() {
        return kanbanRef.current.getResult();
    },

  }));

    return (
      <Kanban 
        columnsFromBackend={columns} 
        ref={kanbanRef} onUpdate={(cols) => { handlingColumnsUpdate(cols); }} 
        componentName={"parkingTicket"} 
        theme={theme}
        />);
});

export default DraggableParkingTickets;
