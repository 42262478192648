import React, { useState } from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { useEffect } from 'react';
import s from './ParkingPlaceEdit.module.css'
import { setSelectedParkingPlace } from '../../../../rootSlice';
import { useDispatch } from 'react-redux';
import { parkingPlaceDefault } from '../manage-parking-area/Parking-Place-Default';
import { useParams } from 'react-router-dom';
import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';


export const ParkingPlaceEdit = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const params = useParams();

    var [text, setText] = useState();
    var [theme, setTheme] = useState();

    var [top, setTop] = useState(props.initialTop);
    var [left, setLeft] = useState(props.initialLeft);
    var [state, setState] = useState(props.state);
    var [id, setId] = useState(props.id);
    var [number, setNumber] = useState(props.number);
    var [angle, setAngle] = useState(props.angle);
    var [parkingAreaId, setParkingAreaId] = useState(props.parkingAreaId);

    var [selected, setSelected] = useState(false);

    var [mouseDown, setMouseDown] = useState(false);

    var [needServerUpdate, setNeedServerUpdate] = useState(false);
    var [firmNumber, setFirmNumber] = useState(props.firmNumber);
    var [selectedFirmNumber, setSelectedFirmNumber] = useState(props.selectedFirmNumber);

    var [editMode, setEditMode] = useState(props.editMode);


    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const styles = {
        position: 'absolute',
        top: top ?? 0,
        left: left ?? 0,
        border: selected ? "2px solid white" : "none"
    };

    const getColorByState = (state) => {
        switch (state) {
            case 0:
                return s.free;
            case 1:
                return s.reserved;
            case 2:
                return s.busy;
            default:
                return s.busy;
        }
    }

    const handleAdd = () => {
        
        if (id) {
            return;
        }

        return;
        var pp = parkingPlaceDefault;

        pp.id = null;
        pp.angle = 0;
        pp.parkingAreaId = parkingAreaId;
        pp.number = number;
        pp.state = state;

        RestCustomService.Put(
            `/parkingplace/owner/add/${selectedFirmNumber}`,
            pp,
            true,
            (data) => { 
                setId(data.id);
                setNumber(data.number);
                setParkingAreaId(data.parkingAreaId);
                setState(data.state);
                
             },
            (err) => { console.error(err); })
    }

    const handleEdit = () => {

        if (id == null) {
            return;
        }

        if (editMode == false) {
            return;
        }

        var pp = parkingPlaceDefault;

        pp.id = id;
        pp.angle = 0;
        pp.parkingAreaId = parkingAreaId;
        pp.number = + number;
        pp.state = state;
        pp.leftTopPoint.x = Math.round(left);
        pp.leftTopPoint.y = Math.round(top);
    }

    useEffect(() => {
        setText(props.text);
        setTheme(props.theme);
        if ((mouseDown == true)) {
            setTop(props.top - 20);
            setLeft(props.left - 20);
            setNeedServerUpdate(true);
        }
        setState(props.state);
        //setNumber(props.number);\
        if (!id) {
            setId(props.id)
        }
        
        /*if (props.top != top) {
            setTop(top);

        }*/
        setParkingAreaId(props.parkingAreaId);
        setFirmNumber(props.firmNumber);
        setSelectedFirmNumber(props.selectedFirmNumber);
        setEditMode(props.editMode);
    }, [
        props.text,
        props.theme,
        props.top,
        props.left,
        props.state,
        number,
        props.id,
        props.parkingAreaId,
        props.selectedFirmNumber,
        props.editMode
    ]);

    const handleMouseDown = (ev) => {
        setMouseDown(true);
    }

    const handleMouseUp = (ev) => {

        if (editMode == false) {
            return;
        }

        setMouseDown(false);
        handleEdit();
        if (needServerUpdate) {
            setNeedServerUpdate(false);
        }
        
    }

    const handleClick = (e) => {

        if (editMode == false) {
            return;
        }

        switch (e.detail) {
          case 1:
            break;
          case 2:
            setSelected(!selected);
            dispatch(setSelectedParkingPlace(id));
            break;
          case 3:

            break;
        }
      };

    const handleOnChange = e => {
        
    };

    const handleFocusOut = () => {

        if (editMode == false) {
            return;
        }

        handleEdit();
    }

    const numberValidation = () => {

        return null;
    }

    useImperativeHandle(ref, () => ({

        validation () {

            var err = null;
    
            err = numberValidation(number);
            if (err) {
                return "Поле имя: " + err;
            }
    
            return null;
        },
        
        getResult() {

            var pp = parkingPlaceDefault;

            pp.number = + number;
            pp.leftTopPoint.x = Math.round(left);
            pp.leftTopPoint.y = Math.round(top);
            pp.state = state;
            pp.parkingAreaId = parkingAreaId;
            pp.angle = + angle;
            pp.id = id;

            return pp;
        },

      }));


    return (
        <div
            className={`${s.wrapper} ${getColorByState(state)}`}
            style={styles}
            onMouseDown={(ev) => handleMouseDown(ev)}
            onMouseUp={(ev) => handleMouseUp(ev)}
            onClick={(ev) => handleClick(ev)}
            onTouchStart={(ev) => handleMouseDown(ev)}
            onTouchEnd={(ev) => handleMouseUp(ev)}
            onTouchMove={(ev) => handleClick(ev)}
            onChange={(ev) => handleOnChange(ev)}
            name="parkingPlace"
            >
            <input 
                type="number" 
                value={number}
                onChange={(e) => {setNumber(e.target.value);} }
                onBlur={handleFocusOut}
                className={s.parkingTextBox}
                name={"parking-place-edit-number"}
                disabled={!editMode}
                />
        </div>
    )
});

export default ParkingPlaceEdit;