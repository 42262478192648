import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import s from './PriceCard.module.css';

export const PriceCard = (props) => {
    const state = useSelector(state => state);
    var slot = props.slot;





    return (
        <div className={s.wrapper} id={state.settings.theme == "dark" ? s.dark : s.light}>
            <div className={s.header}>
                {props.header}
            </div>
            <div className={s.body}>
                {props.body}
            </div>
            <div className={s.footer}>
                {props.footer}
            </div>

        </div>
    )
}

export default (PriceCard);