import React, { useState } from 'react';
import styled from '@emotion/styled';
import { DragDropContext, Droppable } from "@hello-pangea/dnd";
import TaskCard from './task-card/TaskCard';
import s from './Kanban.module.css';
import { useEffect } from 'react';
import { columnsFromBackend } from './KanbanData';
import { forwardRef, useImperativeHandle, useRef } from 'react';

const Kanban = forwardRef((props, ref) => {

  var [componentName, setComponentName] = useState(props.componentName);
  var [columns, setColumns] = useState(props.columnsFromBackend);

  var [theme, setTheme] = useState(props.theme);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  useEffect(() => {
    setColumns(props.columnsFromBackend);
    setComponentName(props.componentName);
    setTheme(props.theme);
  }, 
  [props.columnsFromBackend, props.componentName, props.theme]);

  useEffect(() => {
    props.onUpdate(columns);
  }, 
  [columns]);


  useImperativeHandle(ref, () => ({

    getResult() {
        return columns;
    },

}));

  return (
    <DragDropContext
      onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
    >
      <div id={(theme == "dark") ? s.dark : s.light} className={s.wrapper}>
        <div className={s.taskColumnStyles}>
          {Object.entries(columns)?.map(([columnId, column], index) => {
            return (
              <Droppable key={columnId} droppableId={columnId}>
                {(provided, snapshot) => (
                  <div className={s.taskList}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className={s.title}>{column.title}</div>
                    {column.items.map((item, index) => (
                      <TaskCard key={item} item={item} index={index} componentName={componentName}/>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            );
          })}
        </div>
      </div>
    </DragDropContext>
  );
});

export default Kanban;
