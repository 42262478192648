export var SettingsObject = {
    appointmentStrategy: null,
    paymentStrategy: null,
    isEnable: null,
    staffMaxNumber: null,
    isClosed: null,
    enableEmailNotification: null,
    enableTelegramNotification: null,
    enableSmsNotificationUser: null,
    enableSmsNotificationWorker: null,
    enableSmsNotificationManager: null,
    enableSmsNotificationOwner: null,
    enableProductionCalendar: null,
}

export default SettingsObject;