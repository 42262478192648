import React, { useEffect } from 'react';
import ChooseSimple from '../choose-simple/ChooseSimple';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chooseCity, chooseRegion, chooseService, chooseServiceType } from '../../../rootSlice';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import config from "../../../config/config.json";
import ChooseDouble from '../choose-double/ChooseDouble';
import ChooseDoubleToFirm from '../choose-double-to-firm/ChooseDoubleToFirm';

export const SelectServiceToFirm = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const regionCode = useSelector(state => state.order.region.code)
    const cityCode = useSelector(state => state.order.city.code)
    const { register, handleSubmit } = useForm({ defaultValues: { cityCode } })
    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';

    const state = useSelector(state => state)

    var cities = [];
    var options = [];

    var chooseRef = useRef();

    const submitClick = (type, service) => {
        dispatch(chooseServiceType(type));
        dispatch(chooseService(service));

        /*if (type?.name == "Стоянка") {
            navigate("./../parking-area");
            return
        }*/
        navigate("./../select-model");
    }

    const getServiceTypesFromServer = () => {
        const apiUrl = `${url}/api/serviceType/get/all`;
        return fetch(apiUrl)
            .then((response) => response.json())
            .then((responseData) => {
                return responseData;
            })
            .catch(error => console.warn(error));
    }

    const getServicesFromServer = (serviceTypeCode) => {
        const apiUrl = `${url}/api/service/get/type/${serviceTypeCode}`;
        return fetch(apiUrl)
            .then((response) => { return response.json() })
    }

    useEffect(() => {
        getServiceTypesFromServer();
    });

    useImperativeHandle(ref, () => ({

        validation() {

            return null;
        },
        
        getResult() {
            
            return chooseRef.current.getResult();
        },

    }));

    return (
        <ChooseDoubleToFirm
            ref={chooseRef}
            title={"Услуга"}
            options={options}
            directoryName="Тип сервиса"
            subDirectoryName="Сервис"
            directoryDescription="Выберите тип сервиса"
            subDirectoryDescription="Выберите сервис"
            previous="/select-city"
            next="./service-type"
            chooseFunc={submitClick}
            selectDirectoryFunc={getServiceTypesFromServer}
            selectSubdirectoryFunc={getServicesFromServer} />
    )
});

export default SelectServiceToFirm;