import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import s from './TabAdmin.module.css';
import style from '../../../../../../special/CommonStyles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiamond, faIdBadge, faRouble } from '@fortawesome/free-solid-svg-icons';
import { CheckboxCustom, InputTextCustom } from 'omni-services-base';

export const TabAdmin = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const done = useSelector(state => state.done)

    const [editMode, setEditMode] = useState(props.editMode);
    const [theme, setTheme] = useState(props.theme);


    var [id, setId] = useState(props.firm?.id);
    var [ownerId, setOwnerId] = useState(props.firm?.ownerId);
    var [number, setNumber] = useState(props.firm?.number);
    var [rate, setRate] = useState(props.firm?.rate);
    var [isEnabled, setIsEnabled] = useState(props.firm?.isEnabled);
    var [isBlocked, setIsBlocked] = useState(props.firm?.isBlocked);
    var [state, setState] = useState(props.firm?.state);
    var [registerDate, setRegisterDate] = useState(props.firm?.registerDate);
    var [accountBalance, setAccountBalance] = useState(props.firm?.payment?.accountBalance);
    var [lastPayment, setLastPayment] = useState(props.firm?.payment?.lastPayment);

    var [balance, setBalance] = useState('');


    useEffect(() => {

        setEditMode(props.editMode);
        setTheme(props.theme);

        setId(props.firm?.id);
        setOwnerId(props.firm?.ownerId);
        setNumber(props.firm?.number);
        setRate(props.firm?.rate);
        setIsEnabled(props.firm?.isEnabled);
        setState(props.firm?.state);
        setIsBlocked(props.firm?.isBlocked);
        setRegisterDate(props.firm?.registerDate);
        setAccountBalance(props.firm?.payment?.accountBalance);
        setLastPayment(props.firm?.payment?.lastPayment);

    }, [
        props.firm,
        props.editMode,
        props.theme,
    ]);

    const idValidation = (text) => {

        if (!text) {
            return "Поле обязательно";
        }

        if (text.length == 0) {
            return "Поле обязательно";
        }

        if (/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(text) == false) {
            return "формат не GUID!";
        }

        return null;
    }

    const numberValidation = (text) => {

        if (!text) {
            return "Поле обязательно";
        }

        if (Number.isInteger(text) == false) {
            return "не число";
        }

        return null;
    }

    useImperativeHandle(ref, () => ({

        validation() {

            var err = null;

            err = idValidation(id);
            if (err) {
                return "Поле id: " + err;
            }

            err = idValidation(ownerId);
            if (err) {
                return "Поле ownerId: " + err;
            }

            err = numberValidation(number);
            if (err) {
                return "Поле number: " + err;
            }

            return null;
        },

        getResult() {

            var result = {
                id: id,
                ownerId: ownerId,
                number: +number,
                rate: +rate,
                isEnabled: isEnabled,
                isBlocked: isBlocked,
                state: +state,
                registerDate: registerDate,
                payment: {
                    accountBalance: accountBalance,
                    lastPayment: lastPayment,
                },
            };

            return result;
        },

    }));

    return (
        <div>
            <InputTextCustom
                controlId="id"
                label="id"
                required={false}
                type="text"
                name="id"
                placeholder="iD"
                muted=""
                message={id}
                readOnly={!editMode}
                setMessage={setId}
                leftText={<FontAwesomeIcon icon={faDiamond} />}
                theme={theme}
            />
            <InputTextCustom
                controlId="ownerId"
                label="ownerId"
                required={false}
                type="text"
                name="ownerId"
                placeholder="ownerId"
                muted=""
                message={ownerId}
                readOnly={!editMode}
                setMessage={setOwnerId}
                leftText={<FontAwesomeIcon icon={faDiamond} />}
                theme={theme}
            />
            <InputTextCustom
                controlId="number"
                label="number"
                required={false}
                type="text"
                name="number"
                placeholder="number"
                muted=""
                message={number}
                readOnly={!editMode}
                setMessage={setNumber}
                leftText={<FontAwesomeIcon icon={faDiamond} />}
                theme={theme}
            />
            <InputTextCustom
                controlId="rate"
                label="rate"
                required={false}
                type="text"
                name="rate"
                placeholder="rate"
                muted=""
                message={rate}
                readOnly={!editMode}
                setMessage={setRate}
                leftText={<FontAwesomeIcon icon={faDiamond} />}
                theme={theme}
            />
            <CheckboxCustom
                label={"isEnabled"}
                checked={isEnabled}
                onChange={() => setIsEnabled(!isEnabled)}
                id="isEnabled"
                name="isEnabled"
                readOnly={!editMode}
            />
            <CheckboxCustom
                label={"isBlocked"}
                checked={isBlocked}
                onChange={() => setIsBlocked(!isBlocked)}
                id="isBlocked"
                name="isBlocked"
                readOnly={!editMode}
            />
            <InputTextCustom
                controlId="state"
                label="state"
                required={false}
                type="text"
                name="state"
                placeholder="state"
                muted=""
                message={state}
                readOnly={!editMode}
                setMessage={setState}
                leftText={<FontAwesomeIcon icon={faDiamond} />}
                theme={theme}
            />
            <InputTextCustom
                theme={theme}
                controlId="registerDate"
                label="registerDate"
                required={false}
                type="datetime-local"
                name="registerDate"
                placeholder="registerDate"
                muted=""
                message={registerDate?.substring(0, 16)}
                readOnly={!editMode}
                setMessage={setRegisterDate}
                leftText={<FontAwesomeIcon icon={faDiamond}
                />}
            />
            <InputTextCustom
                controlId="accountBalance"
                label="accountBalance"
                required={false}
                type="text"
                name="accountBalance"
                placeholder="accountBalance"
                muted=""
                message={accountBalance}
                readOnly={!editMode}
                setMessage={setAccountBalance}
                leftText={<FontAwesomeIcon icon={faDiamond} />}
                theme={theme}
            />
            <InputTextCustom
                theme={theme}
                controlId="lastPaymente"
                label="lastPayment"
                required={false}
                type="datetime-local"
                name="lastPayment"
                placeholder="lastPayment"
                muted=""
                message={lastPayment?.substring(0, 16)}
                readOnly={!editMode}
                setMessage={setLastPayment}
                leftText={<FontAwesomeIcon icon={faDiamond}
                />}
            />
        </div>
    );
});

export default TabAdmin;