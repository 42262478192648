import React, { useState, useEffect, useRef } from 'react';
import s from './FirmMapBackCustom.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { AddressFiasCustom, FormCustom } from 'omni-services-common';
import { ImageCustom, InputTextCustom } from 'omni-services-base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faBusinessTime, faLocation, faLocationArrow, faLocationDot, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';

export const FirmMapBackCustom = (props) => {

    const [theme, setTheme] = useState(props.theme);

    const [success, setSuccess] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        setTheme(props.theme);
    }, [
        props.theme
    ]);


    var options = {
        showFooterButtons: false,
        showMenuButtons: false,
        readOnly: true,
        submitText: "Выбрать фирму",
        showSelectFirm: true,
        needToLoggedIn: false,
        enableBackButton: true,
    };


    return (
        <div className={s.formWrapper} id={theme === "dark" ? s.dark : s.light}>
            <FormCustom
                title={""}
                onSubmit={null}
                editMode={false}
                setEditMode={() => { }}
                refresh={() => { }}
                options={options}
                success={success}
                error={error}
                body={
                    null
                }
                theme={theme}
            />
        </div>
        )

}

export default (FirmMapBackCustom);