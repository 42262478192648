import {createSlice} from '@reduxjs/toolkit'

const rootSlice = createSlice({
    name: "root",
    initialState: {
        order: {
            region: {
                code: 0,
                name: "",
            },
            city: {
                code: 0,
                name: ""
            },
            serviceType: {
                code: 0,
                name: ""
            },
            service: {
                code: 0,
                name: ""
            },
            manufacturer: {
                code: 0,
                name: ""
            },
            manufacturerModel: {
                code: 0,
                name: ""
            },
            firm: {
                number: 0,
                name: "",
                schedule: null,
                staffId: null,
                address: null
            },
            slot: {
                id: null
            },
            slotDate: {
                date: null
            },
            parkingArea: {
                id: null
            },
            parkingDateInterval: {
                begin: null,
                end: null
            },
            parkingPlace: {
                id: null
            },
            exists: false
        },
        settings: {
            theme: "light",
            showMenu: false
        }, 
        error: {
            code: null,
            message: null
        },
        auth: {
            isByPhone: true,
            needToShow: false,
            needToShowFast: false,
            accessToken: null,
            link: null,
        },
        user: {
            id: '',
            name: '',
            role: []
        },
        done: {
            title: "Ok!",
            description: "Действие выполнено!",
            link: "/",
            linkText: "Перейти к выбору"
        },
        forms: {
            user: {
                editMode: false
            }
        },
        firms: {
            list: [],
            selectedNumber: null,
            selectedFirm: null
        },
        parkingPlace: {
            number: null
        },
        registeredUser: {
            name: '',
            email: '',
            phone: ''
        }
    },
    reducers: {
        chooseRegion: (state, action) => {state.order.region.code = action.payload.code; state.order.region.name = action.payload.name},
        chooseCity: (state, action) => {state.order.city.code = action.payload.code; state.order.city.name = action.payload.name},
        chooseServiceType: (state, action) => {state.order.serviceType.code = action.payload.code; state.order.serviceType.name = action.payload.name},
        chooseService: (state, action) => {state.order.service.code = action.payload.code; state.order.service.name = action.payload.name},
        chooseManufacturer: (state, action) => {state.order.manufacturer.code = action.payload.code; state.order.manufacturer.name = action.payload.name},
        chooseManufacturerModel: (state, action) => {state.order.manufacturerModel.code = action.payload.code; state.order.manufacturerModel.name = action.payload.name},
        chooseFirm: (state, action) => {
            state.order.firm.number = action.payload.number; 
            state.order.firm.name = action.payload.name; 
            state.order.firm.schedule = action.payload.schedule;
            state.order.firm.staffId = action.payload.staffId;
            state.order.firm.address = action.payload.address;
        },
        chooseSlot: (state, action) => {state.order.slot.id = action.payload.id; state.order.slot.begin = action.payload.begin; state.order.slot.end = action.payload.end; state.order.slot.duration = action.payload.duration},
        chooseSlotDate: (state, action) => {state.order.slotDate.date = action.payload;},
        chooseSlotTime: (state, action) => {state.order.slot.id = action.payload.id; state.order.slot.begin = action.payload.begin; state.order.slot.end = action.payload.end; state.order.slot.duration = action.payload.duration},
        chooseParkingArea: (state, action) => {state.order.parkingArea.id = action.payload;},
        chooseParkingInterval: (state, action) => {state.order.parkingDateInterval.begin = action.payload.begin; state.order.parkingDateInterval.end = action.payload.end;},
        chooseParkingPlace: (state, action) => {state.order.parkingPlace.id = action.payload.id; state.order.parkingPlace.number = action.payload.number;},

        chooseTheme: (state, action) => {state.settings.theme = action.payload},
        setShowMenu: (state, action) => {state.settings.showMenu = action.payload},
        setShowRight: (state, action) => {state.settings.showMenu = !state.settings.showMenu },

        setErrorCustom: (state, action) => {state.error.message = action.payload},
        setAuthMode: (state, action) => {state.auth.isByPhone = action.payload},
        needAuthShow: (state, action) => {state.auth.needToShow = action.payload},
        needAuthFastShow: (state, action) => {state.auth.needToShowFast = action.payload},
        setAccessToken: (state, action) => {state.auth.accessToken = action.payload},
        setAfterAuthLink: (state, action) => {state.auth.link = action.payload},
        setUser: (state, action) => {state.user.id = action.payload.id; state.user.name = action.payload.name; state.user.role = action.payload.role},

        setDone: (state, action) => {state.done = action.payload},

        setFormUserEditMode: (state, action) => {state.forms.user.editMode = action.payload; },

        setOwnersFirms: (state, action) => { state.firms.list = action.payload; },
        setSelectedOwnerFirm: (state, action) => { state.firms.selectedNumber = action.payload; },
        setSelectedParkingPlace: (state, action) => { state.parkingPlace.number = action.payload; },
        setRegisteredUser: (state, action) => { state.registeredUser.name = action.payload.name; state.registeredUser.email = action.payload.email; state.registeredUser.phone = action.payload.phone;},
        isOrderExists: (state, action) => {state.order.exists = action.payload},
    }
})

export const reducer = rootSlice.reducer;

export const {
    chooseRegion, 
    chooseCity, 
    chooseServiceType, 
    chooseService, 
    chooseManufacturer, 
    chooseManufacturerModel, 
    chooseTheme, 
    setShowMenu,
    setShowRight,
    chooseFirm, 
    chooseSlot, 
    chooseSlotDate,
    chooseSlotTime,
    setErrorCustom,
    setAuthMode,
    needAuthShow,
    needAuthFastShow,
    setAccessToken,
    setAfterAuthLink,
    setUser,
    setDone,
    setFormUserEditMode,
    setOwnersFirms,
    setSelectedOwnerFirm,
    chooseParkingArea,
    chooseParkingInterval,
    chooseParkingPlace,
    setSelectedParkingPlace,
    setRegisteredUser,
    isOrderExists,
} = rootSlice.actions;