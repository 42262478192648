export var staffDefault = {
    id: null,
    userId: null,
    firmNumber: 0,
    positionName: "",
    userName: "",
    userStaffRole: null,
    directions: {
        serviceTypesCode: [],
        servicesCode: [],
    },
    state: 3,
    schedule: {
        type: 1,
        weekSchedule: [],
        complexSchedule: {
            beginningDate: "2077-08-14T06:00:00Z",
            daysSchedules: [
                {
                    dayOfWeek: 0,
                    beginningTime: "2077-08-14T04:00:00Z",
                    endingTime: "2077-08-14T10:00:00Z",
                    brakeTimeBegin: null,
                    brakeEnabled: null,
                    brakeTimeEnd: null,
                    brakeDuration: 0,
                    isWorking: true
                }
            ],
            vacations: null
        }
    },
    photo: ""
}

export default staffDefault;