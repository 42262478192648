import React, { useEffect } from 'react';
import ChooseSimple from '../choose-simple/ChooseSimple';
import { useDispatch, useSelector } from 'react-redux';
import { chooseCity, chooseManufacturer, chooseManufacturerModel, chooseRegion } from '../../../rootSlice';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import config from "../../../config/config.json";
import ChooseDouble from '../choose-double/ChooseDouble';
import { getNextFromInputPath } from '../chooseProcessing/ChooseProcessing';

export const SelectModel = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const regionCode = useSelector(state => state.order.region.code)
    const cityCode = useSelector(state => state.order.city.code)
    const { register, handleSubmit } = useForm({ defaultValues: { cityCode } })
    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';;

    const state = useSelector(state => state)

    var cities = [];
    var options = [];

    const submitClick = (manufacturer, model) => {
        dispatch(chooseManufacturer(manufacturer));
        dispatch(chooseManufacturerModel(model));
        navigate(getNextFromInputPath());
    }

    const getManufacturerFromServer = () => {
        const apiUrl = `${url}/api/manufacturer/get/all`;
        return fetch(apiUrl)
            .then((response) => response.json())
            .then((responseData) => {
                return responseData;
            })
            .catch(error => console.warn(error));
    }

    const getModelFromServer = (manufacturerCode) => {
        const apiUrl = `${url}/api/manufacturerModel/get/manufacturer/${manufacturerCode}`;
        return fetch(apiUrl)
            .then((response) => { return response.json() })
    }

    useEffect(() => {
        getManufacturerFromServer();
    });

    return (
        <ChooseDouble
            title={"Транспортное средство"}
            options={options}
            directoryName="Марка"
            subDirectoryName="Модель"
            directoryDescription="Выберите марку"
            subDirectoryDescription="Выберите модель"
            previous="/select-service"
            next="./service-type"
            chooseFunc={submitClick}
            selectDirectoryFunc={getManufacturerFromServer}
            selectSubdirectoryFunc={getModelFromServer} />
    )


}

export default SelectModel;