import React, { useEffect, useState } from 'react';
import s from './NotificationNumberCustom.module.css';
import { useDispatch, useSelector } from 'react-redux';

export const NotificationNumberCustom = (props) => {

    const dispatch = useDispatch();
    const state = useSelector(state => state)


    const [count, setCount] = useState(props.count);

    useEffect(() => {

    }, []);

    useEffect(() => {
        setCount(props.count);
    }, [props.count]);


    return (
        <div className={s.wrapper}>
            {count}
        </div>
    );
}

export default NotificationNumberCustom;