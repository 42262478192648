import React, { useState, useEffect, useRef } from 'react';
import s from './FirmMapDescription.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { AddressFiasCustom, FormCustom } from 'omni-services-common';
import { ImageCustom, InputTextCustom } from 'omni-services-base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faBusinessTime, faLocation, faLocationArrow, faLocationDot, faMapLocationDot } from '@fortawesome/free-solid-svg-icons';

export const FirmMapDescription = (props) => {

    const [firm, setFirm] = useState(props.firm);
    const [theme, setTheme] = useState(props.theme);
    const [url, setUrl] = useState(props.url);

    const [success, setSuccess] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        setTheme(props.theme);
    }, [
        props.theme
    ]);

    useEffect(() => {
        setFirm(props.firm);
    }, [props.firm]);

    const onSubmit = () => {

        var err = validation();

        if (err) {
            setError(err);
            return;
        }

        if (props.onSubmit != undefined) {
            props.onSubmit(firm);
        }
    }

    var options = {
        showFooterButtons: true,
        showMenuButtons: false,
        readOnly: true,
        submitText: "Выбрать фирму",
        showSelectFirm: true,
        needToLoggedIn: false,
        enableBackButton: true,
    };

    const validation = () => {

        if (!firm) {
            return "Фирма не выбрана!"
        }

        return null;
    }


    return (
        <div className={s.formWrapper} id={theme === "dark" ? s.dark : s.light}>
            <FormCustom
                title={""}
                onSubmit={onSubmit}
                editMode={false}
                setEditMode={() => { }}
                refresh={() => { }}
                options={options}
                success={success}
                error={error}
                body={
                    !!firm ?
                    <div>
                        <div className={s.widthWrapper}>
                            <div className={s.imageWrapper}>
                                <ImageCustom src={`${url}${firm?.logo}`} />
                            </div>
                        </div>
                        <InputTextCustom
                            theme={theme}
                            controlId="name"
                            label="Название фирмы"
                            required={false}
                            type="text"
                            name="name"
                            placeholder="Название фирмы..."
                            muted=""
                            message={firm?.name}
                            readOnly={true}
                            setMessage={() => { }}
                            leftText={<FontAwesomeIcon icon={faBusinessTime}
                            />}
                        />
                        <div className={s.widthWrapper}>
                            <InputTextCustom
                                theme={theme}
                                controlId="name"
                                label="Улица"
                                required={false}
                                type="text"
                                name="name"
                                placeholder="улица..."
                                muted=""
                                message={firm?.address?.streetName}
                                readOnly={true}
                                setMessage={() => { }}
                                leftText={<FontAwesomeIcon icon={faLocationDot}
                                />}
                            />

                            <InputTextCustom
                                theme={theme}
                                controlId="name"
                                label="Здание"
                                required={false}
                                type="text"
                                name="name"
                                placeholder="здание..."
                                muted=""
                                message={firm?.address?.buildingName}
                                readOnly={true}
                                setMessage={() => { }}
                                leftText={<FontAwesomeIcon icon={faBuilding}
                                />}
                            />
                        </div>

                    </div>
                    : null
                }
                theme={theme}
            />
        </div>)


}

export default (FirmMapDescription);