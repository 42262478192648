import { useSelector } from 'react-redux';
import s from './AuthForm.module.css';
import { ConfigCustomService } from '../../services/base/config-custom-service/ConfigCustomService';
import AuthFormCustom from '../auth-form/AuthFormCustom';
import { AuthFormCustomCommon } from 'omni-services-common';
import { useDispatch } from 'react-redux';
import { needAuthShow, setUser } from '../../rootSlice';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';


const AuthForm = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const state = useSelector(state => state);
    const auth = useSelector(state => state.auth);
    
    const isOrderExists = useSelector(state => state.order.exists);

    const onSuccess = (user) => {
        dispatch(setUser(user));
        console.log("on success auth");

        if (isOrderExists == true && !!auth.link) {
            navigate(auth.link);
        }

    }

    const onFirmsLoad = () => {

    }

    const onError = () => {

    }

    const onClose = () => {
        
    }

    const clickHandler = (e) => {
        e.stopPropagation();
    }

    return (
        <div className={s.authFormContainer}>
            <div className={s.authForm} onClick={clickHandler}>
                <AuthFormCustomCommon
                    theme={state.settings.theme}
                    url={ConfigCustomService.getUrl()}
                    onSuccess={onSuccess}
                    onFirmsLoad={onFirmsLoad}
                    onError={onError}
                    onClose={onClose}
                    afterRegister={"ticket"}
                />
            </div>
        </div>
    );
}

export default (AuthForm);