import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './CardStaff.module.css';
import style from '../../../../special/CommonStyles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHammer, faPerson } from '@fortawesome/free-solid-svg-icons';
import Image from 'react-bootstrap/Image'
import { useNavigate, useParams } from 'react-router-dom';
import { FormCustom } from 'omni-services-common';
import { InputTextCustom } from 'omni-services-base';
import { ImageCustom } from 'omni-services-base';

export const CardStaff = (props) => {

  const navigate = useNavigate();
  const { firmNumber } = useParams();

  var [theme, setTheme] = useState();
  var [text, setText] = useState();
  var [editMode, setEditMode] = useState(false);
  var [error, setError] = useState();

  var [userName, setUserName] = useState(props.staff.userName);
  var [positionName, setPositionName] = useState(props.staff.positionName);
  var [id, setId] = useState(props.staff.id);
  var [staff, setStaff] = useState(props.staff);

  const state = useSelector(state => state)
  

  const handleSubmit = (event) => {

  }

  const setEditModeFunc = () => {
    navigate(`/firm/${firmNumber}/edit-staff/${id}`);
  }

    const handlingRefresh = () => {
      
    }

  useEffect(() => {
    setTheme(props.theme);
    setText(props.text);
  }, 
  [props.theme, props.text])

  var options = {
    showSelectFirm: false
};

  return (
    <div className={s.wrapper} id={(state.settings.theme == "dark") ? style.dark : style.light}>
        <FormCustom
                title={"Сотрудник"}
                onSubmit={handleSubmit}
                editMode={editMode}
                setEditMode={setEditModeFunc}
                refresh={handlingRefresh}
                body={
                    <div className={s.descriptionWrapper}>
                    <div className={s.imageWrapper}>
                      <ImageCustom src={staff?.photo} />
                    </div>
                    <InputTextCustom
                            theme={state.settings.theme}
                            controlId="userName"
                            label="Имя"
                            required={true}
                            type="text"
                            name="userName"
                            placeholder="Отображаемое имя..."
                            muted=""
                            message={userName}
                            setMessage={setUserName}
                            readOnly={!editMode}
                            leftText={<FontAwesomeIcon icon={faPerson} />}
                        />
                    <InputTextCustom
                            theme={state.settings.theme}
                            controlId="positionName"
                            label="Должность"
                            required={true}
                            type="text"
                            name="positionName"
                            placeholder="Название должности..."
                            muted=""
                            message={positionName}
                            setMessage={setPositionName}
                            readOnly={!editMode}
                            leftText={<FontAwesomeIcon icon={faHammer} />}
                        />
                </div>
                }
                theme={state.settings.theme}
                error={error}
                options={options}
            />
    </div>
  )
}

export default CardStaff;