import React, { useEffect } from 'react';
import { Draggable } from "@hello-pangea/dnd";
import styled from '@emotion/styled';
import s from './TaskCard.module.css';
import CardParkingTicketSmall from '../../../../card-parking-ticket-small/CardParkingTicketSmall';
import CardTicketSmall from '../../../../../ticket/card-ticket-small/CardTicketSmall';

const TaskCard = (props) => {


  useEffect(() => {

  },  []);

  return (
    <Draggable key={props.item.id.toString()} draggableId={props.item.id.toString()} index={props.index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <div>
              {
                props.componentName == "parkingTicket" ? <CardParkingTicketSmall parkingTicket={props.item}/> : null
              }
              {
                props.componentName == "ticket" ? <CardTicketSmall ticket={props.item}/> : null
              }
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;
