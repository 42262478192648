import React, { useState, useEffect } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import s from './InfoToFirm.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import style from '../../../../special/CommonStyles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faCalendar, faCar, faClock, faIndustry, faUser, faWrench } from '@fortawesome/free-solid-svg-icons';
import { RestCustomService } from 'omni-services-utils';
import { InputTextCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';
import { AddressFiasCustom } from 'omni-services-common';

export const InfoToFirm = forwardRef((props, ref) => {

    var addressRef = useRef();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const themeState = useSelector(state => state.settings.theme);

    var [theme, setTheme] = useState(themeState);
    var [error, setError] = useState();
    var [firmNumber, setFirmNumber] = useState(props.firmNumber);

    var [firm, setFirm] = useState();

    const state = useSelector(state => state)

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const options = {
        showFooterButtons: true,
        showMenuButtons: false,
        readOnly: true,
        submitText: "Выбрать слот",
        cancelText: "Назад",
        enableBackButton: true
    };

    const loadFirmFromServer = (firmNumber) => {

        if (!firmNumber) {
            return;
        }

        RestCustomService.Get(
            `/firm/get/number/${firmNumber}`,
            null,
            true,
            (data) => {
                setFirm(data);
            },
            (err) => { setError(err) }
        );
    }

    useEffect(() => {
        setFirmNumber(props.firmNumber);
        loadFirmFromServer(props.firmNumber);
    }, [props.firmNumber])

    useImperativeHandle(ref, () => ({

        validation() {

            return null;
        },

        getResult() {

            return firm;
        },

    }));

    return (
        <div id={state.settings.theme == "dark" ? style.dark : style.light}>
            <AddressFiasCustom
                showMap={true}
                showLiveSearch={false}
                showAddress={false}
                showUserLocation={false}
                address={firm?.address}
                theme={state.settings.theme}
                ref={addressRef}
                editMode={true}
                url={ConfigCustomService.getUrl()}
            />
            <InputTextCustom
                theme={state.settings.theme}
                controlId="name"
                label="Вы записываетесь в фирму"
                required={false}
                type="text"
                name="name"
                placeholder="Название фирмы..."
                muted=""
                message={firm?.name}
                readOnly={true}
                setMessage={() => { }}
                leftText={<FontAwesomeIcon icon={faBusinessTime}
                />}
            />
        </div>
    )
});

export default (InfoToFirm);