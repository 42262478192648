import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import style from './../../../../special/CommonStyles.module.css'
import s from './ManageParkingArea.module.css'
import ManageParkingPlace from '../manage-parking-place/ParkingPlaceEdit';
import { useDispatch, useSelector } from 'react-redux';
import { chooseParkingPlace } from '../../../../rootSlice';
import { useNavigate, useParams } from 'react-router-dom';
import AddParkingArea from '../add-parking-area/AddParkingArea';
import EditParkingArea from '../edit-parking/EditParkingArea';
import { parkingPlaceDefault } from './Parking-Place-Default';
import { RestCustomService } from 'omni-services-utils';
import { FormCustom, InfoBlockCustom } from 'omni-services-common';
import { CheckboxCustom } from 'omni-services-base';
import config from "./../../../../config/config.json";
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';


export const ManageParkingArea = (props) => {

    const state = useSelector(state => state)
    const editParkingAreaRef = useRef();

    const { firmNumber } = useParams();

    const firm = useSelector(state => state.order.firm);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';

    var [text, setText] = useState();
    var [theme, setTheme] = useState(state.settings.theme);
    var [error, setError] = useState();
    var [success, setSuccess] = useState();

    var [places, setPlaces] = useState([]);

    var firms = useSelector(state => state.firms)
    var selectedNumber = useSelector(state => state?.firms?.selectedNumber ?? firms?.list[0]?.number)

    var selectedFirmNumber = firmNumber;

    var [editMode, setEditMode] = useState(false);

    var [parkingArea, setParkingArea] = useState();

    var [activeParkingArea, setActiveParkingArea] = useState(false);


    const options = {
        readonly: false,
        canRefresh: true,
        canSave: true,
        canDelete: true,
        showMenuButtons: true,
        showFooterButtons: false,
        showSelectFirm: true,
        editMode: true,
        needToLoggedIn: true,
        enableBackButton: true
    };

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const handleSubmit = (event) => {

        var parkingPlaces = [];

        var parkingPlacesArr = editParkingAreaRef.current.getParkingPlaces();

        RestCustomService.Patch(
            `/parkingplace/owner/save-many/${selectedFirmNumber}`,
            parkingPlacesArr,
            true,
            (data) => { setPlaces(data); setSuccess("Сохранение прошло успешно!");},
            (err) => { console.error(err); setError(err); }
        );

        return;
    }

    const setEditModeFunc = () => {
        setEditMode(!editMode);
    }


    const getAreasFromServer = () => {

        if (!selectedFirmNumber) {
            return;
        }
        
        RestCustomService.Get(
            `/ParkingArea/guest/get/${selectedFirmNumber}`,
            null,
            true,
            (data) => { setParkingArea(data[0]); },
            (err) => {/* console.error(err);*/ setError(err); }
        );
    }

    const onChoose = (place) => {
        dispatch(chooseParkingPlace({ id: place.id, number: place.number }));

        navigate("./../parking-ticket");
    }

    const getParkingAreaFromServer = () => {

        RestCustomService.Get(
            `/parkingarea/owner/get/${selectedFirmNumber}`,
            null,
            true,
            (data) => {
                if (data[0]) {
                    setParkingArea(data[0]);
                    setActiveParkingArea(true);
                } else {
                   setActiveParkingArea(false);
                }
            },
            (err) => { console.error(err); })
    }

    useEffect(() => {
        RestCustomService.setUrl(url);
    }, [])

    useEffect(() => {

        if (!selectedFirmNumber) {
            return;
        }

        if (!parkingArea) {
            getParkingAreaFromServer();
        }
        setText(props.text);
        setTheme(props.theme);

        
        getAreasFromServer();
    }, [
        props.text,
        props.theme,
        selectedFirmNumber
    ]);

    const onActiveParkingArea = () => {

        var flag = !activeParkingArea;
        
        var parkingAreaDto = {
            firmNumber: +selectedFirmNumber,
            circuit: null
        }

        if (flag) {
            
            RestCustomService.Put(
                `/parkingarea/owner/add/${selectedFirmNumber}`,
                parkingAreaDto,
                true,
                (data) => { setParkingArea(data); setActiveParkingArea(!activeParkingArea);},
                (err) => { console.error(err); setError(err); setActiveParkingArea(activeParkingArea); })
        } else {
            RestCustomService.Delete(
                `/parkingarea/owner/delete/${parkingArea?.id}`,
                null,
                true,
                (data) => { setParkingArea(data[0]); setActiveParkingArea(!activeParkingArea);},
                (err) => { console.error(err); setError(err); setActiveParkingArea(activeParkingArea);})
        }
    }


    return (
        <div id={(state.settings.theme == "dark") ? s.dark : s.light}>
            <h3>Редактирование</h3>
            <div className={style.formWrapper} id={(state.settings.theme == "dark") ? style.dark : style.light}>
                <FormCustom
                    title={"Парковка"}
                    onSubmit={handleSubmit}
                    editMode={editMode}
                    setEditMode={setEditModeFunc}
                    body={
                        <div>
                            <InfoBlockCustom info={`
                                Здесь находится графический редактор парковочных мест. 
                                Добавьте необходимое количество парковочных мест, расположите их на поле и сохраните результат.
                            `}/>
                            <CheckboxCustom
                                label={"Активировать стоянку"}
                                checked={activeParkingArea}
                                onChange={() => onActiveParkingArea()}
                                name="isEnable"
                                id="isEnable"
                                readOnly={editMode == false} />
                            <div className={s.parkingAreaWrapper}>
                                <EditParkingArea 
                                    parkingAreaId={parkingArea?.id}
                                    selectedFirmNumber={selectedNumber}
                                    places={places}
                                    ref={editParkingAreaRef}
                                    getPlacesFromServer={getParkingAreaFromServer}
                                    editMode={editMode}
                                    handleSubmit={handleSubmit}
                                />
                                <div className={editMode == false ? s.shield : null}></div>
                            </div>
                        </div>}
                    theme={state.settings.theme}
                    error={error}
                    success={success}
                    options={options}
                />
            </div>
        </div>
    )
}

export default ManageParkingArea;