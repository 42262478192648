import React from 'react';
import s from './UserMenu.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import UserMenuItem from './user-menu-item/UserMenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBook, faBusinessTime, faClose, faDollar, faMoneyBill, faMoon, faQ, faQuestion, faQuestionCircle, faSignOut, faTicket, faUser } from '@fortawesome/free-solid-svg-icons';
import { setIsAuthorized, setShowMenu } from '../../rootSlice';
import { UserProcessingService } from 'omni-services-common';

export const UserMenu = (props) => {

    const [ searchParams, setSearchParams ] = useSearchParams();

    console.log(searchParams.get("to"));

    const dispatch = useDispatch();
    const base = useSelector(state => state.base)
    const navigate = useNavigate();

    const state = useSelector(state => state)
    const user = useSelector(state => state.user)

    const handlingClose = () => {
        dispatch(setShowMenu(false));
    }

    const handlingUser = () => {
        navigate("/user");
        handlingClose();
    }

    const handlingUserTickets = () => {
        navigate("/user-tickets");
        handlingClose();
    }

    const handlingFirm = () => {
        navigate("/firm/:firmNumber/edit-firm");

        //navigate("/register-firm");


        handlingClose();
    }

    const handlingSlot = () => {
        navigate("/firm/:firmNumber/manage-slot");

        //navigate("/register-firm");


        handlingClose();
    }

    const handlingTheme = () => {
        handlingClose();
    }

    const handlingSignOut = () => {
        handlingClose();
        // TODO: localStorage HERE
        UserProcessingService.remove();  
    }

    
    return (
        <div className={ state.settings.showMenu ? s.visible : s.invisible }>
            <div className={s.userMenuWrapper} id={state.settings.theme == "dark" ? s.dark : s.light}>
                <div className={s.title}><FontAwesomeIcon icon={faClose} title="Выйти" onClick={handlingClose} /></div>
                <UserMenuItem icon={<FontAwesomeIcon icon={faUser} title="Выйти" />} name={`Профиль (${user.name})`} onClick={handlingUser} />
                <UserMenuItem icon={<FontAwesomeIcon icon={faBook} title="Выйти" />} name={`Мои записи`} onClick={handlingUserTickets} />
                <UserMenuItem icon={<FontAwesomeIcon icon={faSignOut} title="Авторизация" />} name="Выйти" onClick={handlingSignOut} />
            </div>
        </div>
    );
}

export default UserMenu;