import React, { useState, useEffect, useRef } from 'react';
import s from './MapFirmItemCustom.module.css';
import { CircleMarker, Pane, Popup } from 'react-leaflet';

export const MapFirmItemCustom = (props) => {


    const [locality, setLocality] = useState(props.locality);
    const [text, setText] = useState(props.text);
    const [number, setNumber] = useState(props.number);

    useEffect(() => {

        setLocality(props.locality);
        setText(props.text);
        setNumber(props.number);
    }, [props.locality, props.text, props.number])

    const onClick = () => {

        if (props.onChoose != undefined) {
            props.onChoose(number);
        }
    }

    return (
        <div >
            <Pane >
                <CircleMarker 
                    center={[locality?.latitude, locality?.longtitude]}
                    eventHandlers={{
                        click: (e) => {
                          //console.log('marker clicked', e);
                          onClick(number);
                        },
                      }}>
                    <Popup>
                        {text}
                    </Popup>
                </CircleMarker>
            </Pane>
        </div>
    )

}

export default (MapFirmItemCustom);