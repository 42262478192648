import React, { useEffect, useState } from 'react';
import ChooseSimple from '../choose-simple/ChooseSimple';
import { useDispatch, useSelector } from 'react-redux';
import { chooseCity, chooseRegion, chooseService, chooseServiceType } from '../../../rootSlice';
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import config from "../../../config/config.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faParking, faPen } from '@fortawesome/free-solid-svg-icons';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { RestCustomService } from 'omni-services-utils';
import { SelectCustomSearch } from 'omni-services-base';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';

export const SelectParkingPlace = forwardRef((props, ref) => {

    const { firmNumber } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';

    var firms = useSelector(state => state.firms)
    var selectedNumber = useSelector(state => state?.firms?.selectedNumber ?? firms?.list[0]?.number)

    var selectedFirmNumber = selectedNumber;

    var [parkingArea, setParkingArea] = useState(props.parkingArea);

    const state = useSelector(state => state)

    var [slots, setSlots] = useState([]);
    var [options, setOptions] = useState()
    var [selected, setSelected] = useState(props.selected ?? null);
    var [theme, setTheme] = useState(props.theme);
    var [editMode, setEditMode] = useState(props.editMode);

    var [parkingAreaId, setParkingAreaId] = useState(props.parkingAreaId);

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const submitClick = (type, service) => {
        dispatch(chooseServiceType(type));
        dispatch(chooseService(service));
        navigate("./../select-model");
    }

    const getFormattedDate = (dateTime) => {

        var optionsDate = { year: 'numeric', month: 'numeric', day: 'numeric', hour12: false, hour: '2-digit', minute: '2-digit' };

        if (dateTime == null) {
            return null;
        }

        return new Date(dateTime ?? null).toLocaleDateString("ru-RU", optionsDate);
    }

    const getSlotsFromServer = (selected, parkingArea) => {

        if (!parkingArea) {
            return;
        }

        if (selected) {
            RestCustomService.Get(
                `/parkingplace/owner/get/free-and-busy/${parkingArea?.id}/${firmNumber}/${selected}`,
                null,
                true,
                (data) => { fillSlots(data); },
                (err) => { console.error(err); })
        } else {
            RestCustomService.Get(
                `/parkingplace/owner/get/free/${parkingArea?.id}/${firmNumber}`,
                null,
                true,
                (data) => { fillSlots(data);},
                (err) => { console.error(err); })
        }
    }

    const fillSlots = (data) => {

        var opts = [];
        for (var i = 0; i < data.length; i++) {
            var opt = { key: data[i].id, label: `Место ${data[i].number}`, value: data[i]?.number?.toString() };
            opts.push(opt);
        }
        
        setOptions(opts);
    }

    useEffect(() => {
        setSelected(props.selected);
        setParkingArea(props.parkingArea);
        getSlotsFromServer(props.selected, props.parkingArea);
        setTheme(props.theme);
        setEditMode(props.editMode);
    }, [props.selected, props.parkingArea, props.theme, props.editMode]);


    useImperativeHandle(ref, () => ({

        validation() {
    
            var err = null;
    
        
            return err;
        },
        
        getResult() {    
            return {parkingPlace: selected};
        },

    }));

    return (
        <SelectCustomSearch
            options={options}
            title={"Парковочное место"}
            name="choosedPlace"
            selected={selected}
            theme={theme}
            leftText={<FontAwesomeIcon icon={faParking} />}
            setSelectedValue={setSelected}
            editMode={editMode}
        />
    )


});

export default SelectParkingPlace;