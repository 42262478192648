import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './ShowTicket.module.css';
import style from '../../../../special/CommonStyles.module.css';
import { useParams } from 'react-router-dom';
import SelectManufacturerModelSmall from '../../../../forms/choose-manufacturer-model/ChooseManufacturerModel';
import SelectServiceSmall from '../../../../forms/choose-service/ChooseService';
import SelectSlot from '../../../choose/selectSlot/SelectSlot';
import SelectStaff from '../../../choose/selectStaff/SelectStaff';
import SelectUser from '../../../choose/select-user/SelectUser';
import { useRef } from 'react';
import SelectTicketStatuses from '../../../choose/select-ticket-statuses/SelectTicketStatuses';
import { RestCustomService } from 'omni-services-utils';
import { FormCustom } from 'omni-services-common';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileText } from '@fortawesome/free-solid-svg-icons';
import { TextAreaCustom } from 'omni-services-base';

export const ShowTicket = (props) => {


  let { firmNumber } = useParams();
  let { ticketId } = useParams();

  const selectUserStateRef = useRef();
  const selectUserRef = useRef();
  const selectStaffRef = useRef();
  const selectSlotRef = useRef();
  const selectManufacturerRef = useRef();
  const selectServiceRef = useRef();

  var [theme, setTheme] = useState();
  var [text, setText] = useState();
  var [editMode, setEditMode] = useState(false);
  var [error, setError] = useState();

  var [ticket, setTicket] = useState();

  var [userName, setUserName] = useState();
  var [userPhone, setUserPhone] = useState();
  var [begin, setBegin] = useState();
  var [comment, setComment] = useState();

  var [success, setSuccess] = useState();
  

  var firms = useSelector(state => state.firms)
  var selectedNumber = useSelector(state => state.firms.selectedNumber ? state.firms.selectedNumber : null)
  var selectedFirmNumber = firmNumber; //selectedNumber ?? firms?.list[0]?.number;


  const state = useSelector(state => state)

  const options = {
    readonly: false,
    canRefresh: false,
    canSave: true,
    canDelete: true,
    showMenuButtons: true,
    showFooterButtons: true,
    submitText: "Добавить запись",
    showSelectFirm: true,
    editMode: true,
    showSelectFirm: true,
    needToLoggedIn: true,
    enableBackButton: true
  };

  RestCustomService.setUrl(ConfigCustomService.getUrl());

  const handleSave = (ticketEdit) => {

    if (ticketId) {

      RestCustomService.Patch(
        `/ticket/owner/edit/${selectedFirmNumber}/`,
        ticketEdit,
        true,
        (data) => { setSuccess("Запись успешно изменена!"); },
        (err) => { setError(err); }
      );

    } else {

      RestCustomService.Put(
        `/ticket/owner/add/${selectedFirmNumber}/`,
        ticketEdit,
        true,
        (data) => { setSuccess("Запись успешно добавлена!"); },
        (err) => { setError(err); }
      );
    }

  }

  const handleSubmit = (event) => {

  }

  var fillTicket = (data) => {
    setTicket(data);
  }

  const getTicketById = (id) => {

    RestCustomService.Get(
      `/ticket/user/get-by-id/${ticketId}`,
      null,
      true,
      (data) => { fillTicket(data); },
      (err) => { console.error(err); })
  }

  const setEditModeFunc = () => {
    setEditMode(!editMode);
  }

  useEffect(() => {
    setTheme(props.theme);
    setText(props.text);
    if (ticketId) {
      getTicketById(ticketId);
    }
  },
    [props.theme, props.text, ticketId])


  const nameValidation = (text) => {

    if (!text) {
      return "значение обязательно";
    }

    if (text.length == 0) {
      return "значение обязательно";
    }

    if (/^[a-zA-Zа-яА-ЯёЁ\s]*$/.test(text) == false) {
      return "только буквы и пробел";
    }

    return null;
  }

  const phoneValidation = (text) => {

    if (!text) {
      return "значение обязательно";
    }

    if (text.length == 0) {
      return "значение обязательно";
    }

    if (/^[+0-9]{12}$/.test(text) == false) {
      return "формат +11 цифр: +7XXXXXXXXXX";
    }

    return null;
  }

  const emailValidation = (text) => {

    if (!text) {
      return "значение обязательно";
    }

    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(text) == false) {
      return "неверный формат адреса почты";
    }

    return null;
  }

  const validation = () => {

    var err = null;

    err = selectUserRef.current.validation();
    if (err) {
      return err;
    }

    return err;
  }


  return (
    <div className={style.formWrapper} id={(state.settings.theme == "dark") ? style.dark : style.light}>
      <FormCustom
        title={"Запись"}
        onSubmit={handleSubmit}
        editMode={editMode}
        setEditMode={setEditModeFunc}
        body={
          <div>
            <SelectTicketStatuses selected={ticket?.state} theme={state.settings.theme} editMode={editMode} ref={selectUserStateRef} />
            <SelectUser ref={selectUserRef} selected={ticket?.userId} theme={state.settings.theme} editMode={editMode} />
            {
              <SelectStaff ref={selectStaffRef} staffId={ticket?.staffId} theme={state.settings.theme} editMode={editMode} />

            }
            {
              //<SelectSlot selected={ticket?.slotId} ref={selectSlotRef} theme={state.settings.theme} editMode={editMode} />
            }
            {/*<SelectCitySmall regionCode={ticket?.address?.regionCode} cityCode={ticket?.address?.cityCode}/>*/}
            <SelectManufacturerModelSmall
              manufacturerCode={ticket?.manufacturerCode}
              manufacturerModelCode={ticket?.manufacturerModelCode}
              ref={selectManufacturerRef}
              theme={state.settings.theme}
              editMode={editMode}
            />
            {/*<SelectTransportSubcategorySmall />*/}
            <SelectServiceSmall
              serviceTypeCode={ticket?.serviceTypeCode}
              serviceCode={ticket?.serviceCode}
              ref={selectServiceRef}
              theme={state.settings.theme}
              editMode={editMode}
            />

            <TextAreaCustom
              theme={theme}
              controlId="name"
              label="Комментарий"
              required={false}
              type="textarea"
              name="name"
              placeholder="Комментарий..."
              muted=""
              message={comment}
              readOnly={false}
              setMessage={setComment}
              leftText={<FontAwesomeIcon icon={faFileText} />}
            />
          </div>
        }
        theme={state.settings.theme}
        error={error}
        options={options}
        validation={validation}
      />

    </div>
  )
}

export default ShowTicket;