import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import s from './TabRequisites.module.css';
import style from '../../../../../../special/CommonStyles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faRouble } from '@fortawesome/free-solid-svg-icons';
import { InputTextCustom } from 'omni-services-base';

export const TabRequisites = forwardRef((props, ref) => {

    const dispatch = useDispatch();

    var [theme, setTheme] = useState(props.theme);
    var [editMode, setEditMode] = useState(props.editMode);

    const done = useSelector(state => state.done)

    const state = useSelector(state => state)

    var [legalName, setLegalName] = useState(props.requisites?.legalName);
    var [legalAddress, setLegalAddress] = useState(props.requisites?.legalAddress);
    var [legalMailAddress, setLegalMailAddress] = useState(props.requisites?.legalMailAddress);
    var [inn, setInn] = useState(props.requisites?.inn);
    var [ogrn, setOgrn] = useState(props.requisites?.ogrn);
    var [kpp, setKpp] = useState(props.requisites?.kpp);
    var [okpo, setOkpo] = useState(props.requisites?.okpo);
    var [checkingAccount, setCheckingAccount] = useState(props.requisites?.checkingAccount);
    var [correspondentAccount, setCorrespondentAccount] = useState(props.requisites?.correspondentAccount);
    var [bic, setBic] = useState(props.requisites?.bic);


    useEffect(() => {

        setTheme(props.theme);
        setEditMode(props.editMode);

        setLegalName(props.requisites?.legalName);
        setLegalAddress(props.requisites?.legalAddress);
        setLegalMailAddress(props.requisites?.legalMailAddress);
        setInn(props.requisites?.inn);
        setOgrn(props.requisites?.ogrn);
        setKpp(props.requisites?.kpp);
        setOkpo(props.requisites?.okpo);
        setCheckingAccount(props.requisites?.checkingAccount);
        setCorrespondentAccount(props.requisites?.correspondentAccount);
        setBic(props.requisites?.bic);
    }, [
        props.requisites,
        props.theme,
        props.editMode,
    ]);

    const innValidation = (text) => {

        if (!text) {
            return null;
        }

        if (text.length == 0) {
            return null;
        }

        if (isNaN(text)) {
            return "Только цифровые значения";
        }

        if ((/^[0-9]{9}$/.test(text) == false) &&
            (/^[0-9]{10}$/.test(text) == false) &&
            (/^[0-9]{12}$/.test(text) == false)) {
            return "только 9, 10 или 12 цифр";
        }

        return null;
    }

    const kppValidation = (text) => {

        if (!text) {
            return null;
        }

        if (text.length == 0) {
            return null;
        }

        if (isNaN(text)) {
            return "Только цифровые значения";
        }

        if (/^[0-9]{9}$/.test(text) == false) {
            return "только 9 цифр";
        }

        return null;
    }



    const bicValidation = (text) => {

        if (!text) {
            return null;
        }

        if (text.length == 0) {
            return null;
        }

        if (isNaN(text)) {
            return "Только цифровые значения";
        }

        if (/^[0-9]{9}$/.test(text) == false) {
            return "только 9 цифр";
        }

        return null;
    }

    const okpoValidation = (text) => {

        if (!text) {
            return null;
        }

        if (text.length == 0) {
            return null;
        }

        if (isNaN(text)) {
            return "Только цифровые значения";
        }

        if ((/^[0-9]{8}$/.test(text) == false) &&
            (/^[0-9]{10}$/.test(text) == false) &&
            (/^[0-9]{14}$/.test(text) == false)) {
            return "только 8, 10 или 14 цифр";
        }

        return null;
    }

    const ogrnValidation = (text) => {

        if (!text) {
            return null;
        }

        if (text.length == 0) {
            return null;
        }

        if (isNaN(text)) {
            return "Только цифровые значения";
        }

        if (/^[0-9]{13}$/.test(text) == false) {
            return "только 13 цифр";
        }

        return null;
    }


    const checkingAccountValidation = (text) => {

        if (!text) {
            return null;
        }

        if (text.length == 0) {
            return null;
        }

        if (isNaN(text)) {
            return "Только цифровые значения";
        }

        if (/^[0-9]{20}$/.test(text) == false) {
            return "состоит из 20 цифр";
        }

        return null;
    }

    const сorrespondentAccountValidation = (text) => {

        if (!text) {
            return null;
        }

        if (text.length == 0) {
            return null;
        }

        if (isNaN(text)) {
            return "Только цифровые значения";
        }

        if (/^[0-9]{20}$/.test(text) == false) {
            return "состоит из 20 цифр";
        }

        return null;
    }

    useImperativeHandle(ref, () => ({

        validation() {

            var err = null;

            err = innValidation(inn);
            if (err) {
                return "Поле ИНН: " + err;
            }

            err = kppValidation(kpp);
            if (err) {
                return "Поле КПП: " + err;
            }

            err = okpoValidation(okpo);
            if (err) {
                return "Поле ОКПО: " + err;
            }

            err = ogrnValidation(ogrn);
            if (err) {
                return "Поле ОГРН: " + err;
            }

            err = checkingAccountValidation(checkingAccount);
            if (err) {
                return "Поле Расчётный счёт: " + err;
            }

            err = сorrespondentAccountValidation(correspondentAccount);
            if (err) {
                return "Поле Корреспондентский счёт: " + err;
            }

            err = bicValidation(bic);
            if (err) {
                return "Поле БИК: " + err;
            }

            return null;
        },

        getResult() {

            return {
                legalName: legalName,
                legalAddress: legalAddress,
                legalMailAddress: legalMailAddress,
                inn: inn,
                kpp: kpp,
                ogrn: ogrn,
                okpo: okpo,
                checkingAccount: checkingAccount,
                correspondentAccount: correspondentAccount,
                bic: bic,
            };
        },

    }));



    return (
        <div>
            <InputTextCustom
                controlId="LegalName"
                label="Юридическое название"
                required={false}
                type="text"
                name="legalName"
                placeholder="Юридическое название..."
                muted=""
                message={legalName}
                setMessage={setLegalName}
                leftText={<FontAwesomeIcon icon={faInfo} />}
                theme={theme}
                readOnly={!editMode}
            />
            <InputTextCustom
                controlId="LegalAddress"
                label="Юридический адрес"
                required={false}
                type="text"
                name="legalAddress"
                placeholder="Юридический адрес..."
                muted=""
                message={legalAddress}
                setMessage={setLegalAddress}
                leftText={<FontAwesomeIcon icon={faInfo} />}
                theme={theme}
                readOnly={!editMode}
            />
            <InputTextCustom
                controlId="LegalMailAddress"
                label="Почтовый адрес"
                required={false}
                type="text"
                name="legalMailAddress"
                placeholder="Юридическое название..."
                muted=""
                message={legalMailAddress}
                setMessage={setLegalMailAddress}
                leftText={<FontAwesomeIcon icon={faInfo} />}
                theme={theme}
                readOnly={!editMode}
            />
            <InputTextCustom
                controlId="Inn"
                label="ИНН"
                required={false}
                type="text"
                name="inn"
                placeholder="ИНН..."
                muted=""
                message={inn}
                setMessage={setInn}
                leftText={<FontAwesomeIcon icon={faInfo} />}
                theme={theme}
                readOnly={!editMode}
                validation={innValidation}
            />
            <InputTextCustom
                controlId="Kpp"
                label="КПП"
                required={false}
                type="text"
                name="kpp"
                placeholder="КПП..."
                muted=""
                message={kpp}
                setMessage={setKpp}
                leftText={<FontAwesomeIcon icon={faInfo} />}
                theme={theme}
                readOnly={!editMode}
                validation={kppValidation}
            />
            <InputTextCustom
                controlId="Ogrn"
                label="ОГРН"
                required={false}
                type="text"
                name="ogrn"
                placeholder="ОГРН..."
                muted=""
                message={ogrn}
                setMessage={setOgrn}
                leftText={<FontAwesomeIcon icon={faInfo} />}
                theme={theme}
                readOnly={!editMode}
                validation={ogrnValidation}
            />
            <InputTextCustom
                controlId="Okpo"
                label="ОКПО"
                required={false}
                type="text"
                name="okpo"
                placeholder="ОКПО..."
                muted=""
                message={okpo}
                setMessage={setOkpo}
                leftText={<FontAwesomeIcon icon={faInfo} />}
                theme={theme}
                readOnly={!editMode}
                validation={okpoValidation}
            />
            <InputTextCustom
                controlId="CheckingAccount"
                label="Расчётный счёт"
                required={false}
                type="text"
                name="checkingAccount"
                placeholder="Расчётный счёт..."
                muted=""
                message={checkingAccount}
                setMessage={setCheckingAccount}
                leftText={<FontAwesomeIcon icon={faInfo} />}
                theme={theme}
                readOnly={!editMode}
                validation={checkingAccountValidation}
            />
            <InputTextCustom
                controlId="CorrespondentAccount"
                label="Корреспондентский счёт"
                required={false}
                type="text"
                name="correspondentAccount"
                placeholder="Корреспондентский счёт..."
                muted=""
                message={correspondentAccount}
                setMessage={setCorrespondentAccount}
                leftText={<FontAwesomeIcon icon={faInfo} />}
                theme={theme}
                readOnly={!editMode}
                validation={сorrespondentAccountValidation}
            />
            <InputTextCustom
                controlId="Bic"
                label="БИК"
                required={false}
                type="text"
                name="bic"
                placeholder="БИК..."
                muted=""
                message={bic}
                setMessage={setBic}
                leftText={<FontAwesomeIcon icon={faInfo} />}
                theme={theme}
                readOnly={!editMode}
                validation={bicValidation}
            />

        </div>
    );
});

export default TabRequisites;