import React, { useEffect, useState } from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import s from './TabSettings.module.css';
import ChooseServiceSettings from '../../../../../choose/choose-service-settings/ChooseServiceSettings';
import DirectionsObject from './DirectionsObject';
import SettingsObject from './SettingsObject';
import { CheckboxCustom } from 'omni-services-base';
import { InputTextCustom } from 'omni-services-base';
import { RadioGroupCustom } from 'omni-services-base';

export const TabSettings = forwardRef((props, ref) => {

    const [staffMaxNumber, setStaffMaxNumber] = useState();
    const [accountBalance, setAccountBalance] = useState();

    var [appointmentStrategy, setAppointmentStrategy] = useState();
    var [paymentStrategy, setPaymentStrategy] = useState();

    const [isEnable, setIsEnable] = useState();
    const [isClosed, setIsClosed] = useState();

    const [enableEmailNotification, setEnableEmailNotification] = useState();
    const [enableTelegramNotification, setEnableTelegramNotification] = useState();
    const [enableSmsNotificationUser, setEnableSmsNotificationUser] = useState();
    const [enableSmsNotificationWorker, setEnableSmsNotificationWorker] = useState();
    const [enableSmsNotificationManager, setEnableSmsNotificationManager] = useState();
    const [enableSmsNotificationOwner, setEnableSmsNotificationOwner] = useState();
    const [enableProductionCalendar, setEnableProductionCalendar] = useState();

    const chooseServiceRef = useRef([]);

    var [directions, setDirections] = useState();

    var [editMode, setEditMode] = useState(props.editMode);
    
    useEffect(() => {
        setIsEnable(props.settings.isEnable);  
        setIsClosed(props.settings.isClosed);
        setStaffMaxNumber(props.settings.staffMaxNumber);
        setEnableEmailNotification(props.settings.enableEmailNotification);
        setEnableTelegramNotification(props.settings.enableTelegramNotification)
        setEnableSmsNotificationUser(props.settings.enableSmsNotificationUser);
        setEnableSmsNotificationWorker(props.settings.enableSmsNotificationWorker);
        setEnableSmsNotificationManager(props.settings.enableSmsNotificationManager);
        setEnableSmsNotificationOwner(props.settings.enableSmsNotificationOwner);
        setEnableProductionCalendar(props.settings.enableProductionCalendar);
        setAppointmentStrategy(props.settings.appointmentStrategy);
        setPaymentStrategy(props.settings.paymentStrategy);
        {
            setDirections(props.directions);
        }
        setEditMode(props.editMode);
    }, [props.settings, props.directions, props.editMode])

    useImperativeHandle(ref, () => ({
        
        getSettingsResult() {

            var directions = SettingsObject;
            directions.appointmentStrategy = + appointmentStrategy;
            directions.paymentStrategy = + paymentStrategy;
            directions.isEnable = isEnable
            directions.isClosed = isClosed;
            directions.enableEmailNotification = enableEmailNotification;
            directions.enableTelegramNotification = enableTelegramNotification;
            directions.enableSmsNotificationUser = enableSmsNotificationUser;
            directions.enableSmsNotificationWorker = enableSmsNotificationWorker;
            directions.enableSmsNotificationManager = enableSmsNotificationManager;
            directions.enableSmsNotificationOwner = enableSmsNotificationOwner;
            directions.enableProductionCalendar = enableProductionCalendar;

            return directions;
        },
        
        getDirectionsResult() {

            var res = chooseServiceRef.current.getResult();
            var directions = DirectionsObject;
            
            directions.serviceTypesCode = res.serviceTypesCode;
            directions.servicesCode = res.servicesCode;

            return directions;
        },

      }));

    return (
        <div>
            <div>
                <fieldset>
                    <legend  className={s.legend}>Активность</legend>
                        <CheckboxCustom
                            label={"Фирма активна"} 
                            checked={isEnable} 
                            onChange={() => setIsEnable(!isEnable)} 
                            name="isEnable" 
                            id="isEnable" 
                            readOnly={!editMode}/>

                        <CheckboxCustom 
                            label={"Перерыв"} 
                            checked={isClosed} 
                            onChange={() => setIsClosed(!isClosed)} 
                            id="isClosed" 
                            name="isClosed" 
                            readOnly={!editMode}/>
                </fieldset>
                <fieldset>
                    <legend  className={s.legend}>Интеграции</legend>
                    <CheckboxCustom 
                            label={"Включить проверку дат в производственном календаре"} 
                            checked={enableProductionCalendar} 
                            onChange={() => setEnableProductionCalendar(!enableProductionCalendar)} 
                            id="enableSmsNotificationWorker" 
                            name="enableSmsNotificationWorker" 
                            readOnly={!editMode}/>
                </fieldset>
                    
                <RadioGroupCustom 
                    options={[
                            { key: "Абонентская", value: 0 }, 
                            { key: "По тарифу", value: 1 }
                        ]}
                    name={"paymentStrategy"}
                    legend={"Способ оплаты"}
                    selectedValue={paymentStrategy}
                    onChange={setPaymentStrategy}
                    readOnly={!editMode}
                />

                <RadioGroupCustom 
                    options={[
                            { key: "Без слотов", value: 0 }, 
                            { key: "Слот для фирмы", value: 1 }, 
                            { key: "Слот для работника", value: 2 }
                        ]}
                    name={"appointmentStrategy"}
                    legend={"Схема записи"}
                    selectedValue={appointmentStrategy}
                    onChange={setAppointmentStrategy}
                    readOnly={!editMode}
                />
                <br/>
                <fieldset>
                    <legend  className={s.legend}>Выберите способ оповещений</legend>
                    <CheckboxCustom 
                        label={"Оповещения в почте"} 
                        checked={enableEmailNotification} 
                        onChange={() => setEnableEmailNotification(!enableEmailNotification)} 
                        id="enableEmailNotification" 
                        name="enableEmailNotification" 
                        readOnly={!editMode}
                        />
                    <CheckboxCustom 
                        label={"Оповещения в Telegram"} 
                        checked={enableTelegramNotification} 
                        onChange={() => setEnableTelegramNotification(!enableTelegramNotification)} 
                        id="enableTelegramNotification" 
                        name="enableTelegramNotification" 
                        readOnly={!editMode}
                        />
                    <CheckboxCustom 
                        label={"Оповещения в СМС клиента"} 
                        checked={enableSmsNotificationUser} 
                        onChange={() => setEnableSmsNotificationUser(!enableSmsNotificationUser)} 
                        id="enableSmsNotificationUser"
                        name="enableSmsNotificationUser" 
                        readOnly={!editMode}/>
                    <CheckboxCustom 
                        label={"Оповещения в СМС сотрудника"} 
                        checked={enableSmsNotificationWorker} 
                        onChange={() => setEnableSmsNotificationWorker(!enableSmsNotificationWorker)} 
                        id="enableSmsNotificationWorker"
                        name="enableSmsNotificationWorker" 
                        readOnly={!editMode}/>
                    <CheckboxCustom 
                        label={"Оповещения в СМС менеджера"} 
                        checked={enableSmsNotificationManager} 
                        onChange={() => setEnableSmsNotificationManager(!enableSmsNotificationManager)} 
                        id="enableSmsNotificationManager"
                        name="enableSmsNotificationManager" 
                        readOnly={!editMode}/>
                    <CheckboxCustom 
                        label={"Оповещения в СМС владельца"} 
                        checked={enableSmsNotificationOwner} 
                        onChange={() => setEnableSmsNotificationOwner(!enableSmsNotificationOwner)} 
                        id="enableSmsNotificationWorker" 
                        name="enableSmsNotificationWorker" 
                        readOnly={!editMode}/>
                </fieldset>

                <InputTextCustom
                    controlId="staffMaxNumber"
                    label="Максимальное количество сотрудников"
                    required={false}
                    type="number"
                    name="staffMaxNumber"
                    placeholder="Максимальное количество сотрудников..."
                    muted=""
                    message={staffMaxNumber}
                    readOnly={false}
                    setMessage={setStaffMaxNumber}
                /> 
                <p>Специализация</p>              
                {/*<AccordionCustom header={"Типы сервисов"} body={<MultiSelectCustom title={"Типы сервисов"} />}/>
                <AccordionCustom header={"Категории транспорта"} body={<MultiSelectCustom title={"Категории транспорта"} />}/>
                <AccordionCustom header={"Марки автомобилей"} body={<MultiSelectCustom title={"Модели автомобилей"} />}/>*/}
                {<ChooseServiceSettings 
                    directions={directions} 
                    editMode={editMode}
                    ref={chooseServiceRef}
                    />}
                
            </div>
        </div>
    );
});

export default TabSettings;