import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import s from './NavbarCustom.module.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faBars, faBell, faCalendar, faCartArrowDown, faEllipsisVertical, faExpandArrowsAlt, faHome, faLocation, faLocationArrow, faLocationDot, faLocationPin, faLocationPinLock, faMapLocation, faMapLocationDot, faRegistered, faSignIn, faSignOut, faSignOutAlt, faTh, faUser } from '@fortawesome/free-solid-svg-icons';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chooseTheme, needAuthShow, setIsAuthorized, setShowMenu, setShowRight } from './../../rootSlice.js';
import UserMenu from '../../user/menu/UserMenu';
import { UserProcessingService } from 'omni-services-common';

import logo from './../../img/logo_big.png'
import NotificationBaseCustom from '../../auto-service/notification/NotificationBaseCustom.jsx';
import NotificationLabelCustom from '../../auto-service/notification/notification-label/NotificationLabelCustom.jsx';
import AuthContainerLabel from '../../auto-service/forms/auth-container/auth-container-label/AuthContainerLabel.jsx';

export const NavbarCustom = (props) => {

  const dispatch = useDispatch();
  const cityName = useSelector(state => state.order.city.name)

  var [loggedIn, setLoggedIn] = useState()

  const userName = useSelector(state => state.user.name)

  function changeTheme() {
    dispatch(chooseTheme(state.settings.theme == "dark" ? "light" : "dark"));
    localStorage.setItem('theme', JSON.stringify(state.settings.theme == "dark" ? "light" : "dark"));
  }

  function showAuthForm() {
    dispatch(needAuthShow(true));
  }

  function showUserMenu() {
    dispatch(setShowRight());
  }

  function fullScreenEnable() {
    //Element.requestFullscreen();
  }

  useEffect(() => {
    var theme = JSON.parse(localStorage.getItem('theme'));
    dispatch(chooseTheme(theme == "dark" ? "dark" : "light"));
  }, []);

  useEffect(() => {

    if (UserProcessingService.get()) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }

  }, [UserProcessingService.get()]);

  const state = useSelector(state => state);

  return (
    <div>
      <Navbar className={s.navbarCustomClass} expand="lg" id={state.settings.theme == "dark" ? s.dark : s.light}>
        <Container>
          <Navbar.Brand className={s.navIcon} as={Link} to="/">
            <img
              alt=""
              src={logo}
              width="48"
              height="48"
              className="d-inline-block align-top"
            />{' '}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className={s.nav + " me-auto"}>
              <Nav.Link className={s.navText} as={Link} to="/"><FontAwesomeIcon icon={faHome} /></Nav.Link>
              <Nav.Link className={s.navText} as={Link} to="/select-city">Записаться!</Nav.Link>
              <Nav.Link className={s.navText} as={Link} to="/customer">Сотрудничество</Nav.Link>
              <Nav.Link className={s.navText} as={Link} to="/about">О нас</Nav.Link>
            </Nav>
            <div className={"my-2 my-lg-0 " + s.rightContainer}>
              <div className={s.rightCell}>
                <Nav.Link className={s.navText} onClick={fullScreenEnable}><FontAwesomeIcon icon={faLocationDot} /></Nav.Link>
                <span>{cityName}</span>
              </div>
              <div className={s.rightCell}>
                {/*<Nav.Link className={s.navText}><FontAwesomeIcon icon={faBell} /></Nav.Link>*/}
                {
                  loggedIn == true
                  ?
                  <NotificationLabelCustom theme={state.settings.theme} />
                  : null
                }
              </div>
              <div className={s.rightCell}>

                {state.settings.theme == "dark" ?
                  <Nav.Link className={s.navText} onClick={changeTheme}><FontAwesomeIcon icon={faMoon} /></Nav.Link>
                  : <Nav.Link className={s.navText} onClick={changeTheme}><FontAwesomeIcon icon={faSun} /></Nav.Link>
                }
              </div>
              <div className={s.rightCell}>
                <Nav.Link className={s.navText} onClick={fullScreenEnable}><FontAwesomeIcon icon={faCalendar} /></Nav.Link>
              </div>
              {
                loggedIn == true
                  ?
                  <Nav.Link className={s.navText} onClick={showUserMenu}><FontAwesomeIcon icon={faBars} title="Меню" /></Nav.Link>
                  :
                  <div className={s.rightCell}>
                    <AuthContainerLabel theme={state.settings.theme} />
                  </div>

              }
              {/*<form class="form-inline">
                <input class="form-control mr-sm-3" type="search" placeholder="Search" aria-label="Search" />
                <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button>
              </form>*/}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>

  )

}

export default NavbarCustom;