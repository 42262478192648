import React, { useEffect, useState } from 'react';
import { RestCustomService } from 'omni-services-utils';
import { GalleryCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../../../../services/base/config-custom-service/ConfigCustomService';

export const TabPhoto = (props) => {

  const [staffMaxNumber, setStaffMaxNumber] = useState();
  const [accountBalance, setAccountBalance] = useState();

  const [isEnable, setIsEnable] = useState();
  const [isClosed, setIsClosed] = useState();

  var [editMode, setEditMode] = useState(props.editMode);

  var [src, setSrc] = useState([props.staff?.photo] ?? []);

  var [staff, setStaff] = useState(props.staff);

  var [selectedFirmNumber, setSelectedFirmNumber] = useState(props.selectedFirmNumber);

  RestCustomService.setUrl(ConfigCustomService.getUrl());

  const handleSendLogo = (fileModel) => {

    RestCustomService.Put(
      `/staff/owner/image/add/${selectedFirmNumber}/${staff.id}`,
      fileModel,
      true,
      () => {  },
      (err) => { }
    )
  }


  const deleteLogoGallery = (src) => {
    var fileModelCustom = {
      fileName: src?.toString(),
      content: ''
    }

    RestCustomService.Delete(
      `/staff/owner/image/delete/${selectedFirmNumber}/${staff.id}`,
      fileModelCustom,
      true,
      () => { },
      (err) => { }
    )
  }


  const handleSendImage = (fileModel) => {
    
  }


  const deleteImageGallery = (src) => {
    /*var fileModelCustom = {
      fileName: src?.toString(),
      content: ''
    }
  
      requestApiDelete(
        `/firm/owner/image/delete/${props.firm.number}/`,
        fileModelCustom,
        true,
        () => {},
        (err) => {}
      )*/
  }


  useEffect(() => {
    if (props.staff?.photo) {
      setSrc([props.staff?.photo]);
    }
    setStaff(props.staff);
    setEditMode(props.editMode);
  }, [props.settings, props.staff, props.editMode])

  return (
    <div>
      <div>
        <GalleryCustom
          srcs={src} 
          deleteImage={deleteLogoGallery} 
          handleSend={handleSendLogo} 
          label={'Фото'} 
          editMode={editMode}/>
      </div>
    </div>
  );
}

export default TabPhoto;