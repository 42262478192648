import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { SelectCustomSearch } from 'omni-services-base';

export const ChooseDoubleToFirm = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const base = useSelector(state => state.base)
    const { register, handleSubmit } = useForm({ defaultValues: { base } })

    const state = useSelector(state => state)

    var [selectedValue, setSelectedValue] = useState();
    var [selectedSubValue, setSelectedSubValue] = useState();

    var [selectedValueFull, setSelectedValueFull] = useState();
    var [selectedSubValueFull, setSelectedSubValueFull] = useState();

    var [options, setOptions] = useState([]);
    var [subOptions, setSubOptions] = useState([]);

    var [success, setSuccess] = useState('');
    var [error, setError] = useState('');

    var [editMode, setEditMode] = useState(true);
    var [theme, setTheme] = useState(props.theme);

    const onSubmit = () => {
        var obj = options.find(o => o.value == selectedValue);
        var selectedObj = { code: obj.value, name: obj.label }

        var subObj = subOptions.find(o => o.value == selectedSubValue);
        var selectedSubObj = { code: subObj.value, name: subObj.label }

        props.chooseFunc(selectedObj, selectedSubObj);
    }

    const handleClick = (val) => {
        selectedValue = { code: val.value, name: val.label };
        //subOptions = [];
        setSelectedValue(val);
        props.selectSubdirectoryFunc(val).then(response => fillSubdirectory(response));
    }

    const handleSubClick = (val) => {
        setSelectedSubValue(val);
        //setIsDisabled(false);
    }

    useEffect(() => {
        if ((options) && (options.length == 0)) {
            props.selectDirectoryFunc().then(response => fillDirectory(response));
            //setIsDisabled(true);
        }
        setTheme(props.theme);
    }, [options, props.theme]);

    const fillDirectory = (data) => {

        if ((options) && (options.length > 0)) {
            return;
        }

        var opts = [];

        for (var i = 0; i < data.length; i++) {
            var ChooseSimple = { key: data[i].id, label: data[i].name, value: data[i].code };
            opts.push(ChooseSimple);
        }

        setOptions(opts)
    }

    const fillSubdirectory = (data) => {

        setSubOptions([]);

        var opts = []
        for (var i = 0; i < data.length; i++) {
            var ChooseSimple = { key: data[i].id, label: data[i].name, value: data[i].code };
            opts.push(ChooseSimple);
        }
        setSubOptions(opts);
    }

    const isDisabled = () => {
        return state.settings.theme == "dark";
    }

    const setEditModeFunc = () => {
        setEditMode(!editMode);
    }

    const formOptions = {
        readOnly: false,
        showMenuButtons: false,
        showFooterButtons: true,
        submitText: "Далее",
        enableBackButton: true
    };

    const handlingFormEdit = (val) => {
        //setUsePhone(val);
    }

    useImperativeHandle(ref, () => ({

        validation() {

            return null;
        },
        
        getResult() {
            
            return {
                selectedValue: selectedValue, 
                selectedSubValue: selectedSubValue
            };
        },

    }));

    return (
        <div>
            <SelectCustomSearch
                theme={state.settings.theme}
                options={options}
                title={props.directoryName}
                placeholder="Начните набирать название..."
                selected={selectedValue}
                setSelectedValue={(value) => handleClick(value)}
            />
            <SelectCustomSearch
                theme={state.settings.theme}
                required={true}
                title={props.subDirectoryName}
                selected={selectedSubValue}
                options={subOptions}
                placeholder="Начните набирать название..."
                setSelectedValue={(value) => handleSubClick(value)}
            />
        </div>
    )
});

export default (ChooseDoubleToFirm);