import React, { useEffect, useState } from 'react';
import s from './SettingsCheckDouble.module.css';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { CheckboxCustom } from 'omni-services-base';

export const SettingsCheckDouble = forwardRef((props, ref) => {

    const refs = useRef([]);

    var [label, setLabel] = useState();
    var [value, setValue] = useState();
    var [code, setCode] = useState();
    var [checked, setChecked] = useState();
    var [subNotes, setSubNotes] = useState(props.subNotes);

    var [options, setOptions] = useState();

    var [editMode, setEditMode] = useState(props.editMode);

    const fill = (data) => {

        var opts = [];

        for (var i = 0; i < data.length; i++) {
            var obj = { label: data[i].name, code: data[i].code, checked: subNotes?.some(e => e == data[i].code) ?? false};
            opts.push(obj);
        }
        setOptions(opts);
    }


    useEffect(() => {
        setLabel(props.label);
        setCode(props.code); 

        setValue(props.value);
        setChecked(props.checked);
        setSubNotes(props.subNotes);
        //setCounter(props.counter);
        props.get(props.code).then(response => fill(response));
        setEditMode(props.editMode);
    }, [props.label, props.code, props.value, props.subNotes, props.checked, props.editMode])

    useImperativeHandle(ref, () => ({
        
        getResult() {

            var res = [];

            for (var i = 0; i < refs.current.length; i++) {
                res.push(refs.current[i].getResult());
            }

            return {val: {checked: checked, value: value}, arr: res};
        },

    }));

    return (
        <div>
           <CheckboxCustom
            label={label} 
            value={value}
            checked={checked}
            readOnly={!editMode}
            name={`service-type-index`}
            onChange={() => setChecked(!checked)}
             />
             <div className={s.subSettings}>
             {
                    options?.map((object, index) => 
                        <CheckboxCustom
                            label={object.label} 
                            checked={object.checked}
                            value={`${object.code}`}
                            name={`service-index${object.code}`}
                            id={`service-index${object.code}`}
                            readOnly={!editMode}
                            ref={(element) => { refs.current[index] = element;}}
                        />)
            }
            </div>
        </div>
    )
});

export default (SettingsCheckDouble);