import React, { useState } from 'react';
import s from './DocumentsCustom.module.css';
import { ConfigCustomService } from '../../services/base/config-custom-service/ConfigCustomService';
import { CommonDoc, ProcessingFirmSigned, ProcessingSigned } from 'omni-services-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

const DocumentsCustom = () => {

  const [url, setUrl] = useState(ConfigCustomService.getUrl());


  return (
    <div className='centralWrapper'>
      <h2>Документы</h2>
      <p>
        <FontAwesomeIcon icon={faFilePdf} className={s.iconPdf} /> &nbsp; 
        <a href={`${url}/src/agreements/processing-signed/processingSigned.pdf`} target="_blank">
          Политика обработки персональных данных (пользователь)
        </a>
      </p>
      <p>
        <FontAwesomeIcon icon={faFilePdf} className={s.iconPdf} /> &nbsp; 
        <a href={`${url}/src/agreements/spreading-signed/spreadingSigned.pdf`} target="_blank">
          Политика распространения персональных данных (пользователь)
        </a>
      </p>
      <p>
        <FontAwesomeIcon icon={faFilePdf} className={s.iconPdf} /> &nbsp; 
        <a href={`${url}/src/agreements/processing-firm-signed/processingFirmSigned.pdf`} target="_blank">
          Политика обработки персональных данных (клиент)
        </a>
      </p>
      <p>
        <FontAwesomeIcon icon={faFilePdf} className={s.iconPdf} /> &nbsp; 
        <a href={`${url}/src/agreements/spreading-firm-signed/spreadingFirmSigned.pdf`} target="_blank">
          Политика распространения персональных данных (клиент)
        </a>
      </p>
    </div>
  )
}


export default (DocumentsCustom);