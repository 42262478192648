import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './ManageStaff.module.css';
import style from '../../../../special/CommonStyles.module.css';
import EditStaff from '../edit-staff/EditStaff';
import CardStaff from '../card-staff/CardStaff';
import AddStaff from '../add-staff/AddStaff';
import { useParams } from 'react-router-dom';
import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';

export const ManageStaff = (props) => {

  const { firmNumber } = useParams();
  var [theme, setTheme] = useState();
  var [text, setText] = useState();
  var [editMode, setEditMode] = useState(false);
  var [error, setError] = useState();

  const state = useSelector(state => state)
  var firms = useSelector(state => state.firms)
  var selectedNumber = useSelector(state => state.firms.selectedNumber ? state.firms.selectedNumber : null)
  var selectedFirmNumber = firmNumber; // selectedNumber ?? firms?.list[0]?.number;

  var [staffs, setStaffs] = useState([]);

  RestCustomService.setUrl(ConfigCustomService.getUrl());

  const handleSubmit = (event) => {

  }

  const setEditModeFunc = () => {
    setEditMode(!editMode);
  }

  const getAllStaffByAdmin = () => {

    RestCustomService.Get(
      `/staff/owner/get/${selectedFirmNumber}/all/`,
      null, 
      true, 
      (data) => { fillStaffs(data); }, 
      (err) => { console.error(err); })
  }

  const fillStaffs = (data) => {
    setStaffs([]);
    var st = [];
    for (var i = 0; i < data?.length; i++) {
      st.push(data[i]);
    }

    setStaffs(st);
  }

  useEffect(() => {
    setTheme(props.theme);
    setText(props.text);
    getAllStaffByAdmin();
  }, 
  [props.theme, props.text, selectedFirmNumber])

  return (
    <div >
        <AddStaff />
        <div className={s.slotListWrapper}>
          {
            staffs.map((item) => (
              <CardStaff staff={item} refresh={() => {}} />
            ))
          }
        </div>
    </div>
  )
}

export default ManageStaff;