import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { isOrderExists, needAuthFastShow, needAuthShow, setAfterAuthLink, setDone, setErrorCustom } from '../../../rootSlice';
import style from '../../../special/CommonStyles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faCalendar, faCar, faClock, faComment, faFileText, faIndustry, faUser, faWrench } from '@fortawesome/free-solid-svg-icons';
import { RestCustomService } from 'omni-services-utils';
import { InputTextCustom, TextAreaCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';
import { AddressFiasCustom, FormCustom, UserProcessingService } from 'omni-services-common';

export const Ticket = () => {

    const addressRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const themeState = useSelector(state => state.settings.theme);

    const firm = useSelector(state => state.order.firm);
    const slot = useSelector(state => state.order.slot);
    const serviceType = useSelector(state => state.order.serviceType);
    const service = useSelector(state => state.order.service);
    const manufacturer = useSelector(state => state.order.manufacturer);
    const manufacturerModel = useSelector(state => state.order.manufacturerModel);
    const exists = useSelector(state => state.order.exists);

    const [comment, setComment] = useState(null);

    var [theme, setTheme] = useState(themeState);
    var [error, setError] = useState();

    var [address, setAddress] = useState(firm?.address);

    const state = useSelector(state => state)

    const options = {
        showFooterButtons: true,
        showMenuButtons: false,
        readOnly: true,
        submitText: "Подтвердить и записаться",
        cancelText: "Назад",
        enableBackButton: true
    };

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const submitClick = () => {

        if (!UserProcessingService.hasStoredUser()) {
           // dispatch(needAuthFastShow(true));
            dispatch(isOrderExists(true));
            dispatch(setAfterAuthLink("/ticket"));
            console.log("changes!");

            navigate("/auth", { state: "123123" });
            return;
        }

        var body = {
            slotId: slot.id,
            firmNumber: firm.number,
            userId: UserProcessingService.get().id,
            serviceTypeCode: serviceType.code,
            serviceCode: service.code,
            manufacturerCode: manufacturer.code,
            manufacturerModelCode: manufacturerModel.code,
            comment: comment,
        }

        RestCustomService.Put(
            `/ticket/user/make-appointment`,
            body,
            true,
            () => {
                dispatch(setDone({
                    title: "Успешная запись!",
                    description: "Вы были успешно записаны",
                    link: "/",
                    linkText: "Перейдите по ссылке, чтобы выбрать что-нибудь ещё!"
                }));
                navigate("/done");
            },
            (err) => {setError(err)}
        );
    }

    useEffect(() => {
        console.log(firm);
    }, [])

    return (
        <div className={style.formWrapper} id={state.settings.theme == "dark" ? style.dark : style.light}>
        <FormCustom
            title={"Новая запись"}
            options={options}
            onSubmit={submitClick}
            body={
                <div>
                    <AddressFiasCustom 
                        showMap={true}
                        showLiveSearch={false}
                        showAddress={false}
                        showUserLocation={true} 
                        address={firm?.address}
                        theme={state.settings.theme}
                        ref={addressRef}
                        editMode={true}
                        url={ConfigCustomService.getUrl()} 
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Название фирмы"
                        required={false}
                        type="text"
                        name="name"
                        placeholder="Название Вашей фирмы..."
                        muted=""
                        message={firm.name}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faBusinessTime}
                        />}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Дата-время"
                        required={false}
                        type="datetime-local"
                        name="name"
                        placeholder="Дата"
                        muted=""
                        message={slot.begin?.substring(0, 16)}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faCalendar}/>}
                        hide={slot?.id == null}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Длительность"
                        required={false}
                        type="text"
                        name="name"
                        placeholder="Длительность слота..."
                        muted=""
                        message={slot.duration}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faClock}/>}
                        hide={slot?.id == null}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Мастер"
                        required={false}
                        type="text"
                        name="name"
                        placeholder="Мастер..."
                        muted=""
                        message={slot.duration}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faUser}/>}
                        hide={firm?.staffId == null}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Тип сервиса"
                        required={false}
                        type="text"
                        name="name"
                        placeholder="Тип сервиса.."
                        muted=""
                        message={serviceType.name}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faWrench}
                        />}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Сервис"
                        required={false}
                        type="text"
                        name="name"
                        placeholder="Сервиса"
                        muted=""
                        message={service.name}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faWrench}
                        />}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Марка"
                        required={false}
                        type="text"
                        name="name"
                        placeholder="Марка..."
                        muted=""
                        message={manufacturer.name}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faIndustry}
                        />}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Модель"
                        required={false}
                        type="text"
                        name="name"
                        placeholder="Модель..."
                        muted=""
                        message={manufacturerModel.name}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faCar}
                        />}
                    />
                    <TextAreaCustom
                        theme={theme}
                        controlId="name"
                        label="Комментарий"
                        required={false}
                        type="textarea"
                        name="name"
                        placeholder="Комментарий..."
                        muted=""
                        message={comment}
                        readOnly={false}
                        setMessage={setComment}
                        leftText={<FontAwesomeIcon icon={faComment}/>}
                    />
                </div>
            }
            theme={state.settings.theme}
            error={error}
        />

    </div>
    )
}

export default (Ticket);