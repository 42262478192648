import React from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './EditParkingArea.module.css';
import style from '../../../../special/CommonStyles.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import ParkingPlaceEdit from '../manage-parking-place/ParkingPlaceEdit';
import { parkingPlaceDefault } from '../manage-parking-area/Parking-Place-Default';
import ParkingAreaObject from './ParkingAreaObject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faExpand, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { RestCustomService } from 'omni-services-utils';
import { ButtonCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';

export const EditParkingArea = forwardRef((props, ref) => {

    const navigate = useNavigate();
    const parkingPlaceRefs = useRef([]);
    const { firmNumber } = useParams();

    var [theme, setTheme] = useState();
    var [text, setText] = useState();
    var [editMode, setEditMode] = useState(false);
    var [error, setError] = useState();

    var [parkingAreaId, setParkingAreaId] = useState(props.parkingAreaId);
    var [parkingPlaces, setParkingPlaces] = useState([]);

    var [places, setPlaces] = useState(props.places);

    var [selected, setSelected] = useState(false);

    var [lastNumber, setLastNumber] = useState(0);

    var [isExpanded, setIsExpanded] = useState(false);



    const state = useSelector(state => state)
    var selectedParkingPlace = useSelector(state => state.parkingPlace.number)

    const options = {
        readonly: false,
        canRefresh: false,
        canSave: true,
        canDelete: true,
        showMenuButtons: false,
        showFooterButtons: true,
        submitText: "Добавить парковочное место",
        showSelectFirm: true,
        editMode: true,
        needToLoggedIn: true
    };

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const handleSubmit = (event) => {
        //navigate('/edit-ticket/');
    }

    const setEditModeFunc = () => {
        setEditMode(!editMode);
    }

    const [MousePosition, setMousePosition] = useState({
        left: 0,
        top: 0
    })

    const [mouseDown, setMouseDown] = useState();

    const checkIsCoordsAreOut = (event, x, y) => {

        if (x < 0) {
            return true
        }

        if (y < 0) {
            return true
        }

        return false;
    }

    const handleTouchMove = (event) => {

        if (editMode == false) {
            return;
        }

        if (mouseDown) {
            var coords = event.currentTarget.getBoundingClientRect();

            if (checkIsCoordsAreOut(event, event.touches[0].clientX - coords.x, event.touches[0].clientY - coords.y)) {
                return;
            }
            setMousePosition({ left: event.touches[0].clientX - coords.x, top: event.touches[0].clientY - coords.y });
        }

    }

    const handleMouseMove = (event) => {

        if (editMode == false) {
            return;
        }

        if (mouseDown) {
            var coords = event.currentTarget.getBoundingClientRect();

            if (checkIsCoordsAreOut(event, event.clientX - coords.x, event.clientY - coords.y)) {
                return;
            }

            setMousePosition({ left: event.clientX - coords.x, top: event.clientY - coords.y });
        }
    }

    const handleMouseDown = (ev) => {
        setMouseDown(true);
    }

    const handleTouchStart = (ev) => {
        setMouseDown(true);
    }

    const handleMouseUp = (ev) => {
        setMouseDown(false);
    }

    const handleTouchEnd = (ev) => {
        setMouseDown(false);
    }

    useEffect(() => {
        setTheme(props.theme);
        setText(props.text);
        setParkingAreaId(props.parkingAreaId);
        setPlaces(props.places);
        getPlacesFromServer(props.parkingAreaId);
        setEditMode(props.editMode);
    },
        [
            props.theme, 
            props.text, 
            props.selectedFirmNumber, 
            props.parkingAreaId, 
            props.places,
            props.editMode
        ])

    const handleAddPlace = () => {

        var place = JSON.parse(JSON.stringify(parkingPlaceDefault));
        place.id = null;
        place.parkingAreaId = props.parkingAreaId;
        place.leftTopPoint.x = 0;
        place.leftTopPoint.y = 0;
        place.number = places.length > 0 ? Math.max(...places?.map(o => o.number) ?? 0) + 1 : 1;
        setLastNumber(place.number);
        setText(place.number);
        places.push(place);

        return;        
    }

    const getPlacesFromServer = (ManageParkingAreaId) => {

        setPlaces([]);

        if (!ManageParkingAreaId) {
            return;
        }

        RestCustomService.Get(
            `/parkingplace/guest/get/${ManageParkingAreaId}`,
            null,
            true,
            (data) => { setPlaces(data); },
            (err) => { /*console.error(err);*/ setError(err); }
        );
    }

    const handleDeletePlace = () => {

        RestCustomService.Delete(
            `/parkingplace/owner/delete/${selectedParkingPlace}/${firmNumber}`,
            null,
            true,
            (data) => { props.getPlacesFromServer(parkingAreaId); getPlacesFromServer(parkingAreaId); },
            (err) => { console.error(err); })

    }

    useImperativeHandle(ref, () => ({
        
        getParkingPlaces() {

            var parkingPlaces = [];

            for (var i = 0; i < parkingPlaceRefs.current.length; i++) {
                var res = JSON.parse(JSON.stringify(parkingPlaceRefs.current[i].getResult()));
                parkingPlaces.push(res)
            }

            return parkingPlaces;
        },

        getParkingArea() {

            var parkingAreaObj = ParkingAreaObject;
            parkingAreaObj.id = parkingAreaId;
            parkingAreaObj.firmNumber = firmNumber;

            return parkingAreaObj;
        },

    }));

    return (
        <div className={`${s.wrapper} ${ isExpanded ? s.expanded : null}`}>
        <div className={s.container}>
            <div className={s.crudButtons}>
                <ButtonCustom
                    readonly={!editMode}
                    text={<FontAwesomeIcon icon={faSave}/>}
                    theme={state.settings.theme}
                    type={"button"} 
                    onClick={() => {props.handleSubmit()}}/>
            </div>
            <div className={s.crudButtons}>
                <ButtonCustom
                    readonly={!editMode}
                    text={<FontAwesomeIcon icon={faAdd}/>}
                    theme={state.settings.theme}
                    type={"button"} 
                    onClick={handleAddPlace}
                    />
            </div>
            <div className={s.crudButtons}>
                <ButtonCustom
                    readonly={!editMode}
                    text={<FontAwesomeIcon icon={faTrash}/>}
                    theme={state.settings.theme}
                    type={"button"} 
                    onClick={handleDeletePlace}/>
            </div>
            <div className={s.expandButtons}>
                <ButtonCustom
                    readonly={!editMode}
                    text={<FontAwesomeIcon icon={faExpand}/>}
                    theme={state.settings.theme}
                    type={"button"} 
                    onClick={() => { setIsExpanded(!isExpanded) }}/>
            </div>
            <div
                className={s.parkingAreawrapper}
                id={(state.settings.theme == "dark") ? s.dark : s.lightParkingArea}
                onMouseMove={(ev) => handleMouseMove(ev)}
                onMouseDown={(ev) => handleMouseDown(ev)}
                onMouseUp={(ev) => handleMouseUp(ev)}
                onTouchStart={(ev) => handleTouchStart(ev)}
                onTouchEnd={(ev) => handleTouchEnd(ev)}
                onTouchMove={(ev) => handleTouchMove(ev)}
            >
                
                {
                    places?.map((item, index) => (
                        <ParkingPlaceEdit
                            id={item?.id}
                            number={item?.number}
                            top={MousePosition.top}
                            left={MousePosition.left}
                            selected={selected}
                            initialTop={item?.leftTopPoint?.y}
                            initialLeft={item?.leftTopPoint?.x}
                            state={item?.state} 
                            parkingAreaId={item?.parkingAreaId}
                            selectedFirmNumber={firmNumber}
                            ref={(element) => {parkingPlaceRefs.current[index] = element}}
                            editMode={editMode}
                            />
                    ))
                }
            </div>
        </div>
        </div>
    )
});

export default EditParkingArea;