import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuthMode, setDone, setRegisteredUser } from '../../../rootSlice';
import s from './RequestNewPassword.module.css';
import { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { RestCustomService } from 'omni-services-utils';
import { FormCustom, RequestNewPasswordCommon } from 'omni-services-common';
import { InputTextCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';


function RequestNewPassword() {

    const dispatch = useDispatch();


    const state = useSelector(state => state);


    RestCustomService.setUrl(ConfigCustomService.getUrl());

   
    const onRequestPasswordByEmailSuccess = () => {

        dispatch(setDone({
            title: "Восстановление пароля...",
            description: "Проверьте почту",
            link: "/",
            linkText: "На главную"
        }));
    }

    const onRequestPasswordByPhoneSuccess = () => {

        dispatch(setDone({
            title: "Восстановление пароля...",
            description: "Проверьте смс",
            link: "/",
            linkText: "На главную"
        }));
    }

    return (
        <div className={s.authForm} id={state.settings.theme === "dark" ? s.dark : s.light}>
            <RequestNewPasswordCommon 
                url={ConfigCustomService.getUrl()}
                theme={state.settings.theme}
                onRequestPasswordByPhoneSuccess={onRequestPasswordByPhoneSuccess}
                onRequestPasswordByEmailSuccess={onRequestPasswordByEmailSuccess}
            />
        </div>
    );
}

export default RequestNewPassword;