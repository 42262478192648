import React, { useEffect, useState } from 'react';
import s from './NotificationLabelCustom.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { Nav } from 'react-bootstrap';
import NotificationBaseCustom from '../NotificationBaseCustom';
import { RestCustomService } from 'omni-services-utils';
import NotificationNumberCustom from './notification-number/NotificationNumberCustom';
import notificationSound from './notification-sound/notification.mp3';

export const NotificationLabelCustom = (props) => {

    const TIME_STEP = 10000;

    const dispatch = useDispatch();
    const state = useSelector(state => state)

    const [theme, setTheme] = useState(props.theme);

    var [showPanel, setShowPanel] = useState();

    const [notifications, setNotifications] = useState(props.notifications ?? []);

    const [countOfNew, setCountOfNew] = useState(null);



    const playBellSound = () => {
        
        try {
            var promise = new Audio(notificationSound).play();

            if (promise !== undefined) {
                promise.then(_ => {
                    // Autoplay started!
                }).catch(error => {
                    // Autoplay was prevented.
                    // Show a "Play" button so that user can start playback.
                });
            }
        } catch (e) {
            console.error(e);
        }
    }

    const bellClickHandler = () => {
        setShowPanel(!showPanel);
    }

    const backgroundClickHandler = () => {
        setShowPanel(false);
    }

    const loadNotificationsFromServer = () => {

        RestCustomService.Get(
            `/notification/all`,
            null,
            true,
            (data) => { setNotifications(data); },
            (err) => { console.error(err); }
        );
    }

    useEffect(() => {
        setTheme(props.theme);
    },
        [props.theme]);

    useEffect(() => {

        var len = notifications?.filter(n => n.state == 0)?.length ?? 0;
        if (!countOfNew) {
            setCountOfNew(len);
        }

        if (((countOfNew) < len)) {
            playBellSound();
        }

        if ((countOfNew != len)) {
            setCountOfNew(len);
        }
    },
        [notifications]);

    useEffect(() => {

        try {
            setInterval(async () => {
                loadNotificationsFromServer();
            }, TIME_STEP);
        } catch (e) {
            console.error(e);
        }
        loadNotificationsFromServer();

    }, []);

    const getOnlyNewItems = () => {
        return notifications?.filter(n => n.state == 0) ?? [];
    }


    return (
        <div id={(theme == "dark") ? s.dark : s.light} className={s.wrapper}>
            <Nav.Link className={s.navText} onClick={bellClickHandler}>
                <div className={s.labelWrapper}>
                    {
                        getOnlyNewItems()?.length > 0 ?
                            <NotificationNumberCustom count={getOnlyNewItems()?.length} /> :
                            null
                    }
                    <FontAwesomeIcon icon={faBell} />
                </div>
            </Nav.Link>
            {
                showPanel == true ?
                    <div className={s.backgroundWrapper} onClick={backgroundClickHandler}>
                        <NotificationBaseCustom 
                            notifications={notifications} 
                            theme={theme} 
                            onClose={backgroundClickHandler}
                            refresh={loadNotificationsFromServer}
                            />
                    </div>
                    : null
            }
        </div>

    );
}

export default NotificationLabelCustom;