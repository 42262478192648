import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chooseCity, chooseRegion } from '../../../rootSlice.js';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import config from "../../../config/config.json";
import ChooseDouble from '../choose-double/ChooseDouble.jsx';
import { getNextFromInputPath } from '../chooseProcessing/ChooseProcessing.jsx';

export const SelectCity = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const regionCode = useSelector(state => state.order.region.code)
    const cityCode = useSelector(state => state.order.city.code)
    const { register, handleSubmit } = useForm({ defaultValues: { cityCode } })
    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';


    const state = useSelector(state => state)

    var cities = [];
    var options = [];

    const submitClick = (selectedRegion, selectedCity) => {
        dispatch(chooseRegion(selectedRegion));
        dispatch(chooseCity(selectedCity));
        navigate(getNextFromInputPath());
    }

    const getRegionsFromServer = () => {
        const apiUrl = `${url}/api/region/get/all`;
        return fetch(apiUrl)
            .then((response) => response.json())
            .then((responseData) => {
                return responseData;
            })
            .catch(error => console.warn(error));
    }

    const getCitiesFromServer = (regionCode) => {
        const apiUrl = `${url}/api/city/get/region/${regionCode}`;
        return fetch(apiUrl)
            .then((response) => { return response.json() })
    }

    useEffect(() => {
        getRegionsFromServer();
    });

    return (
        <ChooseDouble
            options={options}
            title={"Место"}
            directoryName="Регион"
            subDirectoryName="Город"
            directoryDescription="Выберите регион"
            subDirectoryDescription="Выберите город"
            previous="/"
            next="./select-service"
            chooseFunc={submitClick}
            selectDirectoryFunc={getRegionsFromServer}
            selectSubdirectoryFunc={getCitiesFromServer} />
    )


}

export default SelectCity;