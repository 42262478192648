import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import style from '../../../../special/CommonStyles.module.css';
import TabMain from './tabs/tab-main/TabMain';
import { useParams } from 'react-router-dom';
import TabPhoto from './tabs/tab-photo/TabPhoto';
import TabSchedulle from './tabs/tab-schedulle/TabSchedulle';
import TabSettings from './tabs/tab-settings/TabSettings';
import staffDefault from './StaffDefault';
import { RestCustomService } from 'omni-services-utils';
import { FormCustom } from 'omni-services-common';
import { TabCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';
import TabUserLink from './tabs/tab-user-link/TabUserLink';

export const EditStaff = (props) => {

  const { firmNumber, staffId } = useParams();

  const tabMainRef = useRef(null);
  const tabAddressRef = useRef(null);
  const tabScheduleRef = useRef(null);
  const tabSettingsRef = useRef(null);
  const tabUserLinkRef = useRef(null);


  var [theme, setTheme] = useState();
  var [text, setText] = useState();
  var [editMode, setEditMode] = useState(false);
  var [success, setSuccess] = useState();
  var [error, setError] = useState();

  var [staff, setStaff] = useState();


  const state = useSelector(state => state)

  const options = {
    showSelectFirm: true,
    enableBackButton: true
  };

  RestCustomService.setUrl(ConfigCustomService.getUrl());

  const handleSave = (staffEdit) => {

    if (staffId) {
      console.log("edit");
      RestCustomService.Patch(
        `/staff/owner/edit/${firmNumber}/`,
        staffEdit,
        true,
        (data) => { setSuccess("Сохранение прошло успешно!"); refresh(); },
        (err) => { setError(err); }
      );

    } else {
      console.log("add");
      RestCustomService.Put(
        `/staff/owner/add/${firmNumber}/`,
        staffEdit,
        true,
        (data) => { setSuccess("Сохранение прошло успешно!"); refresh(); },
        (err) => { setError(err); }
      );
    }
  }

  const validation = () => {

    var err = null;

    err = tabMainRef.current.validation();
    if (err) {
      return err;
    }

    return null;
  }

  const handleSubmit = (event) => {

    var tabMain = tabMainRef.current?.getResult();

    var staffEdit = staffDefault;
    staffEdit.firmNumber = firmNumber;

    staffEdit.userName = tabMain.userName;
    staffEdit.positionName = tabMain.positionName;
    
    staffEdit.directions = tabSettingsRef.current.getDirectionsResult();
    staffEdit.settings = tabSettingsRef.current.getDirectionsResult();

    if (tabUserLinkRef.current?.getUnlink()) {

      staffEdit.userId = null;
    } else {
      
      var invitedUser = tabUserLinkRef.current?.getResult();
      staffEdit.userId = staff?.userId ?? invitedUser?.userId;
      staffEdit.userStaffRole = invitedUser?.userStaffRole;
    }
    

    if (staffId) {
      staffEdit.id = staffId;
    }
    handleSave(staffEdit);
  }

  var fillStaff = (data) => {
    //if (staff) return;
    setStaff(data);
  }

  const getStaffById = (id) => {

    RestCustomService.Get(
      `/staff/owner/${firmNumber}/get-by-id/${staffId}`,
      null,
      true,
      (data) => { fillStaff(data); },
      (err) => { console.error(err); })
  }

  const setEditModeFunc = () => {
    setEditMode(!editMode);
  }

  const refresh = () => {

    if (staffId) {
      getStaffById(staffId);
    }
  }

  useEffect(() => {
    refresh();
  }, []);

  useEffect(() => {
    setTheme(props.theme);
    setText(props.text);
    refresh();
  },
    [props.theme, props.text, staffId])

  return (
    <div className={style.formWrapper} id={(state.settings.theme == "dark") ? style.dark : style.light}>
      <FormCustom
        title={"Сотрудник"}
        onSubmit={handleSubmit}
        editMode={editMode}
        setEditMode={setEditModeFunc}
        body={
          <TabCustom
            tabs={[
              { name: 'main', title: 'Основное', body: <TabMain staff={staff} theme={state.settings.theme} editMode={editMode} ref={tabMainRef}/> },
              { name: 'schedulle', title: 'Расписание', body: <TabSchedulle schedule={staff?.schedule} theme={state.settings.theme} editMode={editMode} ref={tabScheduleRef} /> },
              { name: 'settings', title: 'Настройки', body: <TabSettings staff={staff} theme={state.settings.theme} editMode={editMode} directions={staff?.directions} ref={tabSettingsRef} /> },
              { name: 'gallery', title: 'Фото', body: <TabPhoto staff={staff} theme={state.settings.theme} firmNumber={firmNumber} /> },
              { name: 'user', title: 'Пользователь', body: <TabUserLink editMode={editMode} url={ConfigCustomService.getUrl()} staff={staff} theme={state.settings.theme} ref={tabUserLinkRef} /> },
              ]}
            theme={state.settings.theme}
          />
        }
        theme={state.settings.theme}
        success={success}
        error={error}
        options={options}
        validation={validation}
      />

    </div>
  )
}

export default EditStaff;