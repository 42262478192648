import React, { useState, useEffect } from 'react';
import s from './SlotTime.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { chooseSlot, chooseSlotDate } from '../../../rootSlice';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import SlotListItem from './slot-date-list-item/SlotTimeListItem';
import ChooseCommonForm from '../choose-common-form/ChooseCommonForm';
import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';
import { getNextFromInputPath } from '../chooseProcessing/ChooseProcessing';
import SlotTimeListItem from './slot-date-list-item/SlotTimeListItem';
import { FormCustom } from 'omni-services-common';

export const SlotTime = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const base = useSelector(state => state.base)

    const firmNumber = useSelector(state => state.order.firm.number);
    const date = useSelector(state => state.order.slotDate.date);
    const [mounted, setMounted] = useState(false);

    const [slots, setSlots] = useState();

    const { register, handleSubmit } = useForm({ defaultValues: { base } })

    const state = useSelector(state => state)

    var slotItems = [];

    var slotCode = null;

    var options = {
        showSelectFirm: true,
        needToLoggedIn: false,
        enableBackButton: true,
        showFooterButtons: false,
        showMenuButtons: false,
    };

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const submitClick = (selectedValue) => {
        dispatch(chooseSlot(selectedValue));
        navigate(getNextFromInputPath());
    }

    async function getSlotsFromServer() {

        var body = {
            firmNumber: firmNumber,
            date: date,
            workerId: null,
        };

        RestCustomService.Post(
            `/slot/get/free-slots-by-date`,
            body,
            true,
            (data) => {
                fillSlots(data);
            },
            (err) => { })
    }

    useEffect(() => {
        getSlotsFromServer();
    }, [])

    const fillSlots = (data) => {
        setSlots(data);
        for (var i = 0; i < data.length; i++) {
            slotItems.push(<SlotListItem slot={data[i]} />);
        }
    }

    if ((slots != undefined)) {
        return (
            <div>
                <h3 className={s.headerWrapper}>Выберите время: </h3>
                <div className={s.formWrapper} id={state.settings.theme === "dark" ? s.dark : s.light}>
                    <FormCustom
                        title={"Выберите слот"}
                        onSubmit={() => { }}
                        editMode={false}
                        setEditMode={() => { }}
                        refresh={() => { getSlotsFromServer(); }}
                        options={options}
                        body={
                            null
                        }
                        theme={state.settings.theme}
                    />
                </div>
                <div className={s.slotListWrapper}>
                    {
                        slots.map((item) => (
                            <div className={s.slotWrapper} onClick={() => { submitClick(item); }}>
                                <SlotTimeListItem slot={item} />
                            </div>
                        ))
                    }
                </div>
            </div>
        )
    }
}

export default (SlotTime);