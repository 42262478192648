import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './EditParkingTicket.module.css';
import style from '../../../../special/CommonStyles.module.css';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faPerson } from '@fortawesome/free-solid-svg-icons';
import SelectManufacturerModelSmall from '../../../../forms/choose-manufacturer-model/ChooseManufacturerModel';
import SelectServiceSmall from '../../../../forms/choose-service/ChooseService';
import parkingTicketDefault from './ParkingTicketDefault';
import SelectParkingPlace from '../../../choose/select-parking-place/SelectParkingPlace';
import SelectUser from '../../../choose/select-user/SelectUser';
import { useRef } from 'react';
import SelectParkingTicketStatuses from '../../../choose/select-parking-ticket-statuses/SelectParkingTicketStatuses';
import { RestCustomService } from 'omni-services-utils';
import { FormCustom } from 'omni-services-common';
import { InputTextCustom } from 'omni-services-base';
import { DateCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../../services/base/config-custom-service/ConfigCustomService';

export const EditParkingTicket = (props) => {

  const { firmNumber, ticketId } = useParams();
  const selectUserRef = useRef();
  const selectManufacturerRef = useRef();
  const selectServiceRef = useRef();
  const selectParkingPlaceRef = useRef();
  const selectStateRef = useRef();

  var [theme, setTheme] = useState();
  var [text, setText] = useState();
  var [editMode, setEditMode] = useState();
  var [success, setSuccess] = useState();
  var [error, setError] = useState();

  var [parkingTicket, setTicket] = useState();

  var [userName, setUserName] = useState();
  var [userId, setUserId] = useState();

  var [begin, setBegin] = useState();
  var [end, setEnd] = useState();

  var [parkingPlaceNumber, setParkingPlaceNumber] = useState();

  var firms = useSelector(state => state.firms)
  var selectedNumber = useSelector(state => state.firms.selectedNumber ? state.firms.selectedNumber : null)
  var selectedFirmNumber = selectedNumber ?? firms?.list[0]?.number;

  var [parkingArea, setParkingArea] = useState();


  const state = useSelector(state => state)

  const options = {
    readonly: false,
    canRefresh: false,
    canSave: true,
    canDelete: true,
    showMenuButtons: true,
    showFooterButtons: true,
    submitText: "Добавить запись",
    showSelectFirm: true,
    showSelectFirm: true,
    needToLoggedIn: true,
    enableBackButton: true
  };

  RestCustomService.setUrl(ConfigCustomService.getUrl());

  const handleSave = (parkingTicketEdit) => {

    if (ticketId) {

      RestCustomService.Patch(
        `/parkingTicket/owner/edit/${firmNumber}/`,
        parkingTicketEdit,
        true,
        (data) => { setSuccess("Изменения сохранены!"); },
        (err) => { setError(err.detail); }
      );

    } else {

      RestCustomService.Put(
        `/parkingTicket/owner/add/${firmNumber}/`,
        parkingTicketEdit,
        true,
        (data) => { setSuccess("Изменения сохранены!");  },
        (err) => { setError(err.detail); }
      );
    }

  }

  const getParkingAreaFromServer = () => {
    RestCustomService.Get(
        `/parkingarea/owner/get/${firmNumber}`,
        null,
        true,
        (data) => {
            if (data[0]) {
                setParkingArea(data[0]);
            }
        },
        (err) => { console.error(err); })
}

const getDateTimeStr = (dateTime) => {

  if (!dateTime) {
      return null;
  }

  var dateStr = Date.parse(dateTime);
  var date = new Date(dateStr);

  var year = date.getFullYear();
  var month = date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1;
  var day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

  var hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  var minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

  var timezone = -date.getTimezoneOffset()/60 < 10 ? `0${-date.getTimezoneOffset()/60}` : -date.getTimezoneOffset()/60;

  return `${year}-${month}-${day}T${hours}:${minutes}:00.000+${timezone}:00`;
  // return date.toISOString();
}

  const handleSubmit = (event) => {
    var parkingTicketEdit = parkingTicketDefault;

    parkingTicketEdit.id = ticketId;
    parkingTicketEdit.firmNumber = + firmNumber;

    parkingTicketEdit.begin = getDateTimeStr(begin);
    parkingTicketEdit.end = getDateTimeStr(end);

    //parkingTicketEdit.positionName = getInputValueByName(event, "positionName");
    //parkingTicketEdit.userName = getInputValueByName(event, "userName");

    var parkingPlaceObj = selectParkingPlaceRef.current.getResult(); 
    var serv = selectServiceRef.current.getResult();
    var manufact = selectManufacturerRef.current.getResult();
    var usr = selectUserRef.current.getResult();
    var st = selectStateRef.current.getResult();

    parkingTicketEdit.serviceTypeCode = serv?.serviceType;
    parkingTicketEdit.serviceCode = serv?.service;
    parkingTicketEdit.manufacturerCode = manufact?.manufacturerCode;
    parkingTicketEdit.manufacturerModelCode = manufact?.manufacturerModelCode;
    parkingTicketEdit.parkingPlaceNumber = parkingPlaceObj?.parkingPlace;
    parkingTicketEdit.userId = usr.userId;
    parkingTicketEdit.state = st;


    handleSave(parkingTicketEdit);
  }

  var fillTicket = (data) => {
    setTicket(data);
    
    setBegin(DateCustomService.getDateTimeFormattedFullRu(data?.begin))
    setEnd(DateCustomService.getDateTimeFormattedFullRu(data?.end));
    setUserId(data?.userId)
    setParkingPlaceNumber(data?.parkingPlaceNumber);
  }

  const getTicketById = (id) => {
    
    RestCustomService.Get(
      `/parkingTicket/owner/get/${firmNumber}/get-by-id/${ticketId}`,
      null,
      true,
      (data) => { fillTicket(data); },
      (err) => { console.error(err); })
  }

  const setEditModeFunc = () => {
    setEditMode(!editMode);
  }

  useEffect(() => {
    setTheme(props.theme);
    setText(props.text);
    if (ticketId) {
      getTicketById(ticketId);
    }
    getParkingAreaFromServer();
  },
    [props.theme, props.text, ticketId])


  return (
    <div className={style.formWrapper} id={(state.settings.theme == "dark") ? style.dark : style.light}>
      <FormCustom
        title={"Запись"}
        onSubmit={handleSubmit}
        editMode={editMode}
        setEditMode={setEditModeFunc}
        body={
          <div>
            <SelectParkingTicketStatuses selected={parkingTicket?.state} theme={state.settings.theme} ref={selectStateRef} editMode={editMode} />
            <SelectUser selected={parkingTicket?.userId} ref={selectUserRef} editMode={editMode} />
            <InputTextCustom
              theme={state.settings.theme}
              controlId="parkingPlaceDateBegin"
              label="Планируемая дата начала периода стоянки"
              required={true}
              type="datetime-local"
              name="parkingPlaceDateBegin"
              placeholder="Планируемая дата..."
              muted=""
              message={begin}
              readOnly={!editMode}
              setMessage={setBegin}
              leftText={<FontAwesomeIcon icon={faCalendar} />}
            />
            <InputTextCustom
              theme={state.settings.theme}
              controlId="parkingPlaceDateEnd"
              label="Планируемая дата окончания периода стоянки"
              required={true}
              type="datetime-local"
              name="parkingPlaceDateEnd"
              placeholder="Планируемая дата..."
              muted=""
              message={end}
              readOnly={!editMode}
              setMessage={setEnd}
              leftText={<FontAwesomeIcon icon={faCalendar} />}
            />
            <SelectParkingPlace 
              selected={parkingPlaceNumber} 
              parkingArea={parkingArea} 
              theme={state.settings.theme}
              ref={selectParkingPlaceRef}
              editMode={editMode}
              />
            <SelectManufacturerModelSmall
              manufacturerCode={parkingTicket?.manufacturerCode}
              manufacturerModelCode={parkingTicket?.manufacturerModelCode}
              theme={state.settings.theme}
              ref={selectManufacturerRef}
              editMode={editMode}
            />
            <SelectServiceSmall
              serviceTypeCode={parkingTicket?.serviceTypeCode}
              serviceCode={parkingTicket?.serviceCode}
              theme={state.settings.theme}
              ref={selectServiceRef}
              editMode={editMode}
            />
          </div>
        }
        theme={state.settings.theme}
        error={error}
        success={success}
        options={options}
      />

    </div>
  )
}

export default EditParkingTicket;