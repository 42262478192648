import React, { useEffect, useState } from 'react';
import s from './NotificationBaseCustom.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import NotificationItemCustom from './notification-item/NotificationItemCustom';
import { RestCustomService } from 'omni-services-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

export const NotificationBaseCustom = (props) => {

    const dispatch = useDispatch();
    const state = useSelector(state => state)
    const [theme, setTheme] = useState(props.theme);


    const [notifications, setNotifications] = useState([]);

    const clickHandler = (e) => {
        e.stopPropagation();
    }

    const onCloseClickHandler = () => {
        if (props.onClose != undefined) {
            props.onClose();
        }
    }

    useEffect(() => {
        
    }, []);

    useEffect(() => {
        setNotifications(props.notifications);
    }, [props.notifications]);

    useEffect(() => {
        setTheme(props.theme);
    }, [props.theme]);


    return (
        <div 
        id={(theme == "dark") ? s.dark : s.light}
         className={s.backgroundWrapper}
         onClick={clickHandler}>
            <div className={s.wrapper}>
                <div className={s.titleWrapper}>
                    <h5>Уведомления</h5>
                    <div className={s.closeIcon} onClick={onCloseClickHandler}><FontAwesomeIcon icon={faClose} /></div>
                </div>
                <div className={s.bodyWrapper}>
                    {notifications.map((notification, i) => 
                        <NotificationItemCustom 
                            key={i} 
                            notification={notification} 
                            theme={theme}
                            refresh={props.refresh}
                            >
                        </NotificationItemCustom>)} 
                </div>
            </div>

        </div>
    );
}

export default NotificationBaseCustom;