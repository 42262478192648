import React from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import s from './SchedulleDay.module.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CloseButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import DayScheduleObject from './DayScheduleObject';
import { TimeInputCustom } from 'omni-services-base';
import { CheckboxCustom } from 'omni-services-base';

export const SchedulleDay = forwardRef((props, ref) => {

    const state = useSelector(state => state)

    var days = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

    var [isWorking, setIsWorking] = useState();
    var [dayOfWeek, setDayOfWeek] = useState();
    var [breakEnabled, setBreakEnabled] = useState();
    var [beginningTime, setBeginningTime] = useState();
    var [endingTime, setEndingTime] = useState();

    var [brakeTimeBegin, setBrakeTimeBegin] = useState();
    var [brakeTimeEnd, setBrakeTimeEnd] = useState();

    var [editMode, setEditMode] = useState(props.editMode);

    

    var [theme, setTheme] = useState(state.settings.theme)

    useEffect(() => {
        setDayOfWeek(props.weekSchedule.dayOfWeek ?? props.dayNumber);
        setIsWorking(props.weekSchedule.isWorking);
        setBreakEnabled(props.weekSchedule.brakeEnabled);
        setBeginningTime(props.weekSchedule.beginningTime);
        setEndingTime(props.weekSchedule.endingTime);
        setBrakeTimeBegin(props.weekSchedule.brakeTimeBegin);
        setBrakeTimeEnd(props.weekSchedule.brakeTimeEnd);
        setEditMode(props.editMode);

    }, [props.weekSchedule, props.editMode, props.dayNumber])

    useImperativeHandle(ref, () => ({

        validation() {

            if (isWorking) {

                if (!beginningTime) {
                    return `${days[props.dayNumber]}: поле начало рабочего дня не заполнено`;
                }

                if (!endingTime) {
                    return `${days[props.dayNumber]}: поле конец рабочего дня не заполнено`;
                }

                if (beginningTime > endingTime) {
                    return `${days[props.dayNumber]}: Дата начала рабочего дня больше конца рабочего дня`;
                }

                if (breakEnabled) {

                    if (!brakeTimeBegin) {
                        return `${days[props.dayNumber]}: поле начало перерыва не заполнено`;
                    }
    
                    if (!brakeTimeEnd) {
                        return `${days[props.dayNumber]}: поле конец перерыва не заполнено`;
                    }

                    if (brakeTimeBegin < beginningTime) {
                        return `${days[props.dayNumber]}: Дата начала перерыва меньше начала рабочего дня`;
                    }

                    if (brakeTimeBegin > endingTime) {
                        return `${days[props.dayNumber]}: Дата начала перерыва больше конца рабочего дня`;
                    }

                    if (brakeTimeEnd < beginningTime) {
                        return `${days[props.dayNumber]}: Дата начала перерыва меньше начала рабочего дня`;
                    }

                    if (brakeTimeEnd > endingTime) {
                        return `${days[props.dayNumber]}: Дата конца перерыва больше конца рабочего дня`;
                    }
    
                    if (brakeTimeBegin > brakeTimeEnd) {
                        return `${days[props.dayNumber]}: Дата начала перерыва больше конца перерыва`;
                    }
                }
            }

            return null;
        },
        
        getResult() {
            var daySchedule = DayScheduleObject;

            daySchedule.dayOfWeek = dayOfWeek;
            daySchedule.beginningTime = beginningTime;
            daySchedule.endingTime = endingTime;
            daySchedule.brakeTimeBegin = brakeTimeBegin;
            daySchedule.brakeEnabled = breakEnabled;
            daySchedule.brakeTimeEnd = brakeTimeEnd;
            daySchedule.isWorking = isWorking;

            return daySchedule;
        },

    }));

    return (
        <tr className={s.wrapper}>
            <td className={s.tableCell}>
                <CheckboxCustom 
                    label={days[props.dayNumber]} 
                    checked={isWorking} 
                    readOnly={!editMode} 
                    disabled={false} 
                    onChange={() => {setIsWorking(!isWorking)}} 
                    id={`isWorking${dayOfWeek}`} 
                    name={`isWorking${dayOfWeek}`} 
                    />
            </td>

            <td className={s.tableCell}>
                <TimeInputCustom
                    label={"с"} 
                    value={beginningTime}  
                    disabled={!isWorking}  
                    readOnly={!editMode} 
                    name={`begin${dayOfWeek}`} 
                    theme={theme}
                    onChange={setBeginningTime}  
                    />
            </td>
           
            <td className={s.tableCell}>
                <TimeInputCustom 
                    label={"по"} 
                    value={endingTime} 
                    disabled={!isWorking} 
                    readOnly={!editMode}  
                    name={`end${dayOfWeek}`} 
                    theme={theme} 
                    onChange={setEndingTime} 
                    />
            </td>
            <td className={s.tableCell}>
                <CheckboxCustom
                    label={"перерыв"} 
                    checked={breakEnabled}  
                    disabled={!isWorking}  
                    onChange={() => {setBreakEnabled(!breakEnabled)}}  
                    name={`breakEnabled${dayOfWeek}`} 
                    id={`breakEnabled${dayOfWeek}`} 
                    readOnly={!editMode} 
                    
                    />
            </td>
            <td className={s.tableCell}>
                <TimeInputCustom 
                    label={"с"} 
                    value={brakeTimeBegin} 
                    disabled={!breakEnabled} 
                    readOnly={!editMode}  
                    name={`breakBegin${dayOfWeek}`} 
                    theme={theme}
                    onChange={setBrakeTimeBegin} 
                    />
            </td>
            <td className={s.tableCell}>
                <TimeInputCustom 
                    label={"по"} 
                    value={brakeTimeEnd} 
                    disabled={!breakEnabled} 
                    readOnly={!editMode} 
                    name={`breakEnd${dayOfWeek}`} 
                    theme={theme}
                    onChange={setBrakeTimeEnd} 
                    />
            </td>
        </tr>
    );
});

export default SchedulleDay;