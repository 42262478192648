export var FirmInitial = {
    id: "",
    number: 0,
    ownerId: "",
    name: "",
    address: {
        regionCode: 0,
        cityCode: 0,
        locality: null,
        street: null,
        building: null
    },
    accountBalance: 0,
    logo: null,
    directions: {
        serviceTypesCode: [
            1
        ],
        servicesCode: [
            1
        ],
        transportCategoriesCode: [
            1,
            2
        ],
        transportSubcategoriesCode: null,
        manufacturersCode: null,
        manufacturerModelsCode: null
    },
    settings: {
        strategy: 1,
        isEnable: true,
        staffMaxNumber: null,
        accountBalance: null,
        isClosed: null
    },
    schedule: {
        type: 0,
        weekSchedule: [
            {
                dayOfWeek: 0,
                beginningTime: null,
                endingTime: null,
                brakeTimeBegin: null,
                brakeEnabled: null,
                brakeTimeEnd: null,
                brakeDuration: 0,
                isWorking: null
            }
        ],
        complexSchedule: null
    },
    uploadedFiles: [ ],
    rate: 0,
    isEnabled: true,
    isBlocked: false,
    phone: "",
    email: "",
    registerDate: "2023-01-29T17:32:09.7+03:00",
    state: null
};

export default FirmInitial;