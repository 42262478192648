import React from 'react';
import s from './UserMenuItem.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';

export const UserMenuItem = (props) => {

    const dispatch = useDispatch();
    const base = useSelector(state => state.base)

    const state = useSelector(state => state)


    return (
        <div className={s.userMenuWrapper} id={state.settings.theme == "dark" ? s.dark : s.light} onClick={props.onClick}>
            <div className={s.iconWrapper}>{props.icon}</div>
            <div className={s.titleWrapper}>{props.name}</div>
            <div></div>
        </div>
    );
}

export default UserMenuItem;