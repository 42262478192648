import React, { useEffect, useState } from 'react';
import s from './InitialCustom.module.css';
import { useDispatch, useSelector } from 'react-redux';
import config from "../config/config.json";
import { setAccessToken, setIsAuthorized, setOwnersFirms, setSelectedOwnerFirm, setUser } from '../rootSlice';
import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../services/base/config-custom-service/ConfigCustomService';
import { UserProcessingService } from 'omni-services-common';

export const InitialCustom = (props) => {

    const dispatch = useDispatch();

    var [roles, setRoles] = useState([]);
    var [currentUser, setCurrentUser] = useState();

    const state = useSelector(state => state)

    RestCustomService.setUrl(ConfigCustomService.getUrl());


    const fillRoles = () => {

        RestCustomService.Get(
            `/user/get/roles`, 
            null, 
            false, 
            (json) => {
                setRoles(json);
            }, 
            (err) => {}) 
    }

    const fillUser = () => {

        RestCustomService.Get(
            `/user/get`, 
            null, 
            true, 
            (json) => {
                dispatch(setUser(json));
                setCurrentUser(json);
            }, 
            (err) => {console.error(err)}) 
    }

    const fillFirm = (user) => {
        
        if (!user) {
            return;
        }


        if (user?.role?.includes(roles?.find(f => f.value == "OWNER")?.index)) {
            RestCustomService.Get (
                `/firm/owner/get-owners-firms`, 
                null, 
                true, 
                (json) => { dispatch(setOwnersFirms(json)); dispatch(setSelectedOwnerFirm(json[0]?.number)); }, 
                (err) => {})
        }
    }

    useEffect(() => {

        if (roles.length === 0) {
            fillRoles();
        }

        if (roles && (UserProcessingService.hasStoredUser()) && (!currentUser)) {
            fillUser();
        }

        if (UserProcessingService.hasStoredUser()) {
            dispatch(setUser(UserProcessingService.get()));
        }

        if (currentUser) {
            fillFirm(currentUser);
        }
    }, [roles, currentUser]);

    return (
        <div className={s.InitialCustomWrapper}>

        </div>
    );
}

export default InitialCustom;