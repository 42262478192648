import React, { useEffect, useState } from 'react';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPerson, faPhone } from '@fortawesome/free-solid-svg-icons';
import SelectUserObject from './SelectUserObject';
import { RestCustomService } from 'omni-services-utils';
import { InputTextCustom } from 'omni-services-base';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { Form } from 'react-bootstrap';
import { SearchMode } from './SearchMode';
import SelectStaffStatuses from '../select-staff-statuses/SelectStaffStatuses';

export const UserSearchCustom = forwardRef((props, ref) => {

    const selectStaffRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [url, setUrl] = useState(props.url);

    var firms = useSelector(state => state.firms)
    var selectedNumber = useSelector(state => state?.firms?.selectedNumber ?? firms?.list[0]?.number)

    var selectedFirmNumber = selectedNumber;

    const state = useSelector(state => state)

    var [searchMode, setSearchMode] = useState(SearchMode.PHONE);

    var [staffs, setStaffs] = useState([]);
    var [options, setOptions] = useState()

    var [userId, setUserId] = useState(props.selected);
    var [userName, setUserName] = useState();
    var [userPhone, setUserPhone] = useState();
    var [userEmail, setUserEmail] = useState();
    var [userTelegram, setUserTelegram] = useState();

    var [theme, setTheme] = useState(props.theme);
    var [editMode, setEditMode] = useState(props.editMode);

    RestCustomService.setUrl(props.url);

    const submitClick = (type, service) => {

    }

    const fillUserIfExists = (user) => {

        if (!user) {
            setUserName('');
            setUserId(null);
            return;
        }
        console.log(user);
        setUserPhone(user.phone);
        setUserName(user.name);
        setUserId(user.id);
    }

    const handleChangePhone = (val) => {

        setUserPhone(val);

        if (phoneValidation(val) != null) {
            return;
        }

        RestCustomService.Post(
            `/user/get/by-phone/`,
            { phone: val },
            true,
            (data) => { fillUserIfExists(data) },
            (err) => { console.error(err); fillUserIfExists(null) })
    }


    useEffect(() => {

        if (props.selected) {
            RestCustomService.Post(
                `/user/get/by-phone/`,
                { id: props.selected },
                true,
                (data) => { fillUserIfExists(data) },
                (err) => { console.error(err); fillUserIfExists(null) })
        }

        setTheme(props.theme);
        setEditMode(props.editMode)
    }, [props.selected, props.theme, props.editMode]);


    const nameValidation = (text) => {

        if (!text) {
            return "значение обязательно";
        }

        if (text.length == 0) {
            return "значение обязательно";
        }

        if (/^[a-zA-Zа-яА-ЯёЁ\s]*$/.test(text) == false) {
            return "только буквы и пробел";
        }

        return null;
    }

    const phoneValidation = (text) => {

        if (!text) {
            return "значение обязательно";
        }

        if (text.length == 0) {
            return "значение обязательно";
        }

        if (/^[+0-9]{12}$/.test(text) == false) {
            return "формат +11 цифр: +7XXXXXXXXXX";
        }

        return null;
    }

    const emailValidation = (text) => {
        console.error(text);
        if (!text) {
            return "значение обязательно";
        }

        if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(text) == false) {
            return "неверный формат адреса почты";
        }

        return null;
    }

    const changeSearchMode = (searchMode) => {

        setSearchMode(searchMode); 
        setUserEmail(null);
        setUserPhone(null);
        setUserTelegram(null);
        setUserName(null);
    }

    useImperativeHandle(ref, () => ({

        validation() {

            var err = null;

            err = nameValidation(userName);
            if (err) {
                return "поле имя: " + err;
            }

            err = phoneValidation(userPhone);
            if (err) {
                return "поле телефон: " + err;
            }

            return err;
        },

        getResult() {

            var userInvited = SelectUserObject;

            userInvited.userId = userId;
            userInvited.userPhone = userPhone;
            userInvited.userName = userName;
            userInvited.userStaffRole = selectStaffRef.current.getResult();

            console.log(userInvited);

            return userInvited;
        },

    }));

    return (
        <div>
            <Form.Group controlId='formBasicChoose'>
                <Form.Check
                    inline
                    label="Пригласить по телефону"
                    name="choose-type"
                    type='radio'
                    id="usePhone"
                    defaultChecked
                    onChange={() => { changeSearchMode(SearchMode.PHONE); }}
                />
                <Form.Check
                    inline
                    label="Пригласить по почте"
                    name="choose-type"
                    type='radio'
                    id="useEmail"
                    onChange={() => { changeSearchMode(SearchMode.EMAIL); }}
                />
                <Form.Check
                    inline
                    label="Пригласить по Telegram"
                    name="choose-type"
                    type='radio'
                    id="useTelegram"
                    onChange={() => { changeSearchMode(SearchMode.TELEGRAM); }}
                />
            </Form.Group>

            {
                searchMode == SearchMode.PHONE ?
                <InputTextCustom
                    theme={theme}
                    controlId="userName"
                    label="Телефон пользователя"
                    required={false}
                    type="phone"
                    name="userPhone"
                    placeholder="Телефон пользователя..."
                    muted=""
                    message={userPhone}
                    readOnly={!editMode}
                    setMessage={handleChangePhone}
                    leftText={<FontAwesomeIcon icon={faPhone} />}
                    validation={phoneValidation}
                />
                : null
            }
            {
                searchMode == SearchMode.EMAIL ?
                <InputTextCustom
                    theme={theme}
                    controlId="userEmail"
                    label="Почта пользователя"
                    required={false}
                    type="email"
                    name="userEmail"
                    placeholder="Почта пользователя..."
                    muted=""
                    message={userPhone}
                    readOnly={!editMode}
                    setMessage={handleChangePhone}
                    leftText={<FontAwesomeIcon icon={faEnvelope} />}
                    validation={phoneValidation}
                />
                : null
            } 
            {
                searchMode == SearchMode.TELEGRAM ?
                <InputTextCustom
                    theme={theme}
                    controlId="userTelegram"
                    label="Telegram пользователя"
                    required={false}
                    type="text"
                    name="userTelegram"
                    placeholder="Telegram пользователя..."
                    muted=""
                    message={userTelegram}
                    readOnly={!editMode}
                    setMessage={handleChangePhone}
                    leftText={<FontAwesomeIcon icon={faTelegram} />}
                    validation={phoneValidation}
                />
                : null
            }
            <InputTextCustom
                theme={theme}
                controlId="userName"
                label="Имя пользователя"
                required={false}
                type="text"
                name="userName"
                placeholder="Имя пользователя..."
                muted=""
                message={userName}
                readOnly={true}
                setMessage={setUserName}
                leftText={<FontAwesomeIcon icon={faPerson} />}
                validation={nameValidation}
            />
            <SelectStaffStatuses
                    theme={theme}
                    readOnly={!editMode}
                    url={url}
                    ref={selectStaffRef}
                />
        </div>
    )


});

export default UserSearchCustom;