import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import s from './LeftSideMenuItem.module.css';
import style from "../../../special/CommonStyles.module.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import LeftSideMenuSubItem from './left-side-menu-sub-item/LeftSideMenuSubItem';


export const LeftSideMenuItem = (props) => {

  const inputRef = useRef(null);

  const state = useSelector(state => state)

  var [value, setValue] = useState();
  var [theme, setTheme] = useState();
  var [isExpanded, setIsExpanded] = useState();
  var [hideOnSmall, setHideOnSmall] = useState(props.hideOnSmall);
  
  const handleChange = (event) => {
    props.setMessage(event.target.value);
    setValue(event.target.value);
  };

  useEffect(() => {
    setValue(props.message);
    setTheme(props.theme);
    setIsExpanded(props.isExpanded);
    setHideOnSmall(props.hideOnSmall);
  }, 
  [props.message, props.theme, props.isExpanded, props.showOnSmall]);

  const getHideOnSmallClass = () => {

    if (hideOnSmall) {
      return s.firstWrapper;
    }

    return null;
  }

  return (
    <div id={(state.settings.theme == "dark") ? s.dark : s.light} className={`${s.wrapper} ${getHideOnSmallClass()}`}  onClick={props.click}>
        <div className={s.iconWrapper}><FontAwesomeIcon icon={props.icon} /></div>
        {(isExpanded == true) ? <div className={s.titleWrapper}>{props.title}</div> : ''}
        {/*<LeftSideMenuSubItem />*/}
    </div>
  )
}

export default LeftSideMenuItem;