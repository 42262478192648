export const getFromPlacePath = () => {

    return "./../select-service";
}

export const getFromServicePath = () => {

    return "./../select-model";
}

export const getFromTransportPath = () => {

    return "./../select-firm";
}

export const getFromFirmPath = (firm) => {
    
    console.log(firm);
    
    if (!firm) {
        return null;
    }

    if (!firm.settings) {
        return null
    }

    if (firm.settings.appointmentStrategy == 0) {
        return "/ticket"
    }

    if (firm.settings.appointmentStrategy == 1) {
        return "/slot-date"
    }

    if (firm.settings.appointmentStrategy == 2) {
        return "/staff"
    }

    return "";
}

export const getFromSlotDatePath = () => {

    return "/slot-time";
}

export const getFromSlotTimePath = () => {

    return "/ticket";
}

export const getNextFromInputPath = () => {

    var path = window.location.pathname;

    if (path == "/select-city") {
        return getFromPlacePath();
    }

    if (path == "/select-service") {
        return getFromServicePath();
    }

    if (path == "/select-model") {
        return getFromTransportPath();
    }

    if (path == "/select-model") {
        return getFromTransportPath();
    }

    if (path == "/select-firm") {
        return getFromFirmPath();
    }

    if (path == "/slot-date") {
        return getFromSlotDatePath();
    }

    if (path == "/slot-time") {
        return getFromSlotTimePath();
    }

    return "/";
}