import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import s from './UserTickets.module.css';
import UserTicketsCard from './user-tickets-card/UserTicketsCard';
import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';

export const UserTickets = (props) => {

  var [theme, setTheme] = useState();
  var [text, setText] = useState();
  var [editMode, setEditMode] = useState(false);
  var [error, setError] = useState();

  const state = useSelector(state => state)

  var [tickets, setTickets] = useState([]);

  RestCustomService.setUrl(ConfigCustomService.getUrl());

  const handleSubmit = (event) => {

  }

  const setEditModeFunc = () => {
    setEditMode(!editMode);
  }

  const getAllTicketByAdmin = () => {

    RestCustomService.Get(
      `/ticket/user/get-all`,
      null, 
      true, 
      (data) => { fillTickets(data); }, 
      (err) => { console.error(err); })
  }

  const fillTickets = (data) => {

    setTickets([]);
    var st = [];
    for (var i = 0; i < data?.length; i++) {
      st.push(data[i]);
    }
    setTickets(st);
  }

  useEffect(() => {
    setTheme(props.theme);
    setText(props.text);
    getAllTicketByAdmin();
  }, 
  [props.theme, props.text])

  return (
    <div >
        <h2>Мои записи</h2>
        <div className={s.slotListWrapper}>
        {
            tickets.map((item) => (
                <UserTicketsCard ticket={item} />
            ))
          }
        </div>
    </div>
  )
}

export default UserTickets;