import React, { useEffect, useState } from 'react';
import s from './AuthContainerLabel.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCalendar, faCalendarAlt, faSignIn } from '@fortawesome/free-solid-svg-icons';
import { Nav } from 'react-bootstrap';
import AuthForm from '../../../../user/auth/AuthForm';

export const AuthContainerLabel = (props) => {

    const TIME_STEP = 10000;

    const dispatch = useDispatch();
    const state = useSelector(state => state)

    const [theme, setTheme] = useState(props.theme);

    var [showPanel, setShowPanel] = useState();


    const [countOfNew, setCountOfNew] = useState(null);


    const showAuthForm = () => {
        setShowPanel(true);
    }

    const backgroundClickHandler = () => {
        setShowPanel(false);
    }

    const clickHandler = (e) => {
        e.stopPropagation();
    }

    useEffect(() => {
        setTheme(props.theme);
    },
        [props.theme]);



    return (
        <div id={(theme == "dark") ? s.dark : s.light} className={s.wrapper}>
            <Nav.Link className={s.navText} onClick={showAuthForm}>
                <div className={s.labelWrapper}>
                    <FontAwesomeIcon icon={faSignIn} />
                </div>
            </Nav.Link>
            {
                showPanel == true ?
                    <div className={s.backgroundWrapper} onClick={backgroundClickHandler}>
                        <AuthForm onClick={clickHandler} />
                    </div>
                    : null
            }
        </div>

    );
}

export default AuthContainerLabel;