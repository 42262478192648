import React, { useEffect, useState } from 'react';
import s from './ServiceLink.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const ServiceLink = (props) => {
    const state = useSelector(state => state);

    var [color, setColor] = useState();

    useEffect(() => {
        setColor(props.color);
    }, [
        props.color
    ]);

    const getColorClass = (colorName) => {

        if (state.settings.theme != "dark") {
            return null;
        }

        switch (colorName) {
            case "green":
              return s.green;
            case "orange":
              return s.orange;
            case "blue":
              return s.blue;
            case "red":
              return s.red;
              case "pink":
              return s.pink;
              case "yellow":
              return s.yellow;
            default:
              return s.blue;
          }
    }
    return (
        <div className={`${s.serviceLinkWrapper} ${getColorClass(color)}`} id={state.settings.theme == "dark" ? s.dark: s.light}>
            <Nav.Link className={s.navText} as={Link} to={props.link}>
                <div className={s.iconWrapper}>
                    <FontAwesomeIcon icon={props.serviceIcon} />
                </div>
                <div className={s.nameWrapper}>
                    <h5>
                        {props.serviceName}
                    </h5>
                    <p>(Скоро открытие!)</p>
                </div>
            </Nav.Link>
        </div>
    )
}

export default ServiceLink;