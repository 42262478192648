import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import s from './SlotDateListItem.module.css';

export const SlotDateListItem = (props) => {
    const state = useSelector(state => state);
    var [date, setDate] = useState(props.date);
    
    

    const getFormattedDate = (dateTime) => {

        var optionsDate = { year: 'numeric', month: 'numeric', day: 'numeric' };

        if (dateTime == null) {
            return null;
        }

        return new Date(date ?? null).toLocaleDateString("ru-RU", optionsDate);
    } 

    const getFormattedDayOfWeek = (dateTime) => {

        var optionsDate = { weekday: 'long' };

        if (dateTime == null) {
            return null;
        }

        return new Date(date ?? null).toLocaleDateString("ru-RU", optionsDate);
    }

    const getFormattedTime = (dateTime) => {

        var optionsTime = { hour12: false, hour:'2-digit', minute:'2-digit' };

        if (dateTime == null) {
            return null;
        }

        return new Date(date ?? null).toLocaleTimeString("ru-RU", optionsTime);
    } 

    const getFormattedDuration = (duration) => {

        if (duration == null) {
            return null;
        }

        if (duration < 60) {
            return `${duration} мин.`;
        } 

        var hours = Math.floor(duration / 60);
        var minutes = duration % 60;

        if (minutes == 0) {
            return `${hours} ч.`;
        }

        return `${hours} ч. ${minutes} м.`;
    } 

    return (
        <div className={s.wrapper}  id={state.settings.theme == "dark" ? s.dark: s.light}>
            <div className={s.descriptionWrapper}>
                <div>{ getFormattedDate(date) ?? "Ошибка даты" }&nbsp;</div>
                <div>{ `(${getFormattedDayOfWeek(date) ?? "Ошибка даты"})`}</div>
            </div>
        </div>
    )
}

export default (SlotDateListItem);