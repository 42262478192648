import './App.css';
import './../node_modules/omni-services-base/dist/index.css';
import './../node_modules/omni-services-utils/dist/index.css';
import './../node_modules/omni-services-common/dist/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Router, Routes, Route } from "react-router-dom";
import FooterCustom from './main-ui/footer/FooterCustom';
import AutoService from './auto-service/AutoService';
import { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import ErrorCustom from './main-ui/error/errorCustom';
import AuthForm from './user/auth/AuthForm';
import Done from './done/Done';
import UserMenu from './user/menu/UserMenu';
import RequestNewPassword from './user/password/Request/RequestNewPassword';
import ChangePassword from './user/password/Change/ChangePassword';
import SelectModel from './auto-service/choose/selectModel/SelectModel';
import SelectService from './auto-service/choose/selectService/SelectService';
import ServiceType from './auto-service/choose/service-type/ServiceType';
import Ticket from './auto-service/choose/ticket/Ticket';
import Firm from './auto-service/choose/firm/Firm';
import Slot from './auto-service/choose/slot/Slot';
import {UserForm} from './auto-service/forms/user/UserForm';
import RegisterFirmForm from './auto-service/forms/firm/register-firm/RegisterFirmForm';
import SelectCity from './auto-service/choose/selectCity/SelectCity';
import EditFirmForm from './auto-service/forms/firm/edit-firm/EditFirmForm';
import ManageSlot from './auto-service/forms/slot/manage-slot/ManageSlot';
import InitialCustom from './initial-custom/InitialCustom';
import LeftSideMenu from './auto-service/left-side-menu/LeftSideMenu';
import EditStaff from './auto-service/forms/staff/edit-staff/EditStaff';
import AuthFormCustom from './user/auth-form/AuthFormCustom';
import ManageStaff from './auto-service/forms/staff/manage-staff/ManageStaff';
import ManageTicket from './auto-service/forms/ticket/manage-ticket/ManageTicket';
import EditTicket from './auto-service/forms/ticket/edit-ticket/EditTicket';
import ParkingArea from './auto-service/parking-area/ParkingArea';
import SelectParkingDateInterval from './auto-service/choose/select-parking-date/SelectParkingDateInterval';
import ParkingTicket from './auto-service/choose/parking-ticket/ParkingTicket';
import ManageParkingArea from './auto-service/forms/parking-area/manage-parking-area/ManageParkingArea';
import Settings from './main-ui/settings/Settings';
import ManageParkingTicket from './auto-service/forms/parking-ticket/manage-parking-ticket/ManageParkingTicket';
import EditParkingTicket from './auto-service/forms/parking-ticket/edit-parking-ticket/EditParkingTicket';
import UserTickets from './auto-service/forms/user-tickets/UserTickets';
import AuthFormFast from './user/auth-form-fast/AuthFormFast';
import ConfirmByEmail from './user/confirm-by-email/ConfirmByEmail';
import ToFirmTicket from './auto-service/choose/firm-customer/ToFirmTicket';
import ConfirmEmailCustom from './user/confirm-email/ConfirmEmailCustom';
import ConfirmPhoneCustom from './user/confirm-phone/ConfirmPhoneCustom';
import ChangeRestoredPasswordPhone from './user/password/change-restored-password-phone/ChangeRestoredPasswordPhone';
import NavbarCustom from './main-ui/navbar/NavbarCustom';
import AboutCustom from './main-ui/about/AboutCustom';
import Customer from './main-ui/customer/Customer';
import MainCustom from './main-ui/main/MainCustom';
import MainCarousel from './main-ui/main/MainCarousel/MainCarousel';
import { ConfigCustomService } from './services/base/config-custom-service/ConfigCustomService';
import RegisterForm from './user/register/RegisterForm';
import SlotDate from './auto-service/choose/slot-date-time/SlotDate';
import SlotTime from './auto-service/choose/slot-time/SlotTime';
import FirmMap from './auto-service/choose/firm-map/FirmMap';
import DocumentsCustom from './main-ui/documents/DocumentsCustom';
import ShowTicket from './auto-service/forms/ticket/show-ticket/ShowTicket';

const ThemeContext = createContext(null);

function App() {
  const state = useSelector(state => state);

  return (
    <div>
      <InitialCustom />
      <BrowserRouter>
          <div className="app-wrapper" id={state.settings.theme}>
            <NavbarCustom></NavbarCustom>
            <UserMenu />
              <Routes>
                <Route path="/select-city" element={<div className='workAreaWrapper'><SelectCity /></div>} />
                <Route path="/service-type" element={<div className='workAreaWrapper'><ServiceType /></div>} />
                <Route path="/select-service/" element={<div className='workAreaWrapper'><SelectService /></div>} />
                <Route path="/select-model" element={<div className='workAreaWrapper'><SelectModel /></div>} />
                <Route path="/select-firm-map" element={<div className='workAreaWrapperExtraLarge'><FirmMap /></div>} />
                <Route path="/select-firm" element={<div className='workAreaWrapper'><Firm /></div>} />
                <Route path="/to-firm/:firmNumber/" element={<div className='workAreaWrapper'><ToFirmTicket/></div>} />
                <Route path="/slot-date" element={<div className='workAreaWrapper'><SlotDate /></div>} />
                <Route path="/slot-time" element={<div className='workAreaWrapper'><SlotTime /></div>} />
                <Route path="/ticket" element={<div className='workAreaWrapper'><Ticket /></div>} />
                <Route path="/parking-ticket" element={<div className='workAreaWrapper'><ParkingTicket /></div>} />
                <Route path="/register" element={<div className='workAreaWrapper'><RegisterForm url={ConfigCustomService.getUrl()}/></div>} />
                <Route path="/register/confirm/email" element={<div className='workAreaWrapper'><ConfirmEmailCustom /></div>} />
                <Route path="/register/confirm/phone" element={<div className='workAreaWrapper'><ConfirmPhoneCustom /></div>} />
                <Route path="/restore-password/request" element={<div className='workAreaWrapper'><RequestNewPassword /></div>} />
                <Route path="/restore-password/change/:code" element={<div className='workAreaWrapper'><ChangePassword /></div>} />
                <Route path="/change-restored-password-phone" element={<div className='workAreaWrapper'><ChangeRestoredPasswordPhone /></div>} />
                <Route path="/customer" element={<div className='workAreaWrapper'><Customer /></div>} />
                <Route path="/documents" element={<div className='workAreaWrapper'><DocumentsCustom /></div>} />
                <Route path="/about" element={<div className='workAreaWrapper'><AboutCustom /></div>} />
                <Route path="/done" element={<div className='workAreaWrapper'><Done /></div>} />
                <Route path="/user" element={<div className='workAreaWrapper'><UserForm /></div>} />
                <Route path="/user/confirm/:code" element={<div className='workAreaWrapper'><ConfirmByEmail /></div>} />
                <Route path="/user-tickets" element={<div className='workAreaWrapper'><UserTickets /></div>} />
                <Route path="/auth" element={<div className='workAreaWrapper'><AuthForm /></div>} />
                <Route path="/register-firm" element={<div className='workAreaWrapper'><RegisterFirmForm /></div>}/>
                <Route path="/firm/:firmNumber/edit-firm" element={<div className='workAreaWrapper'><EditFirmForm /></div>}/>
                <Route path="/firm/:firmNumber/manage-staff" element={<div className='workAreaWrapperLarge'><ManageStaff /></div>}/>
                <Route path="/firm/:firmNumber/edit-staff/" element={<div className='workAreaWrapper'><EditStaff /></div>}/>
                <Route path="/firm/:firmNumber/edit-staff/:staffId" element={<div className='workAreaWrapper'><EditStaff /></div>}/>
                <Route path="/firm/:firmNumber/manage-slot" element={<div className='workAreaWrapper'><ManageSlot /></div>} />
                <Route path="/firm/:firmNumber/manage-ticket" element={<div className='workAreaWrapperLarge'><ManageTicket /></div>} />
                <Route path="/firm/:firmNumber/edit-ticket/" element={<div className='workAreaWrapper'><EditTicket /></div>} />
                <Route path="/firm/:firmNumber/edit-ticket/:ticketId" element={<div className='workAreaWrapper'><EditTicket /></div>} />
                <Route path="/edit-ticket-user/:ticketId" element={<div className='workAreaWrapper'><ShowTicket /></div>} />
                <Route path="/parking-area" element={<div className='workAreaWrapper'><ParkingArea /></div>}/>
                <Route path="/parking-date-interval" element={<div className='workAreaWrapper'><SelectParkingDateInterval /></div>}/> 
                <Route path="/firm/:firmNumber/manage-parking-area" element={<div className='workAreaWrapper'><ManageParkingArea /></div>}/>
                <Route path="/firm/:firmNumber/manage-parking-ticket" element={<div className='workAreaWrapperLarge'><ManageParkingTicket /></div>}/>

                <Route path="/firm/:firmNumber/edit-parking-ticket/" element={<div className='workAreaWrapper'><EditParkingTicket /></div>} />
                <Route path="/firm/:firmNumber/edit-parking-ticket/:ticketId" element={<div className='workAreaWrapper'><EditParkingTicket /></div>} />

                <Route path="/settings" element={<div className='workAreaWrapper'><Settings /></div>}/>
                <Route exact path="/" element={<div><MainCarousel theme={state.settings.theme} /></div>} />
              </Routes>
              
            <FooterCustom></FooterCustom>
            <AuthFormFast />
            <ErrorCustom />
            <LeftSideMenu />
          </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
