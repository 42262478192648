import React, { useEffect, useState } from 'react';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import ChooseSimple from '../choose-simple/ChooseSimple';
import { useDispatch, useSelector } from 'react-redux';
import { chooseCity, chooseRegion, chooseService, chooseServiceType } from '../../../rootSlice';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import config from "../../../config/config.json";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPerson, faPhone } from '@fortawesome/free-solid-svg-icons';
import SelectUserObject from './SelectUserObject';
import { RestCustomService } from 'omni-services-utils';
import { InputTextCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';

export const SelectUser = forwardRef((props, ref) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';

    var firms = useSelector(state => state.firms)
    var selectedNumber = useSelector(state => state?.firms?.selectedNumber ?? firms?.list[0]?.number)

    var selectedFirmNumber = selectedNumber;

    const state = useSelector(state => state)

    var [staffs, setStaffs] = useState([]);
    var [options, setOptions] = useState()

    var [userId, setUserId] = useState(props.selected);
    var [userName, setUserName] = useState();
    var [userPhone, setUserPhone] = useState();
    var [theme, setTheme] = useState(props.theme);
    var [editMode, setEditMode] = useState(props.editMode);

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const submitClick = (type, service) => {
        
    }

    const fillUserIfExists = (user) => {

        if (!user) {
            setUserName(''); 
            setUserId(null);
            return;
        }

        setUserPhone(user.phone); 
        setUserName(user.name); 
        setUserId(user.id);
    }

    const handleChangePhone = (val) => {

        setUserPhone(val);

        if (phoneValidation(val) != null) {
            return;
        }

        RestCustomService.Post(
            `/user/get/by-phone/`,
            {phone: val},
            true,
            (data) => {  fillUserIfExists(data) },
            (err) => { console.error(err);fillUserIfExists(null) })
    }


    useEffect(() => {

      if (props.selected) {
        RestCustomService.Post(
          `/user/get/by-phone/`,
          {id: props.selected},
          true,
          (data) => {  fillUserIfExists(data) },
          (err) => { console.error(err);fillUserIfExists(null) })
      }

      setTheme(props.theme);
      setEditMode(props.editMode)
    }, [props.selected, props.theme, props.editMode]);


    const nameValidation = (text) => {

        if (!text) {
          return "значение обязательно";
        }
    
        if (text.length == 0) {
          return "значение обязательно";
        }
    
        if (/^[a-zA-Zа-яА-ЯёЁ\s]*$/.test(text) == false) {
          return "только буквы и пробел";
        }
    
        return null;
      }
    
      const phoneValidation = (text) => {
    
        if (!text) {
          return "значение обязательно";
        }
    
        if (text.length == 0) {
          return "значение обязательно";
        }
    
        if (/^[+0-9]{12}$/.test(text) == false) {
          return "формат +11 цифр: +7XXXXXXXXXX";
        }
    
        return null;
      }
    
      const emailValidation = (text) => {
        console.error(text);
        if (!text) {
          return "значение обязательно";
        }
    
        if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(text) == false) {
          return "неверный формат адреса почты";
        }
    
        return null;
      }
    
      

    useImperativeHandle(ref, () => ({

        validation() {
    
            var err = null;
        
            err = nameValidation(userName);
            if (err) {
              return "поле имя: " + err;
            }
        
            err = phoneValidation(userPhone);
            if (err) {
              return "поле телефон: " + err;
            }
        
            return err;
        },
        
        getResult() {
            
            var user = SelectUserObject;

            user.userId = userId;
            user.userPhone = userPhone;
            user.userName = userName;

            return user;
        },

    }));

    return (
        <div>
            <InputTextCustom
              theme={state.settings.theme}
              controlId="userName"
              label="Телефон клиента"
              required={false}
              type="phone"
              name="userPhone"
              placeholder="Телефон клиента..."
              muted=""
              message={userPhone}
              readOnly={!editMode}
              setMessage={handleChangePhone}
              leftText={<FontAwesomeIcon icon={faPhone} />}
              validation={phoneValidation}
            />
            <InputTextCustom
              theme={state.settings.theme}
              controlId="userName"
              label="Имя клиента"
              required={false}
              type="text"
              name="userName"
              placeholder="Имя клиента..."
              muted=""
              message={userName}
              readOnly={!editMode}
              setMessage={setUserName}
              leftText={<FontAwesomeIcon icon={faPerson}/>}
              validation={nameValidation}
            />
        </div>
    )


});

export default SelectUser;