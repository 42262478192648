import { RestCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService.jsx';
import { UserProcessingService } from 'omni-services-common';


export const FirmService = {

    // USER 

    registerByUser: function () {

    },

    // WORKER

    getByWorker: function (onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        RestCustomService.Get(
            `/firm/worker/get`, 
            null, 
            true, 
            (data) => { onSuccess(data); }, 
            (err) => {onError(err);}
            );

    },

    // MANAGER

    getByManager: function (onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        RestCustomService.Get(
            `/firm/manager/get`, 
            null, 
            true, 
            (data) => { onSuccess(data); }, 
            (err) => {onError(err);}
            );

    },

    updateByManager: function (firmEdit, onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        RestCustomService.Patch(
            `/firm/manager/edit/`, 
            firmEdit, 
            true, 
            (data) => { onSuccess(data); }, 
            (err) => {onError(err);}
            );
    },

    // OWNER

    getByOwner: function (selectedFirmNumber, onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        RestCustomService.Get(
            `/firm/owner/get/${selectedFirmNumber}`, 
            null, 
            true, 
            (data) => { onSuccess(data); }, 
            (err) => {onError(err);}
            );

    },

    getAllOwnersFirm: function() {

    },

    updateByOwner: function (firmEdit, onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        console.log(firmEdit);
        RestCustomService.Patch(
            `/firm/owner/edit/`, 
            firmEdit, 
            true, 
            (data) => { onSuccess(data); }, 
            (err) => {onError(err);}
            );
    },

    deleteByOwner: function (firmNumber, onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        console.log("^^^^^^^^^^^^");

        RestCustomService.Delete(
            `/firm/owner/delete/${firmNumber}`, 
            null, 
            true, 
            (data) => { console.log("^^^^^^^^^^^^"); onSuccess(data); }, 
            (err) => {onError(err);}
        );
        
    },

    logoAddOwner: function () {

    },

    logoDeleteOwner: function () {

    },

    imageAddOwner: function () {

    },

    imageDeleteOwner: function () {

    },

    // ODMEN =)

    updateByAdmin: function (firmEdit, onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());

        console.log(firmEdit);
        RestCustomService.Patch(
            `/firm/admin/update/`, 
            firmEdit, 
            true, 
            (data) => { onSuccess(data); }, 
            (err) => {onError(err);}
            );
    },

    addByAdmin: function () {

    },

    getManyByAdmin: function () {

    },

    getByAdmin: function (selectedFirmNumber, onSuccess, onError) {

        RestCustomService.setUrl(ConfigCustomService.getUrl());
        console.log(`/firm/admin/get/${selectedFirmNumber}`);
        RestCustomService.Get(
            `/firm/admin/get/${selectedFirmNumber}`, 
            null, 
            true, 
            (data) => { onSuccess(data); }, 
            (err) => {onError(err);}
            );

    },

    deleteByAdmin: function () {

    },

    get: function (selectedFirmNumber, onSuccess, onError) {

        if (UserProcessingService.hasAdminAccess()) {
            console.log("admin", selectedFirmNumber);
            this.getByAdmin(selectedFirmNumber, onSuccess, onError);
        } else if (UserProcessingService.hasOwnerAccess()) {

            this.getByOwner(selectedFirmNumber, onSuccess, onError);
        } else if (UserProcessingService.hasManagerAccess()) {

            this.getByManager(onSuccess, onError);
        } else if (UserProcessingService.hasWorkerAccess()) {

            this.getByWorker(onSuccess, onError)
        } else {
            
        }
    },


    update: function (firmEdit, onSuccess, onError) {

        if (UserProcessingService.hasAdminAccess()) {
            
            this.updateByAdmin(firmEdit, onSuccess, onError);
        } else if (UserProcessingService.hasOwnerAccess()) {

            this.updateByOwner(firmEdit, onSuccess, onError);
        } else if (UserProcessingService.hasManagerAccess()) {

            this.updateByManager(firmEdit, onSuccess, onError);
        }
    },
}