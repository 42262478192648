import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleArrows,  } from '@fortawesome/free-solid-svg-icons';
import {forwardRef, useImperativeHandle, useRef} from 'react';
import { RestCustomService } from 'omni-services-utils';
import { SelectCustomSearch } from 'omni-services-base';
import { faUpwork } from '@fortawesome/free-brands-svg-icons';

export const SelectStaffStatuses = forwardRef((props, ref)=> {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [url, setUrl] = useState(props.url);

    var [options, setOptions] = useState()
    var [selected, setSelected] = useState(props.selected);
    var [theme, setTheme] = useState(props.theme);

    RestCustomService.setUrl(url);


    const submitClick = (type, service) => {
    }


    const getStatusesFromServer = () => {
        RestCustomService.Get(
                `/staff/get-staff-statuses`,
                null,
                true,
                (data) => { fillStatuses(data) },
                (err) => { console.error(err); })
        
    }

    const fillStatuses = (data) => {

        var opts = [];
        for (var i = 0; i < data.length; i++) {
            var opt = { key: data[i].value, label: data[i].description, value: data[i].index };
            opts.push(opt);
        }

        setOptions(opts);
    }

    useEffect(() => {
        getStatusesFromServer();
        setSelected(props.selected);
        setTheme(props.theme);
        setUrl(props.url);
    }, [
        props.selected, 
        props.theme, 
        props.url
    ]);

    useImperativeHandle(ref, () => ({

        validation() {

            var err = null;

            if (!selected) {
                
                err = "Не выбран статус";
                return err;
            }
    
            return null;
        },
        
        getResult() {
            return selected;
        },

      }));

    return (
        <SelectCustomSearch
            options={options}
            title={"Статус"}
            name="choosedSlot"
            selected={selected}
            leftText={<FontAwesomeIcon icon={faPeopleArrows}/>}
            theme={theme}
            setSelectedValue={setSelected}
        />
    )


});

export default SelectStaffStatuses;