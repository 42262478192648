import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import s from './ChangePassword.module.css';
import { useState } from 'react';
import { setDone } from '../../../rootSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import { RestCustomService } from 'omni-services-utils';
import { ChangePasswordCommon, FormCustom } from 'omni-services-common';
import { InputTextCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';

function ChangePassword(props) {

    const dispatch = useDispatch();
    const state = useSelector(state => state);

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const onSuccess = () => {
        dispatch(setDone({
            title: "Пароль изменен!",
            description: "Пароль был успешно изменен",
            link: "/auth",
            linkText: "Авторизация"
        }));
    }

    return (
        <div className={s.formWrapper} id={state.settings.theme === "dark" ? s.dark : s.light}>
            <ChangePasswordCommon
                theme={state.settings.theme}
                url={ConfigCustomService.getUrl()}
                onSuccess={onSuccess}
            />
        </div>
    );
}

export default ChangePassword;