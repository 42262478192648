import React, { useEffect, useState } from 'react';
import ChooseSimple from '../choose-simple/ChooseSimple';
import { useDispatch, useSelector } from 'react-redux';
import { chooseCity, chooseRegion, chooseService, chooseServiceType } from '../../../rootSlice';
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import config from "../../../config/config.json";
import ChooseDouble from '../choose-double/ChooseDouble';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { RestCustomService } from 'omni-services-utils';
import { SelectCustomSearch } from 'omni-services-base';
import { DateCustomService } from 'omni-services-utils';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';

export const SelectSlotTimeToFirm = forwardRef((props, ref) => {

    const { firmNumber } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const url = config.filter(item => item.name == 'auto-services-api')[0]?.data ?? '';

    var firms = useSelector(state => state.firms)
    var selectedNumber = useSelector(state => state?.firms?.selectedNumber ?? firms?.list[0]?.number)

    var selectedFirmNumber = firmNumber;

    const state = useSelector(state => state)

    var [slots, setSlots] = useState([]);
    var [options, setOptions] = useState()
    var [optionsTime, setOptionsTime] = useState();
    var [selected, setSelected] = useState(props.selected ?? null);
    var [selectedTime, setSelectedTime] = useState();
    var [theme, setTheme] = useState(props.theme);

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const submitClick = (type, service) => {
        dispatch(chooseServiceType(type));
        dispatch(chooseService(service));
        navigate("./../select-model");
    }

    const getSlotsFromServer = (selected) => {

        if (selected) {
            RestCustomService.Get(
                `/slot/get/many/${selectedFirmNumber}`,
                null,
                true,
                (data) => { fillSlotsDate(data); },
                (err) => { console.error(err); })
        } else {
            RestCustomService.Get(
                `/slot/get/many/${selectedFirmNumber}`,
                null,
                true,
                (data) => { fillSlotsDate(data); },
                (err) => { console.error(err); })
        }
    }

    const fillSlotsDate = (data) => {

        if (!data) {
            return;
        }

        setSlots([]);
        var opts = [];
        for (var i = 0; i < data.length; i++) {
            var opt = { key: data[i].id, label: DateCustomService.getDateFormattedDateOnlyRu(data[i].begin), value: data[i].id?.toString() };

            if (opts.some(s => s.label == DateCustomService.getDateFormattedDateOnlyRu(data[i].begin)) == false ) {
                opts.push(opt);
            }
        }
        setOptions(opts);
        console.log(data);
        setSlots(data);
    }

    const fillSlotsTime = (id) => {

        if ((!slots) || (slots.length == 0)) {
            return;
        }

        var choosedSlot = slots.find(s => s.id == id);
        var opts = [];
        for (var i = 0; i < slots.length; i++) {
            var opt = { key: slots[i].id, label: DateCustomService.getDateFormattedTimeOnlyRu(slots[i].begin), value: slots[i].id?.toString() };

            if (DateCustomService.getDateFormattedDateOnlyRu(slots[i].begin) == DateCustomService.getDateFormattedDateOnlyRu(choosedSlot.begin)) 
            {
                opts.push(opt);
            }
        }
        setOptionsTime(opts);
    }

    useEffect(() => {
        getSlotsFromServer(props.selected);
        setSelected(props.selected);
        setTheme(props.theme);

    }, [props.selected, props.theme, props.options]);

    useImperativeHandle(ref, () => ({

        validation() {

            var err = null;

            return err;
        },

        getResult() {
            return selected;
        },

    }));

    const handleSelected = (id) => {

        if (selected != id) {
            setOptionsTime([]);
            setSelectedTime(null);
        }
        setSelected(id);
        fillSlotsTime(id);
    }

    const handleSelectedTime = (id) => {
        setSelectedTime(id);
    }

    return (
        <div>
            <SelectCustomSearch
                options={options}
                title={"Свободная дата"}
                name="choosedSlot"
                selected={selected}
                leftText={<FontAwesomeIcon icon={faClock} />}
                theme={theme}
                setSelectedValue={handleSelected}
            />
            <SelectCustomSearch
                options={optionsTime}
                title={"Свободное время"}
                name="choosedSlot"
                selected={selectedTime}
                leftText={<FontAwesomeIcon icon={faClock} />}
                theme={theme}
                setSelectedValue={handleSelectedTime}
                value={null}
            />
        </div>
    )


});

export default SelectSlotTimeToFirm;