import React, { useState, useEffect } from 'react';
import s from './ParkingTicket.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button } from 'react-bootstrap';
import { setDone, setErrorCustom } from '../../../rootSlice';
import config from "../../../config/config.json";
import style from '../../../special/CommonStyles.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faCalendar, faCar, faClock, faIndustry, faUser, faWrench } from '@fortawesome/free-solid-svg-icons';
import { RestCustomService } from 'omni-services-utils';
import { InputTextCustom } from 'omni-services-base';
import { ConfigCustomService } from '../../../services/base/config-custom-service/ConfigCustomService';
import { FormCustom, UserProcessingService } from 'omni-services-common';

export const ParkingTicket = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const firm = useSelector(state => state.order.firm);
    const serviceType = useSelector(state => state.order.serviceType);
    const service = useSelector(state => state.order.service);
    const manufacturer = useSelector(state => state.order.manufacturer);
    const manufacturerModel = useSelector(state => state.order.manufacturerModel);
    const begin = useSelector(state => state.order.parkingDateInterval.begin);
    const end = useSelector(state => state.order.parkingDateInterval.end);
    const parkingArea = useSelector(state => state.order.parkingArea);
    const parkingPlace = useSelector(state => state.order.parkingPlace);
    const themeState = useSelector(state => state.settings.theme);

    var [theme, setTheme] = useState(themeState);
    var [error, setError] = useState();

    const state = useSelector(state => state)

    const options = {
        showFooterButtons: true,
        showMenuButtons: false,
        readOnly: true,
        submitText: "Подтвердить и записаться"
    };

    RestCustomService.setUrl(ConfigCustomService.getUrl());

    const submitClick = () => {

        var body = {
            firmNumber: firm.number,
            userId: UserProcessingService.get()?.id,
            serviceTypeCode: serviceType.code,
            serviceCode: service.code,
            manufacturerCode: manufacturer.code,
            manufacturerModelCode: manufacturerModel.code,
            begin: begin,
            end: end,
            state: null,
            parkingPlaceNumber: parkingPlace.number
        }

        RestCustomService.Put(
            `/parkingticket/user/add`,
            body,
            true,
            () => {
                dispatch(setDone({
                    title: "Успешная запись!",
                    description: "Вы были успешно записаны",
                    link: "/",
                    linkText: "Перейдите по ссылке, чтобы выбрать что-нибудь ещё!"
                }));
                navigate("/done");
            },
            (err) => {setError(err)}
        );
    }

    useEffect(() => {
        
    }, [])

    return (
        <div className={style.formWrapper} id={state.settings.theme == "dark" ? style.dark : style.light}>
        <FormCustom
            title={"Новая запись"}
            options={options}
            onSubmit={submitClick}
            body={
                <div>
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Название стоянки"
                        required={false}
                        type="text"
                        name="name"
                        placeholder="Название Вашей фирмы..."
                        muted=""
                        message={firm.name}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faBusinessTime}
                        />}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Дата начала стоянки"
                        required={false}
                        type="datetime-local"
                        name="name"
                        placeholder="Дата начала стоянки"
                        muted=""
                        message={begin}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faCalendar}
                        />}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Дата конца стоянки"
                        required={false}
                        type="datetime-local"
                        name="name"
                        placeholder="Дата конца стоянки"
                        muted=""
                        message={end}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faCalendar}
                        />}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Тип сервиса"
                        required={false}
                        type="text"
                        name="name"
                        placeholder="Тип сервиса.."
                        muted=""
                        message={serviceType.name}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faWrench}
                        />}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Сервис"
                        required={false}
                        type="text"
                        name="name"
                        placeholder="Сервиса"
                        muted=""
                        message={service.name}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faWrench}
                        />}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Марка"
                        required={false}
                        type="text"
                        name="name"
                        placeholder="Марка..."
                        muted=""
                        message={manufacturer.name}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faIndustry}
                        />}
                    />
                    <InputTextCustom
                        theme={theme}
                        controlId="name"
                        label="Модель"
                        required={false}
                        type="text"
                        name="name"
                        placeholder="Модель..."
                        muted=""
                        message={manufacturerModel.name}
                        readOnly={true}
                        setMessage={() => {}}
                        leftText={<FontAwesomeIcon icon={faCar}
                        />}
                    />
                </div>
            }
            theme={state.settings.theme}
            error={error}
        />

    </div>
    )
}

export default (ParkingTicket);