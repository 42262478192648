import React, {Component} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import s from './Customer.module.css';
import PriceCard from './price-card/PriceCard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faRouble} from '@fortawesome/free-solid-svg-icons';
import {Button} from 'react-bootstrap';
import {ButtonCustom} from 'omni-services-base';
import {useSelector} from "react-redux";

export const Customer = (props) => {
    const navigate = useNavigate();
    const state = useSelector(state => state);
    const goRegister = () => {
        navigate("/register-firm");
    }

    return (
        <div className='centralWrapper' id={state.settings.theme === "dark" ? s.dark : s.light}>
            <h2>Сотрудничество</h2>

            <div className={s.cardsWrapper}>
                <PriceCard
                    header={
                        "Абонемент"
                    }
                    body={
                        <div>
                            <p>От 400 &#8381;/мес.</p>
                            <p>Безлимитное количество записей</p>
                            <p>Информирование клиента об изменениях</p>
                        </div>
                    }
                    footer={
                        <ButtonCustom
                            theme={state.settings.theme}
                            text="Начать"
                            onClick={() => {
                                goRegister()
                            }}
                            type={"button"}
                            size="lg"
                        />
                    }/>
                <PriceCard
                    header={
                        "Тариф"
                    }
                    body={
                        <div>
                            <p>От 15 &#8381;/запись</p>
                            <p>Безлимитное количество записей</p>
                            <p>Информирование клиента об изменениях</p>
                            <p></p>
                        </div>
                    }
                    footer={
                        <ButtonCustom
                            theme={state.settings.theme}
                            text="Начать"
                            onClick={() => {
                                goRegister()
                            }}
                            type={"button"}
                            size="lg"
                        />
                    }/>
            </div>
            <h2>Как на нас заработать?</h2>
            <p>TODO: Промокод!</p>
        </div>
    )
}

export default Customer;